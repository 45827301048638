import React, { forwardRef, useEffect, useImperativeHandle, useState } from 'react';
import ReactModal from 'react-modal';
import {
  CloseIcon,
  Content,
  CroppedImage,
  Footer,
  Header,
  HeaderIconContainer,
  LeftButton,
  ModalStyle,
  OverlayStyle,
  RightButton,
} from './CropImageModal.styles';
import { Text24ExtraBold } from '../../texts/text.styles';
import Cropper from 'react-easy-crop';
import getCroppedImg from '../../../../utils/image';

const CropImageModal = forwardRef(({ originalImage, onClose, onComplete, aspectRatio = 1 / 1 }, ref) => {
  const [show, setShow] = useState(false);
  const [step, setStep] = useState(0);
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);
  const [fullImage, setFullImage] = useState(null);
  const [croppedImage, setCroppedImage] = useState(null);

  useImperativeHandle(ref, () => ({
    show() {
      setShow(true);
    },
    hide() {
      setShow(false);
      setStep(0);
      setCrop({ x: 0, y: 0 });
      setZoom(1);
      setCroppedAreaPixels(null);
      setFullImage(null);
      setCroppedImage(null);
    },
    toggle() {
      setShow(prev => !prev);
    },
  }));

  useEffect(() => {
    if (!originalImage) return;
    if (typeof originalImage === 'string') {
      setFullImage(originalImage);
    } else {
      setFullImage(originalImage.content);
    }
  }, [originalImage]);

  const onCropComplete = (croppedArea, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels);
  };

  const cropImage = async () => {
    try {
      setStep(1);
      const croppedImage = await getCroppedImg(originalImage.content, croppedAreaPixels, 0);
      setCroppedImage(croppedImage);
    } catch (e) {
      console.error(e);
    }
  };

  return (
    <ReactModal
      isOpen={show}
      closeTimeoutMS={300}
      onRequestClose={onClose}
      className="_"
      overlayClassName="_"
      contentElement={props => (
        <ModalStyle {...props}>
          <Header>
            <Text24ExtraBold>Crop</Text24ExtraBold>
            <HeaderIconContainer>{!!onClose && <CloseIcon onClick={onClose} />}</HeaderIconContainer>
          </Header>
          <Content>
            {step === 0 ? (
              <Cropper
                image={fullImage}
                crop={crop}
                rotation={0}
                zoom={zoom}
                aspect={aspectRatio}
                onCropChange={setCrop}
                onCropComplete={onCropComplete}
                onZoomChange={setZoom}
              />
            ) : (
              <CroppedImage src={croppedImage} />
            )}
          </Content>
          <Footer>
            {step === 0 ? <div /> : <LeftButton title={'Back'} onClick={() => setStep(0)} />}
            <RightButton
              title={step === 0 ? 'Crop' : 'Submit'}
              onClick={step === 0 ? () => cropImage() : () => onComplete(croppedImage)}
            />
          </Footer>
        </ModalStyle>
      )}
      overlayElement={(props, contentElement) => <OverlayStyle {...props}>{contentElement}</OverlayStyle>}
      ariaHideApp={false}
      shouldCloseOnEsc={false}
      shouldCloseOnOverlayClick={false}
    />
  );
});

export default CropImageModal;
