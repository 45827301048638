import styled from 'styled-components';
import { MainButton, TransparentButton } from '../../buttons/Button/Button';
import { Text16Regular } from '../../texts/text.styles';

export const ConfirmationModalContainer = styled.div`
  max-width: 60rem;
  padding: 3.2rem;
`;

export const Footer = styled.div`
  display: flex;
  gap: 3rem;
  justify-content: space-around;
  margin-top: 4.6rem;
`;

export const CancelButton = styled(TransparentButton)`
  width: 30%;
  font-weight: 500;
`;

export const ConfirmButton = styled(MainButton)`
  width: 60%;
  font-weight: 500;
`;

export const TitleWrapper = styled.div`
  margin-bottom: 2.4rem;
  width: 100%;
  display: flex;
  justify-content: center;
`;

export const Subtitle = styled(Text16Regular)`
  text-align: center;
`;
