import React from 'react';
import { StyledMainButton, StyledTransparentButton, ButtonIconWrapper, ButtonLoader } from './Button.styles';

const handleLinkWrapping = (Component, props) => {
  const { title, onClick, disabled, isLoading, icon, className, type } = props;

  return (
    <Component
      onClick={onClick}
      disabled={disabled || isLoading}
      className={className}
      type={type}
      $isLoading={isLoading}>
      {icon && !isLoading && <ButtonIconWrapper>{icon}</ButtonIconWrapper>}
      {isLoading ? (
        <ButtonLoader src={require('../../../../assets/images/mini-loader.gif')} alt="btn-loading-spinner" />
      ) : (
        title
      )}
    </Component>
  );
};

export const MainButton = ({ title, onClick, disabled, isLoading, icon, className, type }) =>
  handleLinkWrapping(StyledMainButton, { title, onClick, disabled, isLoading, icon, className, type });

export const TransparentButton = ({ title, onClick, disabled, isLoading, icon, className, type }) =>
  handleLinkWrapping(StyledTransparentButton, { title, onClick, disabled, isLoading, icon, className, type });
