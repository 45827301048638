import { CheckBoxContent, StyledCheckboxWrapper, StyledHiddenInput, StyledLabel } from './CheckBox.styles';
import { ReactComponent as CheckBoxBlankIcon } from '../../../../assets/icons/check-box-blank.svg';
import { ReactComponent as CheckBoxIcon } from '../../../../assets/icons/check-box.svg';
import ErrorContainer from '../../ErrorContainer/ErrorContainer';

const Checkbox = ({ checked, disabled, onChange, error, hideErrorMessage, children, className }) => {
  return (
    <StyledLabel className={className} disabled={disabled}>
      <StyledCheckboxWrapper>
        {checked ? <CheckBoxIcon /> : <CheckBoxBlankIcon />}
        <StyledHiddenInput type="checkbox" checked={checked} onChange={onChange} disabled={disabled} />
        <CheckBoxContent>{children}</CheckBoxContent>
      </StyledCheckboxWrapper>
      {!hideErrorMessage && <ErrorContainer errorText={error} />}
    </StyledLabel>
  );
};

export default Checkbox;
