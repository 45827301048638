import React, { Fragment } from 'react';
import { ActionTableBodyItem, TableBodyItem } from '../../Table.styles';
import { PreviewIcon } from './UserByInfluencerTableItem.styles';

function UserByInfluencerTableItem({
  firstName,
  lastName,
  username,
  totalMessages,
  numOfSecretModes,
  onDetailsClicked,
}) {
  return (
    <Fragment>
      <TableBodyItem>
        <p>{firstName || '-'}</p>
      </TableBodyItem>
      <TableBodyItem>
        <p>{lastName || '-'}</p>
      </TableBodyItem>
      <TableBodyItem>
        <p>{username || '-'}</p>
      </TableBodyItem>
      <TableBodyItem>
        <p>{totalMessages}</p>
      </TableBodyItem>
      <TableBodyItem>
        <p>{numOfSecretModes}</p>
      </TableBodyItem>
      <ActionTableBodyItem>
        <PreviewIcon onClick={onDetailsClicked} />
      </ActionTableBodyItem>
    </Fragment>
  );
}

export default UserByInfluencerTableItem;
