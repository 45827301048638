import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { AddToGalleryButton, AlbumMediaListContainer, Title } from './InfluencerGalleryTab.styles';
import { useDispatch, useSelector } from 'react-redux';
import {
  resetInfluencerAlbumMediaInfo,
  selectFetchInfluencerAlbumMediaInfo,
  selectSelectedInfluencer,
} from '../../../../../store/slices/influencer/slice';
import {
  deleteCoverInfluencerAlbumMedia,
  fetchInfluencerAlbumMedia,
  promoteCoverInfluencerAlbumMedia,
} from '../../../../../store/slices/influencer/asyncThunks';
import UploadFreeAlbumMediaFormModal from '../../../../../components/modals/UploadFreeAlbumMediaFormModal/UploadFreeAlbumMediaFormModal';
import { capitalizeFirstLetter } from '../../../../../utils/util';
import { FlexHeader, FlexMain } from '../../../../../components/utils/utils.styles';
import UploadPremiumAlbumMediaFormModal from '../../../../../components/modals/UploadPremiumAlbumMediaFormModal/UploadPremiumAlbumMediaFormModal';
import { ALBUM_MEDIA_TYPE } from '../../../../../utils/constants';
import UploadInactiveAlbumMediaFormModal from '../../../../../components/modals/UploadInactiveAlbumMediaFormModal/UploadInactiveAlbumMediaFormModal';
import AlbumMedia from '../../../../../components/AlbumMedia/AlbumMedia';
import UploadCoverAlbumMediaFormModal from '../../../../../components/modals/UploadCoverAlbumMediaFormModal/UploadCoverAlbumMediaFormModal';
import { notifyError, notifyInfo } from '../../../../../utils/notify';
import { useConfirmationModal } from '../../../../../components/utils/modals/ConfirmationModal/ConfirmationModal';

function InfluencerGalleryTab({ albumMediaType }) {
  const selectedInfluencer = useSelector(selectSelectedInfluencer);
  const { data, pending, page, maxReached } = useSelector(selectFetchInfluencerAlbumMediaInfo);
  const [openModal, closeModal] = useConfirmationModal();
  const [selectedAlbumMedia, setSelectedAlbumMedia] = useState(null);
  const uploadNewAlbumMediaRef = useRef();
  const observer = useRef();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchInfluencerAlbumMedia({ influencerId: selectedInfluencer.id, type: albumMediaType, page: 1 }));
    return () => {
      dispatch(resetInfluencerAlbumMediaInfo());
    };
  }, [dispatch, selectedInfluencer.id, albumMediaType]);

  const lastBookElementRef = useCallback(
    node => {
      if (pending) return;
      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver(entries => {
        if (entries[0].isIntersecting && !maxReached) {
          dispatch(fetchInfluencerAlbumMedia({ influencerId: selectedInfluencer.id, type: albumMediaType, page }));
        }
      });
      if (node) observer.current.observe(node);
    },
    [pending, maxReached, albumMediaType, dispatch, selectedInfluencer.id, page],
  );

  const UploadAlbumMediaFormModal = useMemo(() => {
    switch (albumMediaType) {
      case ALBUM_MEDIA_TYPE.FREE:
        return (
          <UploadFreeAlbumMediaFormModal
            modalRef={uploadNewAlbumMediaRef}
            selectedAlbumMedia={selectedAlbumMedia}
            onAfterClose={() => setSelectedAlbumMedia(null)}
          />
        );
      case ALBUM_MEDIA_TYPE.PREMIUM:
        return (
          <UploadPremiumAlbumMediaFormModal
            modalRef={uploadNewAlbumMediaRef}
            selectedAlbumMedia={selectedAlbumMedia}
            onAfterClose={() => setSelectedAlbumMedia(null)}
          />
        );
      case ALBUM_MEDIA_TYPE.INACTIVE:
        return (
          <UploadInactiveAlbumMediaFormModal
            modalRef={uploadNewAlbumMediaRef}
            selectedAlbumMedia={selectedAlbumMedia}
            onAfterClose={() => setSelectedAlbumMedia(null)}
          />
        );
      case ALBUM_MEDIA_TYPE.COVER:
        return (
          <UploadCoverAlbumMediaFormModal
            modalRef={uploadNewAlbumMediaRef}
            selectedAlbumMedia={selectedAlbumMedia}
            onAfterClose={() => setSelectedAlbumMedia(null)}
          />
        );
      default:
        return null;
    }
  }, [albumMediaType, selectedAlbumMedia]);

  const onAddNewAlbumMedia = () => {
    uploadNewAlbumMediaRef.current.show();
  };

  const onEditAlbumMedia = useCallback(
    albumMedia => {
      setSelectedAlbumMedia(albumMediaType === ALBUM_MEDIA_TYPE.COVER ? { url: albumMedia } : albumMedia);
      uploadNewAlbumMediaRef.current.show();
    },
    [albumMediaType],
  );

  const onDeleteAlbumMedia = useCallback(
    (event, type, index) => {
      openModal(
        'Delete album media',
        `Are you sure you want to delete album media?`,
        'Delete',
        () => {
          switch (type) {
            case ALBUM_MEDIA_TYPE.COVER: {
              closeModal();
              dispatch(deleteCoverInfluencerAlbumMedia(data[index]))
                .unwrap()
                .then(_ => {
                  notifyInfo('Album media edited successfully!');
                })
                .catch(err => {
                  notifyError(err.message);
                });
              break;
            }
            default: {
            }
          }
        },
        'Cancel',
        () => {
          closeModal();
        },
      );
    },
    [data, dispatch, openModal, closeModal],
  );

  const onPromoteAlbumMedia = useCallback(
    (event, type, index) => {
      switch (type) {
        case ALBUM_MEDIA_TYPE.COVER: {
          dispatch(promoteCoverInfluencerAlbumMedia(data[index]))
            .unwrap()
            .then(_ => {
              notifyInfo('Album media promoted successfully!');
              dispatch(
                fetchInfluencerAlbumMedia({ influencerId: selectedInfluencer.id, type: albumMediaType, page: 1 }),
              );
            })
            .catch(err => {
              notifyError(err.message);
            });
          break;
        }
        default: {
        }
      }
    },
    [data, dispatch, albumMediaType, selectedInfluencer.id],
  );

  return (
    <>
      <FlexHeader>
        <Title>{capitalizeFirstLetter(albumMediaType)} Gallery</Title>
        <AddToGalleryButton title="Add media" onClick={() => onAddNewAlbumMedia()} />
      </FlexHeader>
      <FlexMain>
        <AlbumMediaListContainer>
          {data.map((item, i) => (
            <AlbumMedia
              key={item.id ? item.id : i}
              id={i}
              src={albumMediaType === ALBUM_MEDIA_TYPE.COVER ? item : item.url}
              type={albumMediaType}
              onClick={onEditAlbumMedia}
              item={item}
              onSecondaryAction={
                albumMediaType === ALBUM_MEDIA_TYPE.COVER ? (event, type) => onPromoteAlbumMedia(event, type, i) : null
              }
              onDelete={
                albumMediaType === ALBUM_MEDIA_TYPE.COVER ? (event, type) => onDeleteAlbumMedia(event, type, i) : null
              }
              alt="album-media-item"
              innerRef={data.length === i + 1 ? lastBookElementRef : null}
            />
          ))}
        </AlbumMediaListContainer>
      </FlexMain>
      {UploadAlbumMediaFormModal}
    </>
  );
}

export default InfluencerGalleryTab;
