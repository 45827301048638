import styled from 'styled-components';
import { ReactComponent as PreviewIconSvg } from '../../../../assets/icons/preview.svg';
import { ReactComponent as DeleteIconSvg } from '../../../../assets/icons/delete.svg';

export const DeleteIcon = styled(DeleteIconSvg)`
  margin-right: 1rem;
  cursor: pointer;
  fill: '#9BACD8';
`;

export const PreviewIcon = styled(PreviewIconSvg)`
  margin-right: 1rem;
  cursor: pointer;
  fill: '#9BACD8';
`;
