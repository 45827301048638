import React from 'react';
import LogoIcon from '../../../assets/images/logo.png';
import { Logo, PulseContainer } from './SplashPage.styles';

const SplashPage = () => {
  return (
    <PulseContainer>
      <Logo src={LogoIcon} alt="loading-spinner" />
    </PulseContainer>
  );
};

export default SplashPage;
