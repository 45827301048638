import styled from 'styled-components';
import { ReactComponent as GallerySvg } from '../../../../../assets/icons/gallery.svg';

export const GalleryItemsList = styled.div`
  display: grid;
  width: 100%;
  grid-template-columns: repeat(auto-fill, minmax(24rem, 1fr));
  column-gap: 3.6rem;
  row-gap: 3.6rem;
  padding: 1rem;
`;

export const GalleryItemContainer = styled.div`
  position: relative;
  width: 100%;
  aspect-ratio: 1 / 1;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  border-radius: 1.4rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 2rem 0;
  text-align: center;
  cursor: pointer;
`;

export const GalleryItemInfoContainer = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  align-items: center;
  gap: 1rem;
`;

export const GalleryIcon = styled(GallerySvg)``;
