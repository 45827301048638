import { configureStore } from '@reduxjs/toolkit';
import authSlice from './slices/auth/slice';
import userSlice from './slices/user/slice';
import influencerSlice from './slices/influencer/slice';
import photoActivitySlice from './slices/photoActivity/slice';

export const store = configureStore({
  reducer: {
    auth: authSlice,
    userInfo: userSlice,
    influencerInfo: influencerSlice,
    photoActivityInfo: photoActivitySlice,
  },
  devTools: false,
});
