import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { selectEditSecretModePending, selectSelectedInfluencer } from '../../../../../store/slices/influencer/slice';
import { Controller, useFieldArray, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { editSecretMode } from '../../../../../store/slices/influencer/asyncThunks';
import { notifyError, notifyInfo } from '../../../../../utils/notify';
import {
  AddMoreItemsButton,
  BackButton,
  EditSecretModeButton,
  ModifiedFlexMain,
  VoiceConfigurationContainer,
} from './EditSecretModeTab.styles';
import { InputLabel, Text16Regular } from '../../../../../components/utils/texts/text.styles';
import { FlexFooter } from '../../../../../components/utils/utils.styles';
import Collapsible from '../../../../../components/utils/Collapsible/Collapsible';
import ErrorContainer from '../../../../../components/utils/ErrorContainer/ErrorContainer';
import { ReactComponent as BasicIcon } from '../../../../../assets/icons/basic.svg';
import { ReactComponent as PromptIcon } from '../../../../../assets/icons/prompt.svg';
import { ReactComponent as MessageIcon } from '../../../../../assets/icons/message.svg';
import { ReactComponent as VoiceIcon } from '../../../../../assets/icons/voice.svg';
import { editSecretModeFormSchema } from '../../../../../forms/schemas/secretMode.schema';
import { FormCheckbox, FormFileInput, FormInput, FormNumericInput } from '../../../../../forms/form.styles';
import { UPLOAD_FILE_CONSTRAINTS } from '../../../../../utils/constants';
import { VoiceInput } from '../../../CreateInfluencerPage/steps/CreateInfluencerPageStepBasicInfo/CreateInfluencerPageStepBasicInfo.styles';

function EditSecretModeTab() {
  const { secretModeId } = useParams();
  const selectedInfluencer = useSelector(selectSelectedInfluencer);
  const [selectedSecretMode, setSelectedSecretMode] = useState(null);
  const editSecretModePending = useSelector(selectEditSecretModePending);
  const [initIsDefaultValue, setInitIsDefaultValue] = useState(null);
  const { pathname } = useLocation();
  const [openedCollapsible, setOpenedCollapsible] = useState(null);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const {
    register,
    handleSubmit,
    setValue,
    control,
    formState: { errors },
    watch,
  } = useForm({
    defaultValues: {
      name: '',
      symbol: '',
      welcomePhoto: null,
      suggestedReply: '',
      callOptions: {
        isVoiceCallEnabled: false,
        isVideoCallEnabled: false,
      },
      voiceCall: {
        initMessage: null,
        vapi: {
          assistantId: '',
        },
      },
      videoCall: {
        replicaId: '',
        personaId: '',
      },
      pricing: {
        textMessage: '',
        voiceMessage: '',
        voiceCallPerMinute: '',
        videoCallPerMinute: '',
      },
      isDefault: false,
      prompt: {
        male: {
          mainMessage: {
            template: '',
            dynamicSection: '',
            initialMessage: '',
          },
          voiceCall: {
            template: '',
            dynamicSection: '',
          },
          videoCall: {
            template: '',
            dynamicSection: '',
          },
          inactiveMessage: {
            template: '',
          },
        },
        female: {
          mainMessage: {
            template: '',
            dynamicSection: '',
            initialMessage: '',
          },
          voiceCall: {
            template: '',
            dynamicSection: '',
          },
          videoCall: {
            template: '',
            dynamicSection: '',
          },
          inactiveMessage: {
            template: '',
          },
        },
        other: {
          mainMessage: {
            template: '',
            dynamicSection: '',
            initialMessage: '',
          },
          voiceCall: {
            template: '',
            dynamicSection: '',
          },
          videoCall: {
            template: '',
            dynamicSection: '',
          },
          inactiveMessage: {
            template: '',
          },
        },
      },
      voiceId: '',
      voiceConfig: {
        stability: '',
        similarityBoost: '',
        style: '',
        useSpeakerBoost: false,
      },
      welcomeMessage: {
        initial: '',
        others: [],
      },
      insufficientFundsMessages: [],
      inactiveMessages: [],
    },
    delayError: 300,
    resolver: yupResolver(editSecretModeFormSchema),
  });

  const isVoiceCallEnabled = watch('callOptions.isVoiceCallEnabled');
  const isVideoCallEnabled = watch('callOptions.isVideoCallEnabled');

  const {
    fields: otherWelcomeMessagesFields,
    append: appendOtherWelcomeMessage,
    remove: removeOtherWelcomeMessage,
  } = useFieldArray({
    control,
    name: 'welcomeMessage.others',
  });

  const {
    fields: insufficientFundsMessagesFields,
    append: appendInsufficientFundsMessage,
    remove: removeInsufficientFundsMessage,
  } = useFieldArray({
    control,
    name: 'insufficientFundsMessages',
  });

  const {
    fields: inactiveMessagesFields,
    append: appendInactiveMessage,
    remove: removeInactiveMessage,
  } = useFieldArray({
    control,
    name: 'inactiveMessages',
  });

  useEffect(() => {
    if (secretModeId) {
      const secretMode = selectedInfluencer.secretModes.find(sm => sm.id === secretModeId);
      setSelectedSecretMode(secretMode);
      setInitIsDefaultValue(secretMode.isDefault);
      setValue('name', secretMode.name);
      setValue('symbol', secretMode.symbol);
      setValue('welcomePhoto', secretMode.welcomePhoto);
      setValue('suggestedReply', secretMode.suggestedReply);
      setValue('callOptions.isVoiceCallEnabled', selectedInfluencer.callOptions.isVoiceCallEnabled);
      setValue('callOptions.isVideoCallEnabled', selectedInfluencer.callOptions.isVideoCallEnabled);
      setValue('voiceCall.initMessage', secretMode.voiceCall?.initMessagePath);
      setValue('voiceCall.vapi.assistantId', secretMode.voiceCall?.vapi?.assistantId);
      setValue('videoCall.replicaId', secretMode.videoCall?.replicaId);
      setValue('videoCall.personaId', secretMode.videoCall?.personaId);
      setValue('pricing.textMessage', secretMode.pricing.textMessage);
      setValue('pricing.voiceMessage', secretMode.pricing.voiceMessage);
      setValue(
        'pricing.voiceCallPerMinute',
        secretMode.pricing.voiceCallPerSecond ? Math.round(60 * secretMode.pricing.voiceCallPerSecond) : null,
      );
      setValue(
        'pricing.videoCallPerMinute',
        secretMode.pricing.videoCallPerSecond ? Math.round(60 * secretMode.pricing.videoCallPerSecond) : null,
      );
      setValue('isDefault', secretMode.isDefault);
      setValue('prompt', secretMode.prompt);
      // setValue('prompt.mainMessage.template', secretMode.prompt.mainMessage.template);
      setValue('voiceId', secretMode.voiceId);
      setValue('voiceConfig.stability', secretMode.voiceConfig.stability);
      setValue('voiceConfig.similarityBoost', secretMode.voiceConfig.similarityBoost);
      setValue('voiceConfig.style', secretMode.voiceConfig.style);
      setValue('voiceConfig.useSpeakerBoost', secretMode.voiceConfig.useSpeakerBoost);
      setValue('welcomeMessage.initial', secretMode.welcomeMessage.initial);
      setValue('welcomeMessage.others', secretMode.welcomeMessage.others);
      setValue('insufficientFundsMessages', secretMode.insufficientFundsMessages);
      setValue('inactiveMessages', secretMode.inactiveMessages);
    }
  }, [secretModeId, selectedInfluencer.secretModes, setValue, selectedInfluencer.callOptions]);

  const onSubmit = data => {
    dispatch(editSecretMode({ secretModeId: selectedSecretMode.id, ...data }))
      .unwrap()
      .then(_ => {
        notifyInfo('Secret mode updated successfully');
        navigate(`${pathname.split('/edit')[0]}/list`);
      })
      .catch(err => {
        notifyError(err.message);
      });
  };

  return (
    <>
      <ModifiedFlexMain>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Collapsible
            id={0}
            title="Basic information"
            openedCollapsible={openedCollapsible}
            onClick={id => setOpenedCollapsible(id)}
            icon={<BasicIcon />}>
            <FormInput label="Name" placeholder="Enter name" {...register('name')} error={errors.name?.message} />
            <FormInput
              label="Symbol"
              placeholder="Enter symbol"
              {...register('symbol')}
              error={errors.symbol?.message}
            />
            <FormInput
              label="Suggested reply"
              placeholder="Enter suggested reply"
              {...register('suggestedReply')}
              error={errors.suggestedReply?.message}
            />
            <FormNumericInput
              label="Text message price (credits)"
              {...register('pricing.textMessage', {
                valueAsNumber: true,
              })}
              error={errors.pricing?.textMessage?.message}
            />
            <FormNumericInput
              label="Voice message price (credits)"
              {...register('pricing.voiceMessage', {
                valueAsNumber: true,
              })}
              error={errors.pricing?.voiceMessage?.message}
            />
            {isVoiceCallEnabled && (
              <FormNumericInput
                label="Voice call per minute price (credits)"
                {...register('pricing.voiceCallPerMinute', {
                  valueAsNumber: true,
                })}
                error={errors.pricing?.voiceCallPerMinute?.message}
              />
            )}
            {isVideoCallEnabled && (
              <FormNumericInput
                label="Video call per minute price (credits)"
                {...register('pricing.videoCallPerMinute', {
                  valueAsNumber: true,
                })}
                error={errors.pricing?.videoCallPerMinute?.message}
              />
            )}
            <Controller
              name="isDefault"
              control={control}
              render={({ field }) => {
                return (
                  <FormCheckbox
                    disabled={initIsDefaultValue}
                    checked={field.value}
                    onChange={e => field.onChange(e.target.checked)}
                    error={errors.isDefault?.message}
                    hideErrorMessage>
                    <Text16Regular>Is Default</Text16Regular>
                  </FormCheckbox>
                );
              }}
            />
            <Controller
              name="callOptions.isVoiceCallEnabled"
              control={control}
              render={({ field }) => {
                return (
                  <FormCheckbox
                    checked={field.value}
                    onChange={e => field.onChange(e.target.checked)}
                    error={errors.callOptions?.isVoiceCallEnabled?.message}
                    hideErrorMessage>
                    <Text16Regular>Voice enabled</Text16Regular>
                  </FormCheckbox>
                );
              }}
            />
            <Controller
              name="callOptions.isVideoCallEnabled"
              control={control}
              render={({ field }) => {
                return (
                  <FormCheckbox
                    checked={field.value}
                    onChange={e => field.onChange(e.target.checked)}
                    error={errors.callOptions?.isVideoCallEnabled?.message}
                    hideErrorMessage>
                    <Text16Regular>Video enabled</Text16Regular>
                  </FormCheckbox>
                );
              }}
            />
          </Collapsible>
          <Collapsible
            id={1}
            title="Prompt settings (Male)"
            openedCollapsible={openedCollapsible}
            onClick={id => setOpenedCollapsible(id)}
            icon={<PromptIcon />}>
            <FormInput
              label="Main message template"
              placeholder="Enter main template"
              {...register('prompt.male.mainMessage.template')}
              error={errors.prompt?.male?.mainMessage?.template?.message}
              type="textarea"
              specificTextAreaHeight={38}
            />
            <FormInput
              label="Main message dynamic section"
              placeholder="Enter main dynamic section"
              {...register('prompt.male.mainMessage.dynamicSection')}
              error={errors.prompt?.male?.mainMessage?.dynamicSection?.message}
              type="textarea"
            />
            <FormInput
              label="Main message initial message"
              placeholder="Enter main initial message"
              {...register('prompt.male.mainMessage.initialMessage')}
              error={errors.prompt?.male?.mainMessage?.initialMessage?.message}
            />
            {isVoiceCallEnabled && (
              <>
                <FormInput
                  label="Voice call template"
                  placeholder="Enter voice call template"
                  {...register('prompt.male.voiceCall.template')}
                  error={errors.prompt?.male?.voiceCall?.template?.message}
                  type="textarea"
                  specificTextAreaHeight={38}
                />
                <FormInput
                  label="Voice call dynamic section"
                  placeholder="Enter voice call dynamic section"
                  {...register('prompt.male.voiceCall.dynamicSection')}
                  error={errors.prompt?.male?.voiceCall?.dynamicSection?.message}
                  type="textarea"
                />
              </>
            )}
            {isVideoCallEnabled && (
              <>
                <FormInput
                  label="Video call template"
                  placeholder="Enter video call template"
                  {...register('prompt.male.videoCall.template')}
                  error={errors.prompt?.male?.videoCall?.template?.message}
                  type="textarea"
                  specificTextAreaHeight={38}
                />
                <FormInput
                  label="Video call dynamic section"
                  placeholder="Enter video call dynamic section"
                  {...register('prompt.male.videoCall.dynamicSection')}
                  error={errors.prompt?.male?.videoCall?.dynamicSection?.message}
                  type="textarea"
                />
              </>
            )}
            <FormInput
              label="Inactive message template"
              placeholder="Enter inactive template"
              {...register('prompt.male.inactiveMessage.template')}
              error={errors.prompt?.male?.inactiveMessage?.template?.message}
              type="textarea"
              specificTextAreaHeight={38}
            />
          </Collapsible>
          <Collapsible
            id={2}
            title="Prompt settings (Female)"
            openedCollapsible={openedCollapsible}
            onClick={id => setOpenedCollapsible(id)}
            icon={<PromptIcon />}>
            <FormInput
              label="Main message template"
              placeholder="Enter main template"
              {...register('prompt.female.mainMessage.template')}
              error={errors.prompt?.female?.mainMessage?.template?.message}
              type="textarea"
              specificTextAreaHeight={38}
            />
            <FormInput
              label="Main message dynamic section"
              placeholder="Enter main dynamic section"
              {...register('prompt.female.mainMessage.dynamicSection')}
              error={errors.prompt?.female?.mainMessage?.dynamicSection?.message}
              type="textarea"
            />
            <FormInput
              label="Main message initial message"
              placeholder="Enter main initial message"
              {...register('prompt.female.mainMessage.initialMessage')}
              error={errors.prompt?.female?.mainMessage?.initialMessage?.message}
            />
            {isVoiceCallEnabled && (
              <>
                <FormInput
                  label="Voice call template"
                  placeholder="Enter voice call template"
                  {...register('prompt.female.voiceCall.template')}
                  error={errors.prompt?.female?.voiceCall?.template?.message}
                  type="textarea"
                  specificTextAreaHeight={38}
                />
                <FormInput
                  label="Voice call dynamic section"
                  placeholder="Enter voice call dynamic section"
                  {...register('prompt.female.voiceCall.dynamicSection')}
                  error={errors.prompt?.female?.voiceCall?.dynamicSection?.message}
                  type="textarea"
                />
              </>
            )}
            {isVideoCallEnabled && (
              <>
                <FormInput
                  label="Video call template"
                  placeholder="Enter video call template"
                  {...register('prompt.female.videoCall.template')}
                  error={errors.prompt?.female?.videoCall?.template?.message}
                  type="textarea"
                  specificTextAreaHeight={38}
                />
                <FormInput
                  label="Video call dynamic section"
                  placeholder="Enter video call dynamic section"
                  {...register('prompt.female.videoCall.dynamicSection')}
                  error={errors.prompt?.female?.videoCall?.dynamicSection?.message}
                  type="textarea"
                />
              </>
            )}
            <FormInput
              label="Inactive message template"
              placeholder="Enter inactive template"
              {...register('prompt.female.inactiveMessage.template')}
              error={errors.prompt?.female?.inactiveMessage?.template?.message}
              type="textarea"
              specificTextAreaHeight={38}
            />
          </Collapsible>
          <Collapsible
            id={3}
            title="Prompt settings (Other)"
            openedCollapsible={openedCollapsible}
            onClick={id => setOpenedCollapsible(id)}
            icon={<PromptIcon />}>
            <FormInput
              label="Main message template"
              placeholder="Enter main template"
              {...register('prompt.other.mainMessage.template')}
              error={errors.prompt?.other?.mainMessage?.template?.message}
              type="textarea"
              specificTextAreaHeight={38}
            />
            <FormInput
              label="Main message dynamic section"
              placeholder="Enter main dynamic section"
              {...register('prompt.other.mainMessage.dynamicSection')}
              error={errors.prompt?.other?.mainMessage?.dynamicSection?.message}
              type="textarea"
            />
            <FormInput
              label="Main message initial message"
              placeholder="Enter main initial message"
              {...register('prompt.other.mainMessage.initialMessage')}
              error={errors.prompt?.other?.mainMessage?.initialMessage?.message}
            />
            {isVoiceCallEnabled && (
              <>
                <FormInput
                  label="Voice call template"
                  placeholder="Enter voice call template"
                  {...register('prompt.other.voiceCall.template')}
                  error={errors.prompt?.other?.voiceCall?.template?.message}
                  type="textarea"
                  specificTextAreaHeight={38}
                />
                <FormInput
                  label="Voice call dynamic section"
                  placeholder="Enter voice call dynamic section"
                  {...register('prompt.other.voiceCall.dynamicSection')}
                  error={errors.prompt?.other?.voiceCall?.dynamicSection?.message}
                  type="textarea"
                />
              </>
            )}
            {isVideoCallEnabled && (
              <>
                <FormInput
                  label="Video call template"
                  placeholder="Enter video call template"
                  {...register('prompt.other.videoCall.template')}
                  error={errors.prompt?.other?.videoCall?.template?.message}
                  type="textarea"
                  specificTextAreaHeight={38}
                />
                <FormInput
                  label="Video call dynamic section"
                  placeholder="Enter video call dynamic section"
                  {...register('prompt.other.videoCall.dynamicSection')}
                  error={errors.prompt?.other?.videoCall?.dynamicSection?.message}
                  type="textarea"
                />
              </>
            )}
            <FormInput
              label="Inactive message template"
              placeholder="Enter inactive template"
              {...register('prompt.other.inactiveMessage.template')}
              error={errors.prompt?.other?.inactiveMessage?.template?.message}
              type="textarea"
              specificTextAreaHeight={38}
            />
          </Collapsible>
          <Collapsible
            id={4}
            title="Welcome messages"
            openedCollapsible={openedCollapsible}
            onClick={id => setOpenedCollapsible(id)}
            icon={<MessageIcon />}>
            <Controller
              name="welcomePhoto"
              control={control}
              render={({ field }) => {
                return (
                  <FormFileInput
                    label="Welcome photo"
                    value={field.value}
                    onChange={field.onChange}
                    type={UPLOAD_FILE_CONSTRAINTS.TYPE.IMAGE}
                    error={errors.welcomeMessage?.message}
                  />
                );
              }}
            />
            <FormInput
              label="Initial"
              placeholder="Enter initial welcome message"
              {...register('welcomeMessage.initial')}
              error={errors.welcomeMessage?.initial?.message}
              type="textarea"
              specificTextAreaHeight={12}
            />
            <InputLabel>Others</InputLabel>
            {otherWelcomeMessagesFields.map((_, index) => (
              <FormInput
                key={index}
                label={`#${index + 1}`}
                placeholder="Enter other welcome message"
                {...register(`welcomeMessage.others[${index}]`)}
                error={errors.welcomeMessage?.others && errors.welcomeMessage.others[index]?.message}
                type="textarea"
                specificTextAreaHeight={12}
                onDelete={() => removeOtherWelcomeMessage(index)}
              />
            ))}
            <AddMoreItemsButton type="button" title="Add new message" onClick={() => appendOtherWelcomeMessage()} />
            <ErrorContainer errorText={errors.welcomeMessage?.others?.message} />
          </Collapsible>
          <Collapsible
            id={5}
            title="Insufficient funds messages"
            openedCollapsible={openedCollapsible}
            onClick={id => setOpenedCollapsible(id)}
            icon={<MessageIcon />}>
            {insufficientFundsMessagesFields.map((_, index) => (
              <FormInput
                key={index}
                label={`#${index + 1}`}
                placeholder="Enter insufficient funds message"
                {...register(`insufficientFundsMessages[${index}]`)}
                error={errors.insufficientFundsMessages && errors.insufficientFundsMessages[index]?.message}
                type="textarea"
                specificTextAreaHeight={12}
                onDelete={() => removeInsufficientFundsMessage(index)}
              />
            ))}
            <AddMoreItemsButton
              type="button"
              title="Add new message"
              onClick={() => appendInsufficientFundsMessage()}
            />
            <ErrorContainer errorText={errors.insufficientFundsMessages?.message} />
          </Collapsible>
          <Collapsible
            id={6}
            title="Inactive messages"
            openedCollapsible={openedCollapsible}
            onClick={id => setOpenedCollapsible(id)}
            icon={<MessageIcon />}>
            {inactiveMessagesFields.map((_, index) => (
              <FormInput
                key={index}
                label={`#${index + 1}`}
                placeholder="Enter inactive message"
                {...register(`inactiveMessages[${index}]`)}
                error={errors.inactiveMessages && errors.inactiveMessages[index]?.message}
                onDelete={() => removeInactiveMessage(index)}
              />
            ))}
            <AddMoreItemsButton type="button" title="Add new message" onClick={() => appendInactiveMessage()} />
            <ErrorContainer errorText={errors.inactiveMessages?.message} />
          </Collapsible>
          <Collapsible
            id={7}
            title="Voice settings"
            openedCollapsible={openedCollapsible}
            onClick={id => setOpenedCollapsible(id)}
            icon={<VoiceIcon />}>
            <FormInput
              label="Voice ID"
              placeholder="Enter voice id"
              {...register('voiceId')}
              error={errors.voiceId?.message}
            />
            <InputLabel>Voice configuration</InputLabel>
            <VoiceConfigurationContainer>
              <FormNumericInput
                label="Stability"
                {...register('voiceConfig.stability', {
                  valueAsNumber: true,
                })}
                error={errors.voiceConfig?.stability?.message}
              />
              <FormNumericInput
                label="Similarity boost"
                {...register('voiceConfig.similarityBoost', {
                  valueAsNumber: true,
                })}
                error={errors.voiceConfig?.similarityBoost?.message}
              />
              <FormNumericInput
                label="Style"
                {...register('voiceConfig.style', {
                  valueAsNumber: true,
                })}
                error={errors.voiceConfig?.style?.message}
              />
              <Controller
                name="voiceConfig.useSpeakerBoost"
                control={control}
                render={({ field }) => {
                  return (
                    <FormCheckbox
                      checked={field.value}
                      onChange={e => field.onChange(e.target.checked)}
                      error={errors.voiceConfig?.useSpeakerBoost?.message}>
                      <Text16Regular>Use speaker boost</Text16Regular>
                    </FormCheckbox>
                  );
                }}
              />
            </VoiceConfigurationContainer>
          </Collapsible>
          <Collapsible
            id={8}
            title="Call Settings (Voice/Video)"
            openedCollapsible={openedCollapsible}
            onClick={id => setOpenedCollapsible(id)}
            icon={<VoiceIcon />}>
            {isVoiceCallEnabled && (
              <>
                <Controller
                  name="voiceCall.initMessage"
                  control={control}
                  render={({ field }) => {
                    return (
                      <VoiceInput
                        label="Voice call - init message"
                        value={field.value}
                        onChange={field.onChange}
                        type={UPLOAD_FILE_CONSTRAINTS.TYPE.VOICE}
                        error={errors.voiceCall?.initMessage?.message}
                        increasedFileWidth
                      />
                    );
                  }}
                />
                <FormInput
                  label="Vapi assistant id"
                  placeholder="Enter vapi assistant id"
                  {...register('voiceCall.vapi.assistantId')}
                  error={errors.voiceCall?.vapi?.assistantId?.message}
                />
              </>
            )}
            {isVideoCallEnabled && (
              <>
                <FormInput
                  label="Replica id"
                  placeholder="Enter replica id"
                  {...register('videoCall.replicaId')}
                  error={errors.videoCall?.replicaId?.message}
                />
                <FormInput
                  label="Persona id"
                  placeholder="Enter persona id"
                  {...register('videoCall.personaId')}
                  error={errors.videoCall?.personaId?.message}
                />
              </>
            )}
          </Collapsible>
        </form>
      </ModifiedFlexMain>
      <FlexFooter>
        <BackButton
          type="button"
          title="Back"
          onClick={() => {
            navigate(`${pathname.split('/edit')[0]}/list`);
          }}
        />
        <EditSecretModeButton
          title="Edit secret mode"
          onClick={() => handleSubmit(onSubmit)()}
          isLoading={editSecretModePending}
        />
      </FlexFooter>
    </>
  );
}

export default EditSecretModeTab;
