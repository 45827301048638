import styled from 'styled-components';
import { TransparentButton } from '../../utils/buttons/Button/Button';

export const Nav = styled.nav`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  display: flex;
  align-items: center;
  height: 7.8rem;
  padding: 2.4rem 4.4rem;
  background-color: #fff;
  z-index: 50;
  box-shadow: 0px 4px 6px -2px rgba(16, 24, 40, 0.13);
  border-radius: 0px 0px 10px 10px;
`;

export const NavMenu = styled.ul`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  list-style: none;
`;

export const NavItem = styled.li`
  font-size: 1.8rem;
  line-height: 2rem;
  font-weight: 500;
  letter-spacing: 0;
  cursor: pointer;
`;

export const NavLink = styled.span`
  color: #161320;
  display: flex;
  align-items: center;
  text-decoration: none;
  padding: 0.5rem 1rem;
  height: 100%;

  &:hover {
    opacity: 0.7;
    transition: all 0.3s ease;
  }
`;

export const SignInButton = styled(TransparentButton)`
  width: auto;
`;
