import React from 'react';
import { Container, StepInfoContainer, StepNumberContainer, StepNumberText, Subtitle, Title } from './FormStep.styles';
import { ReactComponent as DoneIcon } from '../../assets/icons/done.svg';

export const FORM_STEP_STATUS = {
  CURRENT: 'CURRENT',
  COMPLETED: 'COMPLETED',
  UNCOMPLETED: 'UNCOMPLETED',
};

function FormStep({ number, title, subtitle, status, selected, className, onClick }) {
  return (
    <Container $status={status} $selected={selected} onClick={null} className={className}>
      <StepNumberContainer $status={status}>
        {status === FORM_STEP_STATUS.COMPLETED ? <DoneIcon fill="#fff" /> : <StepNumberText>{number}</StepNumberText>}
      </StepNumberContainer>
      <StepInfoContainer>
        <Subtitle>{subtitle}</Subtitle>
        <Title>{title}</Title>
      </StepInfoContainer>
    </Container>
  );
}

export default FormStep;
