import styled from 'styled-components';
import { Row } from '../../../../../components/utils/utils.styles';
import { FormFileInput, FormSelect } from '../../../../../forms/form.styles';

export const Container = styled.div`
  display: grid;
  width: 100%;
  grid-template-columns: repeat(2, minmax(24rem, 1fr));
  column-gap: 3.6rem;
  row-gap: 2rem;
  background-color: #fff;
  padding: 1rem 2rem 2rem 2rem;
  border: 1px solid #f2f0f7;
  border-radius: 1.2rem;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
`;

export const ContainerLeftPart = styled.div`
  grid-column: 1 / 2;
`;

export const ContainerRightPart = styled.div`
  grid-column: 2 / -1;
`;

export const UploadRow = styled(Row)`
  justify-content: space-between;
  gap: 2rem;
`;

export const VoiceInput = styled(FormFileInput)`
  flex: 1;
`;

export const CategorySelect = styled(FormSelect)`
  margin-top: 7.6rem;
`;
