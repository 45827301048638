import styled from 'styled-components';
import { MainButton, TransparentButton } from '../../../../../components/utils/buttons/Button/Button';
import { FlexMain } from '../../../../../components/utils/utils.styles';

export const BackButton = styled(TransparentButton)`
  flex: 1;
`;

export const EditSecretModeButton = styled(MainButton)`
  flex: 2;
`;

export const VoiceConfigurationContainer = styled.div`
  margin-left: 4.4rem;
  margin-top: 2.1rem;
`;

export const ModifiedFlexMain = styled(FlexMain)`
  padding-top: 0;
  margin-top: 0;
`;

export const AddMoreItemsButton = styled(TransparentButton)`
  height: 4rem;
  width: 30rem;
  margin-inline: auto;
  margin-bottom: 1rem;
  background-color: #fff;
  margin-top: 2.4rem;
`;
