import styled from 'styled-components';
import { MainButton } from '../../../../../components/utils/buttons/Button/Button';
import Image from '../../../../../components/utils/Image/Image';
import NoDataContainer from '../../../../../components/NoDataContainer/NoDataContainer';

export const Container = styled.div`
  display: grid;
  width: 100%;
  grid-template-columns: repeat(auto-fill, minmax(24rem, 1fr));
  column-gap: 2.4rem;
  row-gap: 2.4rem;
  background-color: #fff;
  padding: 1rem 2rem 2rem 2rem;
  border: 1px solid #f2f0f7;
  border-radius: 1.2rem;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  margin-bottom: 2rem;
`;

export const ContainerHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  grid-column: 1 / -1;
`;

export const ContainerFooter = styled.div`
  display: flex;
  grid-column: 1 / -1;
`;

export const Row = styled.div`
  display: flex;
  align-items: center;
  gap: 1.2rem;
`;

export const AddNewAlbumMediumButton = styled(MainButton)`
  width: 24rem;
  height: 4rem;
  font-size: 1.5rem;
`;

export const AlbumMediumContainer = styled(Image)`
  cursor: pointer;
`;

export const EmptyListContainer = styled(NoDataContainer)`
  margin: 2rem 0;
  grid-column: 1 / -1;
`;
