import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.4rem;
  align-items: center;
`;

export const NoDataImage = styled.img`
  width: 14rem;
  opacity: 0.5;
`;
