import { createAsyncThunk } from '@reduxjs/toolkit';
import authService from '../../../api/services/auth';
import { LOGOUT } from '../../../utils/constants';

export const login = createAsyncThunk('auth/login', async requestData => {
  return await authService.login(requestData);
});

export const logout = createAsyncThunk('auth/logout', async (_, thunkAPI) => {
  thunkAPI.dispatch({ type: LOGOUT });
  return await authService.logout();
});
