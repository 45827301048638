import React, { useEffect, useRef, useState } from 'react';
import { useConfirmationModal } from '../../../../../components/utils/modals/ConfirmationModal/ConfirmationModal';
import { useFieldArray, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { createInfluencerStepSecretModesFormSchema } from '../../../../../forms/schemas/influencer.schema';
import { CreateInfluencerForm, Footer, FooterLeftButton, FooterRightButton } from '../../CreateInfluencerPage.styles';
import CreateSecretModeFormModal from '../../../../../components/modals/CreateSecretModeFormModal/CreateSecretModeFormModal';
import {
  AddNewSecretModeButton,
  Container,
  ContainerFooter,
  ContainerHeader,
  EmptyListContainer,
  Row,
} from './CreateInfluencerPageStepSecretModes.styles';
import { ReactComponent as SecretModeIcon } from '../../../../../assets/icons/secret-mode.svg';
import { Text20Bold } from '../../../../../components/utils/texts/text.styles';
import ErrorContainer from '../../../../../components/utils/ErrorContainer/ErrorContainer';
import SecretModeItem from '../../../../../components/SecretModeItem/SecretModeItem';
import { useSelector } from 'react-redux';
import { selectCreateInfluencerPending } from '../../../../../store/slices/influencer/slice';

function CreateInfluencerPageStepSecretModes({ step, onAfterSubmit, onBack, formData }) {
  const createInfluencerPending = useSelector(selectCreateInfluencerPending);
  const [openModal, closeModal] = useConfirmationModal();
  const [selectedSecretMode, setSelectedSecretMode] = useState(null);
  const secretModeModalRef = useRef();

  const {
    handleSubmit,
    control,
    formState: { errors },
    getValues,
  } = useForm({
    defaultValues: {
      secretModes: [],
    },
    delayError: 300,
    resolver: yupResolver(createInfluencerStepSecretModesFormSchema),
  });

  const {
    fields: secretModesFields,
    append: appendSecretMode,
    remove: removeSecretMode,
    update: updateSecretMode,
  } = useFieldArray({
    control,
    name: 'secretModes',
  });

  useEffect(() => {
    if (formData.secretModes) {
      if (formData.secretModes.secretModes.length) {
        for (const secretMode of formData.secretModes.secretModes) {
          appendSecretMode(secretMode);
        }
      }
    }
  }, [formData.secretModes, appendSecretMode]);

  const onSubmit = data => {
    onAfterSubmit(data, step);
  };

  const onEditSecretMode = secretMode => {
    setSelectedSecretMode(secretMode);
    secretModeModalRef.current.show();
  };

  return (
    <>
      <CreateInfluencerForm>
        <Container>
          <ContainerHeader>
            <Row>
              <SecretModeIcon />
              <Text20Bold>Secret Modes</Text20Bold>
            </Row>
            <AddNewSecretModeButton
              title="Add secret mode"
              type="button"
              onClick={() => secretModeModalRef.current.show()}
            />
          </ContainerHeader>
          {secretModesFields.length ? (
            <>
              {secretModesFields.map((item, index) => {
                return (
                  <SecretModeItem
                    key={item.id}
                    secretMode={item}
                    onClick={() => onEditSecretMode(item)}
                    onDelete={event => {
                      openModal(
                        'Delete secret mode',
                        `Are you sure you want to delete ${item.name} secret mode?`,
                        'Delete',
                        () => {
                          removeSecretMode(index);
                          closeModal();
                        },
                        'Cancel',
                        () => {
                          closeModal();
                        },
                      );
                      event.stopPropagation();
                    }}
                  />
                );
              })}
            </>
          ) : (
            <EmptyListContainer />
          )}
          <ContainerFooter>
            <ErrorContainer errorText={errors.secretModes?.message} />
          </ContainerFooter>
        </Container>
      </CreateInfluencerForm>
      <Footer>
        <FooterLeftButton title="Previous Step" onClick={() => onBack(getValues(), step)} />
        <FooterRightButton
          title="Create Influencer"
          onClick={() => handleSubmit(onSubmit)()}
          isLoading={createInfluencerPending}
        />
      </Footer>
      <CreateSecretModeFormModal
        modalRef={secretModeModalRef}
        selectedSecretMode={selectedSecretMode}
        customOnSubmit={
          selectedSecretMode
            ? data => {
                if (data.isDefault) {
                  const index = secretModesFields.findIndex(secretMode => secretMode.isDefault);
                  updateSecretMode(index, { ...secretModesFields[index], isDefault: false });
                }
                const index = secretModesFields.findIndex(secretMode => secretMode.id === selectedSecretMode.id);
                updateSecretMode(index, { ...secretModesFields[index], ...data });
              }
            : data => {
                if (data.isDefault) {
                  const index = secretModesFields.findIndex(secretMode => secretMode.isDefault);
                  updateSecretMode(index, { ...secretModesFields[index], isDefault: false });
                }
                appendSecretMode(data);
              }
        }
        onAfterClose={() => setSelectedSecretMode(null)}
        forceDefaultTrue={!secretModesFields.length}
        isVoiceCallEnabled={formData?.additionalInfo?.callOptions?.isVoiceCallEnabled}
        isVideoCallEnabled={formData?.additionalInfo?.callOptions?.isVideoCallEnabled}
      />
    </>
  );
}

export default CreateInfluencerPageStepSecretModes;
