import React, { useCallback } from 'react';
import {
  LastSectionItemContainer,
  LogoIcon,
  SectionItemContainer,
  SectionItemIconContainer,
  SectionItemText,
  SectionName,
  Shadow,
  Sidebar,
} from './PrivateNavigation.styles';
import { ReactComponent as UsersIcon } from '../../../assets/icons/users.svg';
import { ReactComponent as InfluencerIcon } from '../../../assets/icons/influencers.svg';
import { ReactComponent as DashboardIcon } from '../../../assets/icons/dashboard.svg';
import { ReactComponent as PhotoActivitiesIcon } from '../../../assets/icons/gallery.svg';
import { ReactComponent as LogoutIcon } from '../../../assets/icons/logout.svg';
import { NavLink } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { logout } from '../../../store/slices/auth/asyncThunks';
import { selectUser } from '../../../store/slices/user/slice';
import { ADMIN_ROLE, INFLUENCER_ROLE } from '../../../utils/constants';

function PrivateNavigation() {
  const user = useSelector(selectUser);
  const dispatch = useDispatch();

  const renderSidebarContent = useCallback(() => {
    switch (user.role) {
      case ADMIN_ROLE.SUPER_ADMIN:
      case ADMIN_ROLE.ADMIN:
        return (
          <>
            <SectionItemContainer $first>
              <NavLink to="/influencers">
                <SectionItemIconContainer>
                  <InfluencerIcon />
                </SectionItemIconContainer>
                <SectionItemText>Influencers</SectionItemText>
              </NavLink>
            </SectionItemContainer>
            <SectionItemContainer>
              <NavLink to="/users">
                <SectionItemIconContainer>
                  <UsersIcon />
                </SectionItemIconContainer>
                <SectionItemText>Users</SectionItemText>
              </NavLink>
            </SectionItemContainer>
            <SectionItemContainer>
              <NavLink to="/photo-activities">
                <SectionItemIconContainer>
                  <PhotoActivitiesIcon fill="#fff" />
                </SectionItemIconContainer>
                <SectionItemText>Photo Activities</SectionItemText>
              </NavLink>
            </SectionItemContainer>
          </>
        );
      case INFLUENCER_ROLE.INFLUENCER:
        return (
          <>
            <SectionItemContainer $first>
              <NavLink to="/dashboard">
                <SectionItemIconContainer>
                  <DashboardIcon />
                </SectionItemIconContainer>
                <SectionItemText>Dashboard</SectionItemText>
              </NavLink>
            </SectionItemContainer>
            <SectionItemContainer>
              <NavLink to="/users">
                <SectionItemIconContainer>
                  <UsersIcon />
                </SectionItemIconContainer>
                <SectionItemText>Users</SectionItemText>
              </NavLink>
            </SectionItemContainer>
          </>
        );
      default:
        return null;
    }
  }, [user]);

  return (
    <Sidebar>
      <Shadow />
      <LogoIcon />
      <SectionName $first>Manage</SectionName>
      {renderSidebarContent()}
      <LastSectionItemContainer onClick={() => dispatch(logout())}>
        <SectionItemIconContainer>
          <LogoutIcon />
        </SectionItemIconContainer>
        <SectionItemText>Logout</SectionItemText>
      </LastSectionItemContainer>
    </Sidebar>
  );
}

export default PrivateNavigation;
