import React, { useState, forwardRef } from 'react';
import ErrorContainer from '../../ErrorContainer/ErrorContainer';
import { StyledLabel, StyledInput, VisibilityButton, StyledTextarea, StyledLabelRow, DeleteIcon } from './Input.styles';
import { ReactComponent as VisibilityIcon } from '../../../../assets/icons/visibility.svg';
import { ReactComponent as VisibilityOffIcon } from '../../../../assets/icons/visibility_off.svg';
import { Text16Regular } from '../../texts/text.styles';

const Input = forwardRef(
  (
    {
      defaultValue,
      value,
      placeholder,
      onChange,
      autoFocus,
      disabled,
      className,
      error,
      hideErrorMessage,
      label,
      type,
      specificTextAreaHeight,
      onDelete,
      ...inputProps
    },
    ref,
  ) => {
    const [isShown, setIsShown] = useState(false);
    const [focused, setFocused] = useState(false);

    const onFocus = () => setFocused(true);
    const onBlur = () => {
      setFocused(false);
    };

    return (
      <StyledLabel disabled={disabled} className={className}>
        {(label || !!onDelete) && (
          <StyledLabelRow>
            {label && <Text16Regular>{label}</Text16Regular>}
            {!!onDelete && <DeleteIcon onClick={onDelete} />}
          </StyledLabelRow>
        )}
        {type === 'textarea' ? (
          <StyledTextarea
            ref={ref}
            placeholder={placeholder}
            type={type}
            defaultValue={defaultValue}
            value={value}
            onChange={onChange}
            autoFocus={autoFocus}
            disabled={disabled}
            onFocus={onFocus}
            onBlurCapture={onBlur}
            $specificTextAreaHeight={specificTextAreaHeight}
            {...inputProps}
          />
        ) : (
          <StyledInput
            ref={ref}
            placeholder={placeholder}
            type={type === 'password' ? (isShown ? 'text' : 'password') : type}
            defaultValue={defaultValue}
            value={value}
            onChange={onChange}
            autoFocus={autoFocus}
            disabled={disabled}
            onFocus={onFocus}
            onBlurCapture={onBlur}
            $isShown={isShown}
            $isFocused={focused}
            {...inputProps}
          />
        )}
        {type === 'password' && (
          <VisibilityButton onClick={() => setIsShown(prev => !prev)} $labelExists={!!label}>
            {isShown ? <VisibilityOffIcon /> : <VisibilityIcon />}
          </VisibilityButton>
        )}
        {!hideErrorMessage && <ErrorContainer errorText={error} />}
      </StyledLabel>
    );
  },
);

export default Input;
