import React, { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  selectEditInfluencerPending,
  selectFetchInfluencerVariablesInfoData,
  selectSelectedInfluencer,
} from '../../../../store/slices/influencer/slice';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { CheckboxRow, EditInfluencerButton } from './InfluencerEditTab.styles';
import {
  GENDERS,
  INFLUENCER_CATEGORIES,
  INFLUENCER_STATUS,
  UPLOAD_FILE_CONSTRAINTS,
} from '../../../../utils/constants';
import { editInfluencer } from '../../../../store/slices/influencer/asyncThunks';
import { notifyError, notifyInfo } from '../../../../utils/notify';
import { FlexFooter, FlexMain } from '../../../../components/utils/utils.styles';
import { editInfluencerFormSchema } from '../../../../forms/schemas/influencer.schema';
import {
  FormCheckbox,
  FormDatePicker,
  FormFileInput,
  FormInput,
  FormNumericInput,
  FormSelect,
} from '../../../../forms/form.styles';
import { capitalizeFirstLetter, convertTagValueToLabel } from '../../../../utils/util';
import CropImageModal from '../../../../components/utils/modals/CropImageModal/CropImageModal';

function InfluencerEditTab() {
  const selectedInfluencer = useSelector(selectSelectedInfluencer);
  const editInfluencerPending = useSelector(selectEditInfluencerPending);
  const influencerVariables = useSelector(selectFetchInfluencerVariablesInfoData);
  const cropImageRef = useRef();
  const dispatch = useDispatch();

  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
    setValue,
    watch,
    getValues,
  } = useForm({
    defaultValues: {
      profilePhoto: selectedInfluencer.profilePhoto || null,
      name: selectedInfluencer.name || '',
      nickname: selectedInfluencer.nickname || '',
      email: selectedInfluencer.email || '',
      dateOfBirth: selectedInfluencer.dateOfBirth || null,
      location: selectedInfluencer.location || '',
      countryFlag: selectedInfluencer.countryFlag || '',
      category: selectedInfluencer.category
        ? { value: selectedInfluencer.category, label: capitalizeFirstLetter(selectedInfluencer.category) }
        : null,
      gender: selectedInfluencer.gender
        ? { value: selectedInfluencer.gender, label: capitalizeFirstLetter(selectedInfluencer.gender) }
        : null,
      personalityTags: selectedInfluencer.personalityTags
        ? selectedInfluencer.personalityTags.map(value => ({
            value,
            label: convertTagValueToLabel(value),
          }))
        : [],
      appearanceTags: selectedInfluencer.appearanceTags
        ? selectedInfluencer.appearanceTags.map(value => ({
            value,
            label: convertTagValueToLabel(value),
          }))
        : [],
      highlightTags: selectedInfluencer.highlightTags
        ? selectedInfluencer.highlightTags.map(value => ({
            value,
            label: convertTagValueToLabel(value),
          }))
        : [],
      paymentRatio: selectedInfluencer.paymentRatio || {
        directReferral: 0.5,
        directForeigner: 0.2,
        directOthersReferral: 0.05,
        indirectReferral: 0.15,
      },
      score: selectedInfluencer.score || '',
      aboutMe: selectedInfluencer.aboutMe || '',
      isVerified: selectedInfluencer.isVerified || false,
      active: (selectedInfluencer.status === INFLUENCER_STATUS.ACTIVE ? true : false) || false,
    },
    delayError: 300,
    resolver: yupResolver(editInfluencerFormSchema),
  });

  const watchProfilePhoto = watch('profilePhoto');

  useEffect(() => {
    if (watchProfilePhoto) {
      if (typeof watchProfilePhoto !== 'string') {
        cropImageRef.current.show();
      }
    }
  }, [watchProfilePhoto]);

  const onSubmit = data => {
    dispatch(editInfluencer(data))
      .unwrap()
      .then(_ => {
        notifyInfo('Influencer updated successfully!');
      })
      .catch(err => {
        notifyError(err.message);
      });
  };

  return (
    <>
      <FlexMain>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Controller
            name="profilePhoto"
            control={control}
            render={({ field }) => {
              return (
                <FormFileInput
                  label="Profile photo"
                  value={field.value}
                  onChange={field.onChange}
                  type={UPLOAD_FILE_CONSTRAINTS.TYPE.IMAGE}
                  error={errors.profilePhoto?.message}
                />
              );
            }}
          />
          <FormInput label="Name" placeholder="Enter name" {...register('name')} error={errors.name?.message} />
          <FormInput
            label="Nickname"
            placeholder="Enter nickname"
            {...register('nickname')}
            error={errors.nickname?.message}
          />
          <FormInput label="Email" placeholder="Enter email" {...register('email')} error={errors.email?.message} />
          <Controller
            name="dateOfBirth"
            control={control}
            render={({ field }) => {
              return (
                <FormDatePicker
                  label="Date of birth"
                  placeholder="Enter date of birth"
                  value={field.value}
                  onChange={field.onChange}
                  error={errors.dateOfBirth?.message}
                />
              );
            }}
          />
          <FormInput
            label="Location"
            placeholder="Enter location"
            {...register('location')}
            error={errors.location?.message}
          />
          <FormInput
            label="Country flag"
            placeholder="Enter country flag (optional)"
            {...register('countryFlag')}
            error={errors.countryFlag?.message}
          />
          <Controller
            name="category"
            control={control}
            render={({ field }) => (
              <FormSelect
                placeholder="Select category"
                label="Category"
                options={INFLUENCER_CATEGORIES.map(value => ({ value, label: capitalizeFirstLetter(value) }))}
                selectedOption={field.value}
                handleChange={value => field.onChange(value)}
                error={errors.category?.message}
              />
            )}
          />
          <Controller
            name="gender"
            control={control}
            render={({ field }) => (
              <FormSelect
                placeholder="Select gender"
                label="Gender"
                options={GENDERS.map(value => ({ value, label: capitalizeFirstLetter(value) }))}
                selectedOption={field.value}
                handleChange={value => field.onChange(value)}
                error={errors.gender?.message}
              />
            )}
          />
          <Controller
            name="highlightTags"
            control={control}
            render={({ field }) => (
              <FormSelect
                placeholder="Select highlight tags"
                label="Highlight tags"
                options={influencerVariables?.highlightTags.map(value => ({
                  value,
                  label: convertTagValueToLabel(value),
                }))}
                multiSelection
                selectedOption={field.value}
                handleChange={value => field.onChange(value)}
                error={errors.highlightTags?.message}
              />
            )}
          />
          <Controller
            name="personalityTags"
            control={control}
            render={({ field }) => (
              <FormSelect
                placeholder="Select personality tags"
                label="Personality tags"
                options={influencerVariables?.personalityTags.map(value => ({
                  value,
                  label: convertTagValueToLabel(value),
                }))}
                multiSelection
                selectedOption={field.value}
                handleChange={value => field.onChange(value)}
                error={errors.personalityTags?.message}
              />
            )}
          />
          <Controller
            name="appearanceTags"
            control={control}
            render={({ field }) => (
              <FormSelect
                placeholder="Select appearance tags"
                label="Appearance tags"
                options={influencerVariables?.appearanceTags.map(value => ({
                  value,
                  label: convertTagValueToLabel(value),
                }))}
                multiSelection
                selectedOption={field.value}
                handleChange={value => field.onChange(value)}
                error={errors.appearanceTags?.message}
              />
            )}
          />
          <FormNumericInput
            label="Score"
            {...register('score', {
              valueAsNumber: true,
            })}
            error={errors.score?.message}
          />
          <FormNumericInput
            label="Direct referral payment ratio"
            {...register('paymentRatio.directReferral', {
              valueAsNumber: true,
            })}
            error={errors.paymentRatio?.directReferral?.message}
          />
          <FormNumericInput
            label="Direct foreigner payment ratio"
            {...register('paymentRatio.directForeigner', {
              valueAsNumber: true,
            })}
            error={errors.paymentRatio?.directForeigner?.message}
          />
          <FormNumericInput
            label="Direct others referral payment ratio"
            {...register('paymentRatio.directOthersReferral', {
              valueAsNumber: true,
            })}
            error={errors.paymentRatio?.directOthersReferral?.message}
          />
          <FormNumericInput
            label="Indirect referral payment ratio"
            {...register('paymentRatio.indirectReferral', {
              valueAsNumber: true,
            })}
            error={errors.paymentRatio?.indirectReferral?.message}
          />
          <FormInput
            label="About Me"
            placeholder="Enter about me"
            {...register('aboutMe')}
            error={errors.aboutMe?.message}
            type="textarea"
          />
          <CheckboxRow>
            <Controller
              name="active"
              control={control}
              render={({ field }) => {
                return (
                  <FormCheckbox
                    checked={field.value}
                    onChange={e => field.onChange(e.target.checked)}
                    error={errors.active?.message}
                    hideErrorMessage>
                    <p>Active</p>
                  </FormCheckbox>
                );
              }}
            />
            <Controller
              name="isVerified"
              control={control}
              render={({ field }) => {
                return (
                  <FormCheckbox
                    checked={field.value}
                    onChange={e => field.onChange(e.target.checked)}
                    error={errors.isVerified?.message}
                    hideErrorMessage>
                    <p>Verified</p>
                  </FormCheckbox>
                );
              }}
            />
          </CheckboxRow>
        </form>
      </FlexMain>
      <FlexFooter>
        <EditInfluencerButton
          title="Edit influencer"
          onClick={() => handleSubmit(onSubmit)()}
          isLoading={editInfluencerPending}
        />
      </FlexFooter>
      <CropImageModal
        ref={cropImageRef}
        onClose={() => {
          setValue('profilePhoto', null);
          cropImageRef.current.hide();
        }}
        onComplete={croppedImage => {
          setValue('profilePhoto', croppedImage);
          cropImageRef.current.hide();
        }}
        originalImage={getValues('profilePhoto')}
      />
    </>
  );
}

export default InfluencerEditTab;
