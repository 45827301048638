import {
  fetchInfluencers,
  editInfluencer,
  editSecretMode,
  fetchInfluencerById,
  fetchInfluencerAlbumMedia,
  uploadFreeInfluencerAlbumMedia,
  uploadInactiveInfluencerAlbumMedia,
  uploadPremiumInfluencerAlbumMedia,
  editPremiumInfluencerAlbumMedia,
  editInactiveInfluencerAlbumMedia,
  editFreeInfluencerAlbumMedia,
  createInfluencer,
  fetchInfluencerVariables,
  uploadCoverInfluencerAlbumMedia,
  editCoverInfluencerAlbumMedia,
  deleteCoverInfluencerAlbumMedia,
  promoteCoverInfluencerAlbumMedia,
} from './asyncThunks';

export const fetchInfluencersBuilder = builder => {
  builder.addCase(fetchInfluencers.pending, state => {
    state.fetchInfluencersInfo.pending = true;
  });
  builder.addCase(fetchInfluencers.fulfilled, (state, { payload }) => {
    const { data, total } = payload;
    state.fetchInfluencersInfo.pending = false;
    state.fetchInfluencersInfo.data = data;
    state.fetchInfluencersInfo.total = total;
  });
  builder.addCase(fetchInfluencers.rejected, state => {
    state.fetchInfluencersInfo.pending = false;
  });
};

export const fetchInfluencerByIdBuilder = builder => {
  builder.addCase(fetchInfluencerById.pending, state => {
    state.selectedInfluencerInfo.pending = true;
  });
  builder.addCase(fetchInfluencerById.fulfilled, (state, { payload }) => {
    state.selectedInfluencerInfo.influencer = payload;
    state.selectedInfluencerInfo.pending = false;
  });
  builder.addCase(fetchInfluencerById.rejected, state => {
    state.selectedInfluencerInfo.pending = false;
  });
};

export const createInfluencerBuilder = builder => {
  builder.addCase(createInfluencer.pending, state => {
    state.pending.createInfluencer = true;
  });
  builder.addCase(createInfluencer.fulfilled, (state, { payload }) => {
    state.pending.createInfluencer = false;
    state.fetchInfluencerVariablesInfo.data.businessConnections =
      state.fetchInfluencerVariablesInfo.data.businessConnections.filter(
        businessConnection => businessConnection.id !== payload,
      );
  });
  builder.addCase(createInfluencer.rejected, state => {
    state.pending.createInfluencer = false;
  });
};

export const editInfluencerBuilder = builder => {
  builder.addCase(editInfluencer.pending, state => {
    state.pending.editInfluencer = true;
  });
  builder.addCase(editInfluencer.fulfilled, (state, { payload }) => {
    state.pending.editInfluencer = false;
    state.selectedInfluencerInfo.influencer = payload;
  });
  builder.addCase(editInfluencer.rejected, state => {
    state.pending.editInfluencer = false;
  });
};

export const editSecretModeBuilder = builder => {
  builder.addCase(editSecretMode.pending, state => {
    state.pending.editSecretMode = true;
  });
  builder.addCase(editSecretMode.fulfilled, (state, { payload }) => {
    state.pending.editSecretMode = false;
    state.selectedInfluencerInfo.influencer = payload;
  });
  builder.addCase(editSecretMode.rejected, state => {
    state.pending.editSecretMode = false;
  });
};

export const fetchInfluencerAlbumMediaBuilder = builder => {
  builder.addCase(fetchInfluencerAlbumMedia.pending, state => {
    state.fetchInfluencerAlbumMediaInfo.pending = true;
  });
  builder.addCase(fetchInfluencerAlbumMedia.fulfilled, (state, { payload }) => {
    const { data, maxReached, page } = payload;
    state.fetchInfluencerAlbumMediaInfo.pending = false;
    state.fetchInfluencerAlbumMediaInfo.page = page + 1;
    state.fetchInfluencerAlbumMediaInfo.maxReached = maxReached;
    state.fetchInfluencerAlbumMediaInfo.data =
      page === 1 ? data : [...state.fetchInfluencerAlbumMediaInfo.data, ...data];
  });
  builder.addCase(fetchInfluencerAlbumMedia.rejected, state => {
    state.fetchInfluencerAlbumMediaInfo.pending = false;
  });
};

export const uploadFreeInfluencerAlbumMediaBuilder = builder => {
  builder.addCase(uploadFreeInfluencerAlbumMedia.pending, state => {
    state.pending.uploadAlbumMedia = true;
  });
  builder.addCase(uploadFreeInfluencerAlbumMedia.fulfilled, (state, { payload }) => {
    state.selectedInfluencerInfo.influencer.photosInfo.freePhotosSize++;
    // state.fetchInfluencerAlbumMediaInfo.data.unshift(payload);
    state.fetchInfluencerAlbumMediaInfo.data = [payload, ...state.fetchInfluencerAlbumMediaInfo.data];
    state.pending.uploadAlbumMedia = false;
  });
  builder.addCase(uploadFreeInfluencerAlbumMedia.rejected, state => {
    state.pending.uploadAlbumMedia = false;
  });
};

export const editFreeInfluencerAlbumMediaBuilder = builder => {
  builder.addCase(editFreeInfluencerAlbumMedia.pending, state => {
    state.pending.uploadAlbumMedia = true;
  });
  builder.addCase(editFreeInfluencerAlbumMedia.fulfilled, (state, { payload }) => {
    state.fetchInfluencerAlbumMediaInfo.data = state.fetchInfluencerAlbumMediaInfo.data.map(albumMedia => {
      if (albumMedia.id !== payload.id) {
        return albumMedia;
      } else {
        return payload;
      }
    });
    state.pending.uploadAlbumMedia = false;
  });
  builder.addCase(editFreeInfluencerAlbumMedia.rejected, state => {
    state.pending.uploadAlbumMedia = false;
  });
};

export const uploadInactiveInfluencerAlbumMediaBuilder = builder => {
  builder.addCase(uploadInactiveInfluencerAlbumMedia.pending, state => {
    state.pending.uploadAlbumMedia = true;
  });
  builder.addCase(uploadInactiveInfluencerAlbumMedia.fulfilled, (state, { payload }) => {
    state.selectedInfluencerInfo.influencer.photosInfo.inactivePhotosSize++;
    state.fetchInfluencerAlbumMediaInfo.data = [payload, ...state.fetchInfluencerAlbumMediaInfo.data];
    state.pending.uploadAlbumMedia = false;
  });
  builder.addCase(uploadInactiveInfluencerAlbumMedia.rejected, state => {
    state.pending.uploadAlbumMedia = false;
  });
};

export const editInactiveInfluencerAlbumMediaBuilder = builder => {
  builder.addCase(editInactiveInfluencerAlbumMedia.pending, state => {
    state.pending.uploadAlbumMedia = true;
  });
  builder.addCase(editInactiveInfluencerAlbumMedia.fulfilled, (state, { payload }) => {
    state.fetchInfluencerAlbumMediaInfo.data = state.fetchInfluencerAlbumMediaInfo.data.map(albumMedia => {
      if (albumMedia.id !== payload.id) {
        return albumMedia;
      } else {
        return payload;
      }
    });
    state.pending.uploadAlbumMedia = false;
  });
  builder.addCase(editInactiveInfluencerAlbumMedia.rejected, state => {
    state.pending.uploadAlbumMedia = false;
  });
};

export const uploadPremiumInfluencerAlbumMediaBuilder = builder => {
  builder.addCase(uploadPremiumInfluencerAlbumMedia.pending, state => {
    state.pending.uploadAlbumMedia = true;
  });
  builder.addCase(uploadPremiumInfluencerAlbumMedia.fulfilled, (state, { payload }) => {
    state.selectedInfluencerInfo.influencer.photosInfo.premiumPhotosSize++;
    state.fetchInfluencerAlbumMediaInfo.data = [payload, ...state.fetchInfluencerAlbumMediaInfo.data];
    state.pending.uploadAlbumMedia = false;
  });
  builder.addCase(uploadPremiumInfluencerAlbumMedia.rejected, state => {
    state.pending.uploadAlbumMedia = false;
  });
};

export const editPremiumInfluencerAlbumMediaBuilder = builder => {
  builder.addCase(editPremiumInfluencerAlbumMedia.pending, state => {
    state.pending.uploadAlbumMedia = true;
  });
  builder.addCase(editPremiumInfluencerAlbumMedia.fulfilled, (state, { payload }) => {
    state.fetchInfluencerAlbumMediaInfo.data = state.fetchInfluencerAlbumMediaInfo.data.map(albumMedia => {
      if (albumMedia.id !== payload.id) {
        return albumMedia;
      } else {
        return payload;
      }
    });
    state.pending.uploadAlbumMedia = false;
  });
  builder.addCase(editPremiumInfluencerAlbumMedia.rejected, state => {
    state.pending.uploadAlbumMedia = false;
  });
};

export const uploadCoverInfluencerAlbumMediaBuilder = builder => {
  builder.addCase(uploadCoverInfluencerAlbumMedia.pending, state => {
    state.pending.uploadAlbumMedia = true;
  });
  builder.addCase(uploadCoverInfluencerAlbumMedia.fulfilled, (state, { payload }) => {
    state.selectedInfluencerInfo.influencer.photosInfo.coverPhotosSize++;
    state.fetchInfluencerAlbumMediaInfo.data = [payload, ...state.fetchInfluencerAlbumMediaInfo.data];
    state.pending.uploadAlbumMedia = false;
  });
  builder.addCase(uploadCoverInfluencerAlbumMedia.rejected, state => {
    state.pending.uploadAlbumMedia = false;
  });
};

export const editCoverInfluencerAlbumMediaBuilder = builder => {
  builder.addCase(editCoverInfluencerAlbumMedia.pending, state => {
    state.pending.uploadAlbumMedia = true;
  });
  builder.addCase(editCoverInfluencerAlbumMedia.fulfilled, (state, { payload }) => {
    const { oldUrl, newUrl } = payload;
    state.fetchInfluencerAlbumMediaInfo.data = state.fetchInfluencerAlbumMediaInfo.data.map(url => {
      if (url === oldUrl) {
        return newUrl;
      } else {
        return url;
      }
    });
    state.pending.uploadAlbumMedia = false;
  });
  builder.addCase(editCoverInfluencerAlbumMedia.rejected, state => {
    state.pending.uploadAlbumMedia = false;
  });
};

export const deleteCoverInfluencerAlbumMediaBuilder = builder => {
  builder.addCase(deleteCoverInfluencerAlbumMedia.pending, state => {
    state.pending.uploadAlbumMedia = true;
  });
  builder.addCase(deleteCoverInfluencerAlbumMedia.fulfilled, (state, { payload }) => {
    state.fetchInfluencerAlbumMediaInfo.data = state.fetchInfluencerAlbumMediaInfo.data.filter(
      url => !url.endsWith(payload),
    );
    state.pending.uploadAlbumMedia = false;
  });
  builder.addCase(deleteCoverInfluencerAlbumMedia.rejected, state => {
    state.pending.uploadAlbumMedia = false;
  });
};

export const promoteCoverInfluencerAlbumMediaBuilder = builder => {
  builder.addCase(promoteCoverInfluencerAlbumMedia.pending, state => {
    state.pending.uploadAlbumMedia = true;
  });
  builder.addCase(promoteCoverInfluencerAlbumMedia.fulfilled, state => {
    state.pending.uploadAlbumMedia = false;
  });
  builder.addCase(promoteCoverInfluencerAlbumMedia.rejected, state => {
    state.pending.uploadAlbumMedia = false;
  });
};

export const fetchInfluencerVariablesBuilder = builder => {
  builder.addCase(fetchInfluencerVariables.pending, state => {
    state.fetchInfluencerVariablesInfo.pending = true;
  });
  builder.addCase(fetchInfluencerVariables.fulfilled, (state, { payload }) => {
    state.fetchInfluencerVariablesInfo.pending = false;
    state.fetchInfluencerVariablesInfo.data = payload;
  });
  builder.addCase(fetchInfluencerVariables.rejected, state => {
    state.fetchInfluencerVariablesInfo.pending = false;
  });
};
