import axiosApiClient from '..';
import { handleError, throwErrorIfWrongStatus } from './utils';

const BASE_URL = '/users';

class UserService {
  fetchUserInfo = async () => {
    try {
      const response = await axiosApiClient.get(`${BASE_URL}/me`, { withCredentials: true });
      throwErrorIfWrongStatus(response, 200);
      const { user, accessToken } = response.data.data;
      return { user, accessToken };
    } catch (err) {
      throw handleError(err, "Error occured while fetching user's info.");
    }
  };

  fetchUsers = async (page, search) => {
    try {
      const response = await axiosApiClient.get(`${BASE_URL}?page=${page}&search=${search}`);
      throwErrorIfWrongStatus(response, 200);
      const { data, total } = response.data.data;
      return { data, total };
    } catch (err) {
      throw handleError(err, 'Error occured while fetching users.');
    }
  };

  fetchUsersByInfluencer = async (influencerId, page, search) => {
    try {
      const response = await axiosApiClient.get(`${BASE_URL}/influencer/${influencerId}?page=${page}&search=${search}`);
      throwErrorIfWrongStatus(response, 200);
      const { data, total } = response.data.data;
      return { data, total };
    } catch (err) {
      throw handleError(err, 'Error occured while fetching users by influencer.');
    }
  };
}

const userService = new UserService();
export default userService;
