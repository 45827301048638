import * as yup from 'yup';

export const uploadFreeAlbumMediumFormSchema = yup
  .object({
    albumMedia: yup.mixed().required('Album media is required'),
  })
  .required();

export const uploadInactiveAlbumMediumFormSchema = yup
  .object({
    albumMedia: yup.mixed().required('Album media is required'),
    description: yup.string().trim().required('Description is required'),
  })
  .required();

export const uploadPremiumAlbumMediaFormSchema = yup
  .object({
    albumMedia: yup.mixed().required('Album media is required'),
    cost: yup
      .number()
      .integer()
      .typeError('Cost must be a whole number')
      .required('Cost is required')
      .positive('Cost must be positive'),
    isForAISale: yup.boolean().oneOf([true, false], 'Is for AI sale is required'),
    aiDescription: yup.string().when('isForAISale', {
      is: true,
      then: () => yup.string().trim().required('AI description is required'),
      otherwise: () => yup.string().trim().notRequired(),
    }),
    buttonDescription: yup.string().when('isForAISale', {
      is: true,
      then: () => yup.string().trim().required('Button description is required'),
      otherwise: () => yup.string().trim().notRequired(),
    }),
  })
  .required();

export const uploadCoverAlbumMediumFormSchema = yup
  .object({
    albumMedia: yup.mixed().required('Album media is required'),
  })
  .required();
