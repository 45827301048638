import React, { Fragment, useEffect, useState } from 'react';
import { Routes, Route, Navigate, Outlet } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';
import { AuthLayout, PrivateLayout, PublicLayout } from '../../layouts';
import GlobalStyles from '../../styles/globalStyles';
import { useDispatch, useSelector } from 'react-redux';
import { fetchUserInfo } from '../../store/slices/user/asyncThunks';
import 'react-toastify/dist/ReactToastify.css';
import 'react-datepicker/dist/react-datepicker.css';
import '../../styles/fonts.css';
import { selectLoggedIn } from '../../store/slices/auth/slice';
import InfluencerDetailsPage from '../../pages/influencers/InfluencerDetailsPage/InfluencerDetailsPage';
import ForbiddenPage from '../../pages/util/ForbiddenPage/ForbiddenPage';
import { ADMIN_ROLES, ALBUM_MEDIA_TYPE, INFLUENCER_ROLES } from '../../utils/constants';
import PrivateOutlet from '../navigations/PrivateOutlet/PrivateOutlet';
import PaginationOutlet from '../navigations/PaginationOutlet/PaginationOutlet';
import InfluencerEditTab from '../../pages/influencers/tabs/InfluencerEditTab/InfluencerEditTab';
import EditSecretModeTab from '../../pages/influencers/tabs/InfluencerSecretModesTab/EditSecretModeTab/EditSecretModeTab';
import SecretModesListTab from '../../pages/influencers/tabs/InfluencerSecretModesTab/SecretModesListTab/SecretModesListTab';
import InfluencerGalleriesListTab from '../../pages/influencers/tabs/InfluencerGalleryTab/InfluencerGalleriesListTab/InfluencerGalleriesListTab';
// import InfluencerProfilePage from '../../pages/ProfilePage/influencer/InfluencerProfilePage';
import LandingPage from '../../pages/LandingPage/LandingPage';
import LoginPage from '../../pages/auth/LoginPage/LoginPage';
import SplashPage from '../../pages/util/SplashPage/SplashPage';
import PageNotFound from '../../pages/util/PageNotFound/PageNotFound';
import InfluencersListPage from '../../pages/influencers/InfluencersListPage/InfluencersListPage';
import UsersPage from '../../pages/UsersPage';
import InfluencerGalleryTab from '../../pages/influencers/tabs/InfluencerGalleryTab/InfluencerGalleryTab/InfluencerGalleryTab';
import PhotoActivitiesPage from '../../pages/PhotoActivitiesPage/PhotoActivitiesPage';
import DashboardPage from '../../pages/DashboardPage';
import CreateInfluencerPage from '../../pages/influencers/CreateInfluencerPage/CreateInfluencerPage';

function App() {
  const [minimumDurationPassed, setMinimumDurationPassed] = useState(false);
  const isAuthenticated = useSelector(selectLoggedIn);
  const dispatch = useDispatch();

  useEffect(() => {
    const timer = setTimeout(() => setMinimumDurationPassed(true), 1000);
    return () => clearTimeout(timer);
  }, []);

  useEffect(() => {
    dispatch(fetchUserInfo());
  }, [dispatch]);

  return (
    <Fragment>
      <GlobalStyles />
      {minimumDurationPassed && isAuthenticated !== null ? (
        <>
          <ToastContainer newestOnTop={true} autoClose={3500} position={toast.POSITION.TOP_RIGHT} />
          <Routes>
            <Route element={<PublicLayout />}>
              <Route path="/" element={<LandingPage />} />
            </Route>
            <Route element={<AuthLayout />}>
              <Route path="/login" element={<LoginPage />} />
            </Route>
            <Route element={<PrivateLayout />}>
              {/* ADMINS + INFLUENCERS */}
              <Route element={<PrivateOutlet roles={[...INFLUENCER_ROLES, ...ADMIN_ROLES]} />}>
                <Route element={<PaginationOutlet />}>
                  <Route path="/users" element={<UsersPage />} />
                </Route>
              </Route>
              {/* ONLY ADMINS */}
              <Route element={<PrivateOutlet roles={ADMIN_ROLES} />}>
                <Route element={<PaginationOutlet />}>
                  <Route path="/influencers" element={<InfluencersListPage />} />
                </Route>
                <Route path="/influencers/new" element={<CreateInfluencerPage />} />
                <Route path="/influencers/:id" element={<InfluencerDetailsPage />}>
                  <Route index element={<Navigate to={'secret-modes'} replace />} />
                  <Route path="secret-modes" element={<Outlet />}>
                    <Route index element={<Navigate to={'list'} replace />} />
                    <Route path="list" element={<SecretModesListTab />} />
                    <Route path="edit/:secretModeId" element={<EditSecretModeTab />} />
                  </Route>
                  <Route path="gallery" element={<Outlet />}>
                    <Route index element={<Navigate to="list" replace />} />
                    <Route path="list" element={<InfluencerGalleriesListTab />} />
                    <Route path="free" element={<InfluencerGalleryTab albumMediaType={ALBUM_MEDIA_TYPE.FREE} />} />
                    <Route
                      path="premium"
                      element={<InfluencerGalleryTab albumMediaType={ALBUM_MEDIA_TYPE.PREMIUM} />}
                    />
                    <Route
                      path="inactive"
                      element={<InfluencerGalleryTab albumMediaType={ALBUM_MEDIA_TYPE.INACTIVE} />}
                    />
                    <Route path="cover" element={<InfluencerGalleryTab albumMediaType={ALBUM_MEDIA_TYPE.COVER} />} />
                  </Route>
                  <Route path="edit" element={<InfluencerEditTab />} />
                </Route>
                <Route element={<PaginationOutlet />}>
                  <Route path="photo-activities" element={<PhotoActivitiesPage />} />
                </Route>
              </Route>
              {/* ONLY INFLUENCERS */}
              <Route element={<PrivateOutlet roles={INFLUENCER_ROLES} />}>
                <Route path="/dashboard" element={<DashboardPage />} />
                {/* <Route path="/profile" element={<InfluencerProfilePage />} /> */}
              </Route>
            </Route>
            <Route path="/forbidden" element={<ForbiddenPage />} />
            <Route path="*" element={<PageNotFound />} />
          </Routes>
        </>
      ) : (
        <SplashPage />
      )}
    </Fragment>
  );
}

export default App;
