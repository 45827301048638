import React, { useState } from 'react';
import CreateInfluencerSidebar from './CreateInfluencerSidebar/CreateInfluencerSidebar';
import CreateInfluencerPageStepBasicInfo from './steps/CreateInfluencerPageStepBasicInfo/CreateInfluencerPageStepBasicInfo';
import CreateInfluencerPageStepAdditionalInfo from './steps/CreateInfluencerPageStepAdditionalInfo/CreateInfluencerPageStepAdditionalInfo';
import CreateInfluencerPageStepAlbumMedia from './steps/CreateInfluencerPageStepAlbumMedia/CreateInfluencerPageStepAlbumMedia';
import CreateInfluencerPageStepSecretModes from './steps/CreateInfluencerPageStepSecretModes/CreateInfluencerPageStepSecretModes';
import { useDispatch } from 'react-redux';
import { createInfluencer } from '../../../store/slices/influencer/asyncThunks';
import { notifyError, notifyInfo } from '../../../utils/notify';
import { useNavigate } from 'react-router-dom';

const FORM_INFO = {
  NUM_OF_STEPS: 4,
  PROP_NAME: {
    0: 'basicInfo',
    1: 'additionalInfo',
    2: 'albumMedia',
    3: 'secretModes',
  },
};

function CreateInfluencerPage() {
  const [formData, setFormData] = useState({
    basicInfo: null,
    additionalInfo: null,
    albumMedia: null,
    secretModes: null,
  });
  const [currentStep, setCurrentStep] = useState(0);
  const [selectedStep, setSelectedStep] = useState(0);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const onSubmit = (data, step) => {
    // Update selected and current steps
    if (step !== FORM_INFO.NUM_OF_STEPS - 1) {
      setFormData(state => ({ ...state, [FORM_INFO.PROP_NAME[step]]: { ...data } }));
      setSelectedStep(step => step + 1);
      if (step === currentStep) {
        setCurrentStep(step => step + 1);
      }
      return;
    }

    // Submit the form
    dispatch(createInfluencer({ ...formData, [FORM_INFO.PROP_NAME[FORM_INFO.NUM_OF_STEPS - 1]]: data }))
      .unwrap()
      .then(_ => {
        navigate('/influencers');
        notifyInfo('Influencer created successfully!');
      })
      .catch(err => {
        notifyError(err.message);
      });
  };

  const onBack = (data, step) => {
    if (step === 0) return;
    setSelectedStep(step => step - 1);
    setFormData(state => ({ ...state, [FORM_INFO.PROP_NAME[step]]: { ...data } }));
  };

  const renderMainContent = () => {
    switch (selectedStep) {
      case 0:
        return <CreateInfluencerPageStepBasicInfo step={0} onAfterSubmit={onSubmit} formData={formData} />;
      case 1:
        return (
          <CreateInfluencerPageStepAdditionalInfo
            step={1}
            onAfterSubmit={onSubmit}
            formData={formData}
            onBack={onBack}
          />
        );
      case 2:
        return (
          <CreateInfluencerPageStepAlbumMedia step={2} formData={formData} onAfterSubmit={onSubmit} onBack={onBack} />
        );
      case 3:
        return (
          <CreateInfluencerPageStepSecretModes step={3} formData={formData} onAfterSubmit={onSubmit} onBack={onBack} />
        );
      default:
        return null;
    }
  };

  return (
    <>
      <CreateInfluencerSidebar currentStep={currentStep} selectedStep={selectedStep} onChangeStep={setSelectedStep} />
      {renderMainContent()}
    </>
  );
}

export default CreateInfluencerPage;
