import React from 'react';
import { useSelector } from 'react-redux';
import { Navigate, Outlet } from 'react-router-dom';
import { selectLoggedIn } from '../../store/slices/auth/slice';
import { PublicHeader } from '../../components';
import { Container } from './AuthLayout.styles';
import { selectUser } from '../../store/slices/user/slice';
import { INFLUENCER_ROLES } from '../../utils/constants';

function AuthLayout() {
  const isAuthenticated = useSelector(selectLoggedIn);
  const user = useSelector(selectUser);

  if (isAuthenticated) {
    if (INFLUENCER_ROLES.includes(user.role)) {
      return <Navigate to="/dashboard" />;
    } else {
      return <Navigate to="/influencers" />;
    }
  }

  return (
    <Container>
      <PublicHeader />
      <Outlet />
    </Container>
  );
}

export default AuthLayout;
