import * as yup from 'yup';

// secretModeFormSchema

const promptBaseFormSchema = yup
  .object({
    mainMessage: yup.object().shape({
      template: yup.string().trim().required('Prompt template is required'),
      dynamicSection: yup.string().optional(),
      initialMessage: yup.string().trim().required('Prompt initial message is required'),
    }),
    // voiceCall: yup.object().shape({
    //   template: yup.string().trim().required('Voice call prompt template is required'),
    //   dynamicSection: yup.string().optional(),
    // }),
    // videoCall: yup.object().shape({
    //   template: yup.string().trim().required('Video call prompt template is required'),
    //   dynamicSection: yup.string().optional(),
    // }),
    inactiveMessage: yup.object().shape({
      template: yup.string().trim().required('Inactive prompt template is required'),
    }),
  })
  .required();

export const editSecretModeFormSchema = yup
  .object({
    name: yup.string().trim().required('Name is required').max(255, 'Max length reached'),
    symbol: yup.string().trim().required('Symbol is required').max(5, 'Max length reached'),
    welcomePhoto: yup.mixed().required('Welcome photo is required'),
    suggestedReply: yup.string().trim().required('Suggested reply is required'),
    callOptions: yup.object().shape({
      isVoiceCallEnabled: yup.boolean().oneOf([true, false], 'Voice call enabled flag is required'),
      isVideoCallEnabled: yup.boolean().oneOf([true, false], 'Video call enabled flag is required'),
    }),
    pricing: yup.object().shape({
      textMessage: yup
        .number()
        .typeError('Text message price must be a number')
        .required('Text message price is required')
        .positive('Text message price must be positive'),
      voiceMessage: yup
        .number()
        .typeError('Voice message price must be a number')
        .required('Voice message price must is required')
        .positive('Voice message price must must be positive'),
      // voiceCallPerMinute: yup.number().nullable().optional().typeError('Must be a number'),
      // videoCallPerMinute: yup.number().nullable().optional().typeError('Must be a number'),
    }),
    isDefault: yup.boolean().oneOf([true, false], 'Default flag is required'),
    prompt: yup.object().shape({
      male: promptBaseFormSchema,
      female: promptBaseFormSchema,
      other: promptBaseFormSchema,
    }),
    voiceId: yup.string().trim().required('Voice id is required'),
    voiceConfig: yup.object().shape({
      stability: yup
        .number()
        .typeError('Voice config stability must be a number')
        .required('Voice config stability is required')
        .positive('Voice config stability must be positive'),
      similarityBoost: yup
        .number()
        .typeError('Voice config similarity boost must be a number')
        .required('Voice config similarity boost is required')
        .positive('Voice config similarity boost must be positive'),
      style: yup
        .number()
        .typeError('Voice config style must be a number')
        .required('Voice config style is required')
        .positive('Voice config style must be positive'),
      useSpeakerBoost: yup.boolean().oneOf([true, false], 'Speaker boost is required'),
    }),
    voiceCall: yup.object().shape({
      initMessage: yup.mixed().nullable(),
      vapi: yup.object().shape({
        assistantId: yup.string().optional(),
      }),
    }),
    videoCall: yup.object().shape({
      replicaId: yup.string().optional(),
      personaId: yup.string().optional(),
    }),
    welcomeMessage: yup.object().shape({
      initial: yup.string().required('Initial welcome message is required'),
      others: yup
        .array()
        .of(yup.string().required('This field is required'))
        .min(1, 'At least one other welcome message is required'),
    }),
    insufficientFundsMessages: yup
      .array()
      .of(yup.string().required('This field is required'))
      .min(1, 'At least one insufficient funds message is required'),
    inactiveMessages: yup
      .array()
      .of(yup.string().required('This field is required'))
      .min(1, 'At least one inactive message is required'),
  })
  .required();

export const createSecretModeAsAdminFormSchema = yup
  .object({
    name: yup.string().trim().required('Name is required').max(255, 'Max length reached'),
    symbol: yup.string().trim().required('Symbol is required').max(5, 'Max length reached'),
    welcomePhoto: yup.mixed().required('Welcome photo is required'),
    suggestedReply: yup.string().trim().required('Suggested reply is required'),
    pricing: yup.object().shape({
      textMessage: yup
        .number()
        .typeError('Text message price must be a number')
        .required('Text message price is required')
        .positive('Text message price must be positive'),
      voiceMessage: yup
        .number()
        .typeError('Voice message price must be a number')
        .required('Voice message price must is required')
        .positive('Voice message price must must be positive'),
      // voiceCallPerMinute: yup.number().nullable().optional().typeError('Must be a number'),
      // videoCallPerMinute: yup.number().nullable().optional().typeError('Must be a number'),
    }),
    voiceCall: yup.object().shape({
      voice: yup.mixed().nullable(),
      vapi: yup.object().shape({
        assistantId: yup.string().optional(),
      }),
    }),
    videoCall: yup.object().shape({
      replicaId: yup.string().optional(),
      personaId: yup.string().optional(),
    }),
    isDefault: yup.boolean().oneOf([true, false], 'Default flag is required'),
    prompt: yup.object().shape({
      male: promptBaseFormSchema,
      female: promptBaseFormSchema,
      other: promptBaseFormSchema,
    }),
    welcomeMessage: yup.object().shape({
      initial: yup.string().required('Initial welcome message is required'),
      others: yup
        .array()
        .of(yup.string().required('This field is required'))
        .min(1, 'At least one other welcome message is required'),
    }),
    insufficientFundsMessages: yup
      .array()
      .of(yup.string().required('This field is required'))
      .min(1, 'At least one insufficient funds message is required'),
    inactiveMessages: yup
      .array()
      .of(yup.string().required('This field is required'))
      .min(1, 'At least one inactive message is required'),
    voiceConfig: yup.object().shape({
      stability: yup
        .number()
        .typeError('Voice config stability must be a number')
        .required('Voice config stability is required')
        .positive('Voice config stability must be positive'),
      similarityBoost: yup
        .number()
        .typeError('Voice config similarity boost must be a number')
        .required('Voice config similarity boost is required')
        .positive('Voice config similarity boost must be positive'),
      style: yup
        .number()
        .typeError('Voice config style must be a number')
        .required('Voice config style is required')
        .positive('Voice config style must be positive'),
      useSpeakerBoost: yup.boolean().oneOf([true, false], 'Speaker boost is required'),
    }),
  })
  .required();
