import React, { useMemo } from 'react';
import { default as CollapsibleComponent } from 'react-collapsible';
import { ExpandLessIcon, ExpandMoreIcon, Header, Row, Title } from './Collapsible.styles';

function Collapsible({ id, children, title, openedCollapsible, onClick, icon }) {
  const opened = useMemo(() => {
    return id === openedCollapsible;
  }, [id, openedCollapsible]);

  return (
    <CollapsibleComponent
      open={opened}
      handleTriggerClick={() => {
        onClick(opened ? null : id);
      }}
      easing={'ease-in'}
      trigger={
        <Header>
          <Row>
            {icon}
            <Title>{title}</Title>
          </Row>
          {opened ? <ExpandLessIcon /> : <ExpandMoreIcon />}
        </Header>
      }>
      {children}
    </CollapsibleComponent>
  );
}

export default Collapsible;
