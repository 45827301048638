import * as yup from 'yup';

export const createPhotoActivityFormSchema = yup
  .object({
    name: yup.string().trim().required('Name is required'),
    cost: yup
      .number()
      .integer()
      .typeError('Cost must be a whole number')
      .required('Cost is required')
      .positive('Cost must be positive'),
    descriptionPrompt: yup.string().trim().required('Description prompt is required'),
    llmPrompt: yup.string().trim().required('LLM prompt is required'),
  })
  .required();
