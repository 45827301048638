import styled from 'styled-components';

export const FlexHeader = styled.header`
  height: 4rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 2rem;
  margin: 2rem 2rem 0 2rem;
`;

export const FlexMain = styled.main`
  overflow-y: auto;
  margin: 1rem 0 1rem 0;
  padding: 1rem 2rem 1rem 2rem;
  margin-bottom: auto;

  /* &::-webkit-scrollbar {
    width: 0.7rem;
  }

  &::-webkit-scrollbar-thumb {
    background: #d3d3d3;
    border-radius: 1.6rem;
  } */

  /* &::-webkit-scrollbar-track {
    background: #d3d3d300;
  } */
`;

export const FlexFooter = styled.footer`
  height: 5.4rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 2rem;
  margin: 0 2rem 2rem 2rem;
`;

export const Divider = styled.div`
  width: 100%;
  margin: 2rem 0;
  height: 1px;
  background: linear-gradient(270deg, #fff 0%, rgba(254, 145, 50, 0) 0.01%, #a0a0a0 50.52%, rgba(210, 52, 2, 0) 100%);
  opacity: 0.5;
`;

export const Row = styled.div`
  display: flex;
  align-items: center;
`;
