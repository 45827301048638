import React, { forwardRef } from 'react';
import ErrorContainer from '../../ErrorContainer/ErrorContainer';
import { StyledInput, StyledLabel } from './NumericInput.styles';
import { InputLabel } from '../../texts/text.styles';

const NumericInput = forwardRef(
  (
    {
      defaultValue,
      value,
      placeholder,
      onChange,
      autoFocus,
      disabled,
      className,
      error,
      hideErrorMessage,
      type,
      step,
      label,
      ...inputProps
    },
    ref,
  ) => {
    return (
      <StyledLabel disabled={disabled} className={className}>
        {label && <InputLabel>{label}</InputLabel>}
        <StyledInput
          ref={ref}
          placeholder={placeholder ? placeholder : '0.00'}
          type={type ? type : 'number'}
          defaultValue={defaultValue}
          value={value}
          onChange={onChange}
          autoFocus={autoFocus}
          disabled={disabled}
          step={step ? step : 'any'}
          onWheel={e => e.target.blur()}
          {...inputProps}
        />
        {!hideErrorMessage && <ErrorContainer errorText={error} />}
      </StyledLabel>
    );
  },
);

export default NumericInput;
