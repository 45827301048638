import { createPhotoActivity, deletePhotoActivity, fetchPhotoActivities, editPhotoActivity } from './asyncThunks';

export const fetchPhotoActivitiesBuilder = builder => {
  builder.addCase(fetchPhotoActivities.pending, state => {
    state.fetchPhotoActivitiesInfo.pending = true;
  });
  builder.addCase(fetchPhotoActivities.fulfilled, (state, { payload }) => {
    const { data, total } = payload;
    state.fetchPhotoActivitiesInfo.pending = false;
    state.fetchPhotoActivitiesInfo.data = data;
    state.fetchPhotoActivitiesInfo.total = total;
  });
  builder.addCase(fetchPhotoActivities.rejected, state => {
    state.fetchPhotoActivitiesInfo.pending = false;
  });
};

export const createPhotoActivityBuilder = builder => {
  builder.addCase(createPhotoActivity.pending, state => {
    state.pending.createPhotoActivity = true;
  });
  builder.addCase(createPhotoActivity.fulfilled, state => {
    state.pending.createPhotoActivity = false;
  });
  builder.addCase(createPhotoActivity.rejected, state => {
    state.pending.createPhotoActivity = false;
  });
};

export const editPhotoActivityBuilder = builder => {
  builder.addCase(editPhotoActivity.pending, state => {
    state.pending.editPhotoActivity = true;
  });
  builder.addCase(editPhotoActivity.fulfilled, state => {
    state.pending.editPhotoActivity = false;
  });
  builder.addCase(editPhotoActivity.rejected, state => {
    state.pending.editPhotoActivity = false;
  });
};

export const deletePhotoActivityBuilder = builder => {
  builder.addCase(deletePhotoActivity.pending, state => {
    state.pending.deletePhotoActivity = true;
  });
  builder.addCase(deletePhotoActivity.fulfilled, state => {
    state.pending.deletePhotoActivity = false;
  });
  builder.addCase(deletePhotoActivity.rejected, state => {
    state.pending.deletePhotoActivity = false;
  });
};
