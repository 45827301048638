import React, { forwardRef, useImperativeHandle, useState } from 'react';
import ReactModal from 'react-modal';
import {
  CloseIcon,
  Content,
  Footer,
  Header,
  HeaderIconContainer,
  LeftButton,
  ModalStyle,
  OverlayStyle,
  RightButton,
} from './FormModal.styles';
import MainLoader from '../../loaders/MainLoader/MainLoader';
import { Text24ExtraBold } from '../../texts/text.styles';

const FormModal = forwardRef(
  (
    {
      title,
      children,
      closeTimeoutMS = 300,
      onClose,
      onAfterClose,
      shouldCloseOnOverlayClick = true,
      loading,
      leftButtonText,
      onLeftButtonClick,
      rightButtonText,
      onRightButtonClick,
      rightButtonLoader = false,
      increasedWidth = false,
    },
    ref,
  ) => {
    const [show, setShow] = useState(false);

    useImperativeHandle(ref, () => ({
      show() {
        setShow(true);
      },
      hide() {
        setShow(false);
      },
      toggle() {
        setShow(prev => !prev);
      },
    }));

    return (
      <ReactModal
        isOpen={show}
        closeTimeoutMS={closeTimeoutMS}
        onRequestClose={onClose}
        onAfterClose={onAfterClose}
        className="_"
        overlayClassName="_"
        contentElement={props => (
          <ModalStyle {...props} $increasedWidth={increasedWidth}>
            {loading ? (
              <MainLoader />
            ) : (
              <>
                <Header>
                  <Text24ExtraBold>{title}</Text24ExtraBold>
                  <HeaderIconContainer>{!!onClose && <CloseIcon onClick={onClose} />}</HeaderIconContainer>
                </Header>
                <Content>{children}</Content>
                <Footer>
                  {!!leftButtonText && !!onLeftButtonClick ? (
                    <LeftButton title={leftButtonText} onClick={onLeftButtonClick} />
                  ) : (
                    <></>
                  )}
                  {!!rightButtonText && !!onRightButtonClick ? (
                    <RightButton title={rightButtonText} onClick={onRightButtonClick} isLoading={rightButtonLoader} />
                  ) : (
                    <></>
                  )}
                </Footer>
              </>
            )}
          </ModalStyle>
        )}
        overlayElement={(props, contentElement) => <OverlayStyle {...props}>{contentElement}</OverlayStyle>}
        ariaHideApp={false}
        shouldCloseOnEsc={shouldCloseOnOverlayClick || !!loading}
        shouldCloseOnOverlayClick={shouldCloseOnOverlayClick}
      />
    );
  },
);

export default FormModal;
