import React, { Fragment } from 'react';
import { TableBodyItem, TableItemImage } from '../../Table.styles';
import { ActiveIcon, InactiveIcon } from './InfluencerTableItem.styles';
import { INFLUENCER_STATUS } from '../../../../utils/constants';

function InfluencerTableItem({
  name,
  profilePhoto,
  username,
  email,
  status,
  secretModes,
  // onDetailsClicked,
  // onCopyClicked,
}) {
  return (
    <Fragment>
      <TableBodyItem $fixedWidth={8}>
        {status === INFLUENCER_STATUS.ACTIVE ? (
          <ActiveIcon width="14px" height="14px" />
        ) : status === INFLUENCER_STATUS.INACTIVE ? (
          <InactiveIcon width="14px" height="14px" />
        ) : null}
      </TableBodyItem>
      <TableBodyItem>
        <TableItemImage src={profilePhoto} alt="influencer-profile" />
        <p>{name || '-'}</p>
      </TableBodyItem>
      <TableBodyItem>
        <p>{username || '-'}</p>
      </TableBodyItem>
      <TableBodyItem>
        <p>{email || '-'}</p>
      </TableBodyItem>
      <TableBodyItem>
        <p>{secretModes || '-'}</p>
      </TableBodyItem>
      {/* <ActionTableBodyItem>
        <PreviewIcon onClick={onDetailsClicked} />
        <CopyIcon onClick={onCopyClicked} />
      </ActionTableBodyItem> */}
    </Fragment>
  );
}

export default InfluencerTableItem;
