import styled from 'styled-components';
import { Text24Bold } from '../../../../../components/utils/texts/text.styles';
import { MainButton } from '../../../../../components/utils/buttons/Button/Button';

export const Title = styled(Text24Bold)``;

export const AddToGalleryButton = styled(MainButton)`
  width: 24rem;
  height: 100%;
  font-size: 1.5rem;
`;

export const AlbumMediaListContainer = styled.div`
  display: grid;
  width: 100%;
  grid-template-columns: repeat(auto-fill, minmax(24rem, 1fr));
  column-gap: 3.6rem;
  row-gap: 3.6rem;
  padding: 1rem;
`;
