import { createSlice } from '@reduxjs/toolkit';
import { fetchUserInfoBuilder, fetchUsersBuilder, fetchUsersByInfluencerBuilder, loginBuilder } from './builder';
import { LOGOUT } from '../../../utils/constants';

const INIT_STATE = {
  fetchUserInfoPending: false,
  user: {
    id: null,
    email: null,
    fullName: null,
    role: null,
    roleSpecificData: null,
  },
  fetchUsersInfo: {
    pending: false,
    data: [],
    total: 0,
  },
  fetchUsersByInfluencerInfo: {
    pending: false,
    data: [],
    total: 0,
  },
};

export const userSlice = createSlice({
  name: 'user',
  initialState: INIT_STATE,
  reducers: {
    setRoleSpecificData: (state, { payload }) => {
      state.user.roleSpecificData = payload;
    },
  },
  extraReducers: builder => {
    fetchUserInfoBuilder(builder);
    loginBuilder(builder);
    fetchUsersBuilder(builder);
    fetchUsersByInfluencerBuilder(builder);
    builder.addCase(LOGOUT, () => ({ ...INIT_STATE }));
  },
});

export const selectFetchUserInfoPending = state => state.userInfo.fetchUserInfoPending;
export const selectUser = state => state.userInfo.user;
export const selectFetchUsersInfo = state => state.userInfo.fetchUsersInfo;
export const selectFetchUsersByInfluencerInfo = state => state.userInfo.fetchUsersByInfluencerInfo;

export const { setRoleSpecificData } = userSlice.actions;

export default userSlice.reducer;
