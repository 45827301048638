import React from 'react';
import { Navigate, Outlet, useLocation, useSearchParams } from 'react-router-dom';

function PaginationOutlet() {
  const [search] = useSearchParams();
  const pageParam = search.get('page');

  let { pathname } = useLocation();

  return pageParam ? <Outlet /> : <Navigate to={`${pathname}?page=1`} replace />;
}

export default PaginationOutlet;
