import styled from 'styled-components';
import { Transition, theme } from '../../../../styles/constants';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  font-weight: 300;
`;

export const SingleValueText = styled.span`
  color: ${({ theme }) => theme.colors.mainText};
  font-weight: 300;
  font-size: 1.6rem;
`;

export const selectStyles = () => ({
  control: baseStyles => ({
    ...baseStyles,
    background: theme.colors.white,
    minHeight: '4.8rem',
    borderRadius: '12px',
    fontSize: '1.6rem',
    boxShadow: 'none',
    outline: 'none',
    borderColor: 'rgba(73, 67, 93, 0.2)',
    fontWeight: '300',
    cursor: 'pointer',
    '&:hover': {
      borderColor: 'rgba(73, 67, 93, 0.2)',
    },
  }),
  menu: baseStyles => ({
    ...baseStyles,
    background: theme.colors.white,
    borderRadius: '8px',
    border: '1px solid',
    boxShadow: 'none',
    borderColor: 'rgba(73, 67, 93, 0.2)',
  }),
  singleValue: baseStyles => ({
    ...baseStyles,
    fontWeight: '300',
    fontSize: '1.6rem',
  }),
  option: baseStyles => ({
    ...baseStyles,
    display: 'flex',
    alignItems: 'center',
    backgroundColor: 'transparent',
    gap: '1rem',
    color: theme.colors.mainText,

    fontWeight: '300',
    fontSize: '1.6rem',
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: '#adaae160',
      transition: Transition.hover.on,
    },
  }),
  placeholder: baseStyles => ({
    ...baseStyles,
    color: baseStyles.color,
  }),
  indicatorSeparator: () => ({ display: 'none' }),
  dropdownIndicator: baseStyles => ({
    ...baseStyles,
    color: theme.colors.mainText,
  }),
});
