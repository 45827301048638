import styled from 'styled-components';
import { MainButton } from '../../../../components/utils/buttons/Button/Button';

export const EditInfluencerButton = styled(MainButton)`
  margin-top: 2.4rem;
`;

export const CheckboxRow = styled.div`
  display: flex;
  align-items: center;
  gap: 4rem;
`;
