import React, { memo } from 'react';
import { Container, DeleteIcon, PrimaryActionContainer, SecondaryActionContainer, StarIcon } from './AlbumMedia.styles';
import Image from '../utils/Image/Image';

const AlbumMedia = ({
  item,
  onClick,
  onClickParams,
  src,
  onDelete,
  onSecondaryAction,
  type,
  index,
  alt,
  className,
  innerRef,
}) => {
  // onClick params
  const onClickAllParams = [];
  item && onClickAllParams.push(item);
  onClickParams && onClickAllParams.push(...Object.values(onClickParams));

  return (
    <Container ref={innerRef} className={className}>
      <Image src={src} alt={alt} onClick={() => onClick(...onClickAllParams)} />
      {!!onDelete && (
        <PrimaryActionContainer onClick={event => onDelete(event, type, index)}>
          <DeleteIcon />
        </PrimaryActionContainer>
      )}
      {!!onSecondaryAction && (
        <SecondaryActionContainer onClick={event => onSecondaryAction(event, type, index)}>
          <StarIcon />
        </SecondaryActionContainer>
      )}
    </Container>
  );
};

export default memo(AlbumMedia);
