import * as yup from 'yup';
import {
  uploadCoverAlbumMediumFormSchema,
  uploadFreeAlbumMediumFormSchema,
  uploadInactiveAlbumMediumFormSchema,
  uploadPremiumAlbumMediaFormSchema,
} from './albumMedia.schema';
import { createSecretModeAsAdminFormSchema } from './secretMode.schema';

export const createInfluencerStepBasicInfoFormSchema = yup
  .object({
    name: yup.string().trim().required('Name is required').max(255, 'Max length reached'),
    nickname: yup.string().trim().required('Nickname is required').max(255, 'Max length reached'),
    username: yup.string().trim().required('Username is required').max(255, 'Max length reached'),
    email: yup.string().trim().required('Email is required').email('Invalid email'),
    password: yup.string().trim().required('Password is required').min(6, 'Password must have at least 6 characters'),
    passwordConfirmation: yup
      .string()
      .trim()
      .required('Password confirmation is required')
      .oneOf([yup.ref('password'), ''], "Passwords don't match!"),
    dateOfBirth: yup
      .date()
      .required('Birth of date is required')
      .max(new Date(new Date().setFullYear(new Date().getFullYear() - 18)), 'Influencer must be 18+'),
    profilePhoto: yup.mixed().required('Profile photo is required'),
    voice: yup.mixed().required('Voice is required'),
    location: yup.string().trim().required('Location is required').max(255, 'Max length reached'),
    category: yup.mixed().required('Category is required'),
    gender: yup.mixed().required('Gender is required'),
  })
  .required();

export const createInfluencerStepAdditionalInfoFormSchema = yup
  .object({
    businessConnection: yup.mixed().required('Business connection is required'),
    personalityTags: yup.array().min(1, 'At least one personality tag is required'),
    appearanceTags: yup.array().min(1, 'At least one appearance tag is required'),
    highlightTags: yup.array(),
    paymentRatio: yup.object().shape({
      directReferral: yup
        .number()
        .typeError('Direct referral payment ratio must be a number')
        .required('Direct referral payment ratio is required')
        .min(0, 'Direct referral payment ratio cannot be below 0')
        .max(1, 'Direct referral payment ratio cannot be above 1'),
      directForeigner: yup
        .number()
        .typeError('Direct foreigner payment ratio must be a number')
        .required('Direct foreigner payment ratio is required')
        .min(0, 'Direct foreigner payment ratio cannot be below 0')
        .max(1, 'Direct foreigner payment ratio cannot be above 1'),
      directOthersReferral: yup
        .number()
        .typeError('Direct others referral payment ratio must be a number')
        .required('Direct others referral payment ratio is required')
        .min(0, 'Direct others referral payment ratio cannot be below 0')
        .max(1, 'Direct others referral payment ratio cannot be above 1'),
      indirectReferral: yup
        .number()
        .typeError('Indirect referral payment ratio must be a number')
        .required('Indirect referral payment ratio is required')
        .min(0, 'Indirect referral payment ratio cannot be below 0')
        .max(1, 'Indirect referral payment ratio cannot be above 1'),
    }),
    score: yup
      .number()
      .typeError('Score must be a number')
      .required('Score is required')
      .positive('Score must be positive'),
    aboutMe: yup.string().trim().required('About me is required').max(2000, 'Max length reached'),
    isVerified: yup.boolean().oneOf([true, false], 'Is verified flag is required'),
    active: yup.boolean().oneOf([true, false], 'Active flag is required'),
    callOptions: yup.object().shape({
      isVoiceCallEnabled: yup.boolean().oneOf([true, false], 'Voice call flag is required'),
      isVideoCallEnabled: yup.boolean().oneOf([true, false], 'Video call flag is required'),
    }),
  })
  .required();

export const createInfluencerStepAlbumMediaFormSchema = yup
  .object({
    freeAlbumMedia: yup
      .array()
      .of(uploadFreeAlbumMediumFormSchema)
      .min(1, 'At least one free album medium is required'),
    premiumAlbumMedia: yup
      .array()
      .of(uploadPremiumAlbumMediaFormSchema)
      .min(1, 'At least one premium album medium is required'),
    inactiveAlbumMedia: yup
      .array()
      .of(uploadInactiveAlbumMediumFormSchema)
      .min(1, 'At least one inactive album medium is required'),
    coverAlbumMedia: yup
      .array()
      .of(uploadCoverAlbumMediumFormSchema)
      .min(1, 'At least one cover album medium is required'),
  })
  .required();

export const createInfluencerStepSecretModesFormSchema = yup
  .object({
    secretModes: yup.array().of(createSecretModeAsAdminFormSchema).min(1, 'At least one secret mode is required'),
  })
  .required();

export const editInfluencerFormSchema = yup
  .object({
    profilePhoto: yup.mixed().required('Profile photo is required'),
    name: yup.string().trim().required('Name is required').max(255, 'Max length reached'),
    nickname: yup.string().trim().required('Nickname is required').max(255, 'Max length reached'),
    email: yup.string().trim().required('Email is required').email('Invalid email'),
    dateOfBirth: yup
      .date()
      .required('Birth of date is required')
      .max(new Date(new Date().setFullYear(new Date().getFullYear() - 18)), 'Influencer must be 18+'),
    location: yup.string().trim().required('Location is required').max(255, 'Max length reached'),
    category: yup.mixed().required('Category is required'),
    gender: yup.mixed().required('Gender is required'),
    personalityTags: yup.array().min(1, 'At least one personality tag is required'),
    appearanceTags: yup.array().min(1, 'At least one appearance tag is required'),
    highlightTags: yup.array(),
    paymentRatio: yup.object().shape({
      directReferral: yup
        .number()
        .typeError('Direct referral payment ratio must be a number')
        .required('Direct referral payment ratio is required')
        .min(0, 'Direct referral payment ratio cannot be below 0')
        .max(1, 'Direct referral payment ratio cannot be above 1'),
      directForeigner: yup
        .number()
        .typeError('Direct foreigner payment ratio must be a number')
        .required('Direct foreigner payment ratio is required')
        .min(0, 'Direct foreigner payment ratio cannot be below 0')
        .max(1, 'Direct foreigner payment ratio cannot be above 1'),
      directOthersReferral: yup
        .number()
        .typeError('Direct others referral payment ratio must be a number')
        .required('Direct others referral payment ratio is required')
        .min(0, 'Direct others referral payment ratio cannot be below 0')
        .max(1, 'Direct others referral payment ratio cannot be above 1'),
      indirectReferral: yup
        .number()
        .typeError('Indirect referral payment ratio must be a number')
        .required('Indirect referral payment ratio is required')
        .min(0, 'Indirect referral payment ratio cannot be below 0')
        .max(1, 'Indirect referral payment ratio cannot be above 1'),
    }),
    score: yup
      .number()
      .typeError('Score must be a number')
      .required('Score is required')
      .positive('Score must be positive'),
    aboutMe: yup.string().trim().required('About me is required').max(2000, 'Max length reached'),
    isVerified: yup.boolean().oneOf([true, false], 'Is verified flag is required'),
    active: yup.boolean().oneOf([true, false], 'Active flag is required'),
  })
  .required();
