import { toast } from 'react-toastify';

export const notifyInfo = message =>
  toast.success(message, {
    position: 'top-right',
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    progressClassName: 'success-toast-message-progress',
    className: 'success-toast-message',
  });

export const notifyError = message =>
  toast.error(message, {
    position: 'top-right',
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    progressClassName: 'error-toast-message-progress',
    className: 'error-toast-message',
  });
