import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import FormModal from '../../utils/modals/FormModal/FormModal';
import { useDispatch, useSelector } from 'react-redux';
import { notifyError, notifyInfo } from '../../../utils/notify';
import {
  selectCreatePhotoActivityPending,
  selectEditPhotoActivityPending,
} from '../../../store/slices/photoActivity/slice';
import { createPhotoActivity, editPhotoActivity } from '../../../store/slices/photoActivity/asyncThunks';
import { createPhotoActivityFormSchema } from '../../../forms/schemas/photoActivity.schema';
import { FormInput, FormNumericInput } from '../../../forms/form.styles';

const CreatePhotoActivityFormModal = ({ modalRef, selectedPhotoActivity, onSuccess, onAfterClose }) => {
  const createPhotoActivityPending = useSelector(selectCreatePhotoActivityPending);
  const editPhotoActivityPending = useSelector(selectEditPhotoActivityPending);
  const dispatch = useDispatch();

  const {
    register,
    handleSubmit,
    setValue,
    reset,
    formState: { errors },
  } = useForm({
    defaultValues: {
      name: '',
      cost: '',
      descriptionPrompt: '',
      llmPrompt: '',
    },
    delayError: 300,
    resolver: yupResolver(createPhotoActivityFormSchema),
  });

  useEffect(() => {
    if (selectedPhotoActivity) {
      const { name, cost, descriptionPrompt, llmPrompt } = selectedPhotoActivity;
      setValue('name', name);
      setValue('cost', cost);
      setValue('descriptionPrompt', descriptionPrompt);
      setValue('llmPrompt', llmPrompt);
    }
  }, [selectedPhotoActivity, setValue]);

  const onSubmit = data => {
    dispatch(createPhotoActivity(data))
      .unwrap()
      .then(_ => {
        reset();
        modalRef.current.hide();
        notifyInfo('Photo activity created successfully!');
        onSuccess();
      })
      .catch(err => {
        notifyError(err.message);
      });
  };

  const onEditPhotoActivity = data => {
    dispatch(editPhotoActivity({ ...data, id: selectedPhotoActivity.id }))
      .unwrap()
      .then(_ => {
        reset();
        modalRef.current.hide();
        notifyInfo('Photo activity edited successfully!');
        onSuccess();
      })
      .catch(err => {
        notifyError(err.message);
      });
  };

  return (
    <FormModal
      ref={modalRef}
      title={selectedPhotoActivity ? 'Edit Photo Activity' : 'Create Photo Activity'}
      onClose={() => {
        modalRef.current.hide();
      }}
      onAfterClose={() => {
        reset();
        onAfterClose();
      }}
      leftButtonText="Cancel"
      onLeftButtonClick={() => {
        modalRef.current.hide();
      }}
      rightButtonText={selectedPhotoActivity ? 'Edit' : 'Submit'}
      onRightButtonClick={() => handleSubmit(selectedPhotoActivity ? onEditPhotoActivity : onSubmit)()}
      rightButtonLoader={selectedPhotoActivity ? editPhotoActivityPending : createPhotoActivityPending}
      shouldCloseOnOverlayClick={false}>
      <form>
        <FormInput label="Name" placeholder="Enter name" {...register('name')} error={errors.name?.message} />
        <FormNumericInput
          label="Cost (credits)"
          {...register('cost', {
            valueAsNumber: true,
          })}
          error={errors.cost?.message}
        />
        <FormInput
          label="Description Prompt"
          placeholder="Enter description prompt"
          {...register('descriptionPrompt')}
          error={errors.descriptionPrompt?.message}
          type="textarea"
        />
        <FormInput
          label="LLM Prompt"
          placeholder="Enter llm prompt"
          {...register('llmPrompt')}
          error={errors.llmPrompt?.message}
          type="textarea"
        />
      </form>
    </FormModal>
  );
};

export default CreatePhotoActivityFormModal;
