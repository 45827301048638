import { createSlice } from '@reduxjs/toolkit';
import { fetchUserInfoBuilder, loginBuilder } from './builder';
import { LOGOUT } from '../../../utils/constants';

const INIT_STATE = {
  loggedIn: null,
  token: null,
  loginPending: false,
};

export const authSlice = createSlice({
  name: 'auth',
  initialState: INIT_STATE,
  reducers: {
    setAccessToken: (state, { payload }) => {
      state.token = payload;
    },
  },
  extraReducers: builder => {
    loginBuilder(builder);
    fetchUserInfoBuilder(builder);
    builder.addCase(LOGOUT, () => ({ ...INIT_STATE, loggedIn: false }));
  },
});

export const { setAccessToken } = authSlice.actions;

export const selectLoginPending = state => state.auth.loginPending;
export const selectLoggedIn = state => state.auth.loggedIn;
export const selectAccessToken = state => state.auth.token;

export default authSlice.reducer;
