import React from 'react';
import { useSelector } from 'react-redux';
import { selectUser } from '../../../store/slices/user/slice';
import { Navigate, Outlet } from 'react-router-dom';

function PrivateOutlet({ roles }) {
  const user = useSelector(selectUser);

  if (!roles.includes(user.role)) {
    return <Navigate to="/forbidden" />;
  }

  return <Outlet />;
}

export default PrivateOutlet;
