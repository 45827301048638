import styled from 'styled-components';

export const Container = styled.div`
  display: grid;
  grid-template-columns:
    [full-start] minmax(6rem, 1fr) [center-start]
    repeat(8, [col-start] minmax(1rem, 20rem) [col-end])
    [center-end] minmax(6rem, 1fr) [full-end page-end];
  padding: 7.8rem 2.6rem 0 2.6rem;
  min-height: 100%;
  height: 1px;
`;
