import styled from 'styled-components';
import { Text16Regular } from '../../utils/texts/text.styles';
import { ReactComponent as LogoSvg } from '../../../assets/icons/logo.svg';

export const Sidebar = styled.nav`
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  width: 28rem;
  display: flex;
  flex-direction: column;
  z-index: 3;
  border-right: 1px solid #050609;
  /* background: linear-gradient(180deg, #111121 0%, #030304 100%); */
  background-color: #1b112d;
  padding: 3.2rem 2rem;
  overflow: hidden;
`;

export const LogoIcon = styled(LogoSvg)`
  width: 54%;
  height: 4rem;
  align-self: center;
  margin-top: 1rem;
`;

export const Shadow = styled.div`
  position: absolute;
  right: 0rem;
  bottom: 10rem;
  width: 4.2rem;
  height: 18rem;
  background-color: #dac2fc;
  filter: blur(87px);
`;

export const SectionName = styled(Text16Regular)`
  color: #fff;
  margin-top: ${props => (props.$first ? '6.4rem' : '1.6rem')};
`;

export const SectionItemContainer = styled.div`
  width: 100%;
  height: 4.2rem;
  margin-top: ${props => (props.$first ? '1.2rem' : '0.8rem')};
  border-radius: 1.4rem;
  overflow: hidden;

  a {
    width: 100%;
    height: 100%;
    padding: 0.2rem 1.2rem;
    display: flex;
    align-items: center;
    text-decoration: none;
  }

  a.active {
    /* background: linear-gradient(
      152deg,
      ${({ theme }) => theme.colors.linearLeft} 0%,
      ${({ theme }) => theme.colors.linearRight} 100%
    ); */
    background: linear-gradient(351deg, rgba(0, 0, 0, 0.2) 7.02%, rgba(0, 0, 0, 0.2) 92.98%), #843efe;

    svg g {
      color: #fff;
    }
  }
`;

export const LastSectionItemContainer = styled(SectionItemContainer)`
  padding: 0.2rem 1.2rem;
  display: flex;
  align-items: center;
  cursor: pointer;
  margin-top: auto;
`;

export const SectionItemIconContainer = styled.div`
  width: 2.4rem;
  margin-right: 1.2rem;
  display: flex;
  align-items: center;
`;

export const SectionItemText = styled(Text16Regular)`
  color: #fff;
  flex: 1;
`;
