import styled from 'styled-components';
import {
  Sidebar as PrivateSideBar,
  LogoIcon as PrivateLogoIcon,
} from '../../../../components/navigations/PrivateNavigation/PrivateNavigation.styles';
import { Text20Bold } from '../../../../components/utils/texts/text.styles';

export const Sidebar = styled(PrivateSideBar)`
  z-index: 4;
`;

export const Row = styled.div`
  display: flex;
  align-items: center;
  margin-top: 1rem;
`;

export const BackContainer = styled.div`
  width: 3.2rem;
  height: 3.2rem;
  border-radius: 50%;
  /* background-color: rgba(255, 255, 255, 0.1); */
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`;

export const LogoIcon = styled(PrivateLogoIcon)`
  margin-top: 0;
  margin-left: auto;
  margin-right: auto;
`;

export const Title = styled(Text20Bold)`
  color: #fff;
  margin-left: 1.6rem;
`;

export const StepsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4rem;
  margin-top: 6.4rem;
`;
