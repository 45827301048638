import { yupResolver } from '@hookform/resolvers/yup';
import React, { useEffect, useRef } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { createInfluencerStepBasicInfoFormSchema } from '../../../../../forms/schemas/influencer.schema';
import { CreateInfluencerForm, Footer, FooterRightButton } from '../../CreateInfluencerPage.styles';
import { ReactComponent as ForwardIcon } from '../../../../../assets/icons/forward.svg';
import { FormDatePicker, FormFileInput, FormInput, FormSelect } from '../../../../../forms/form.styles';
import {
  CategorySelect,
  Container,
  ContainerLeftPart,
  ContainerRightPart,
  UploadRow,
  VoiceInput,
} from './CreateInfluencerPageStepBasicInfo.styles';
import { GENDERS, INFLUENCER_CATEGORIES, UPLOAD_FILE_CONSTRAINTS } from '../../../../../utils/constants';
import { useDispatch, useSelector } from 'react-redux';
import { selectFetchInfluencerVariablesInfoData } from '../../../../../store/slices/influencer/slice';
import { fetchInfluencerVariables } from '../../../../../store/slices/influencer/asyncThunks';
import CropImageModal from '../../../../../components/utils/modals/CropImageModal/CropImageModal';
import { capitalizeFirstLetter } from '../../../../../utils/util';

function CreateInfluencerPageStepBasicInfo({ formData, onAfterSubmit, step }) {
  const influencerVariables = useSelector(selectFetchInfluencerVariablesInfoData);
  const cropImageRef = useRef();
  const dispatch = useDispatch();

  const {
    register,
    handleSubmit,
    control,
    setValue,
    formState: { errors },
    watch,
    getValues,
  } = useForm({
    defaultValues: {
      name: '',
      nickname: '',
      username: '',
      email: '',
      password: '',
      passwordConfirmation: '',
      dateOfBirth: null,
      profilePhoto: null,
      voice: null,
      location: '',
      countryFlag: '',
      category: null,
      gender: null,
    },
    delayError: 300,
    resolver: yupResolver(createInfluencerStepBasicInfoFormSchema),
  });

  const watchProfilePhoto = watch('profilePhoto');

  useEffect(() => {
    if (!influencerVariables) {
      dispatch(fetchInfluencerVariables());
    }
  }, [dispatch, influencerVariables]);

  useEffect(() => {
    if (formData.basicInfo) {
      setValue('name', formData.basicInfo.name);
      setValue('nickname', formData.basicInfo.nickname);
      setValue('username', formData.basicInfo.username);
      setValue('email', formData.basicInfo.email);
      setValue('password', formData.basicInfo.password);
      setValue('passwordConfirmation', formData.basicInfo.passwordConfirmation);
      setValue('dateOfBirth', formData.basicInfo.dateOfBirth);
      setValue('profilePhoto', formData.basicInfo.profilePhoto);
      setValue('voice', formData.basicInfo.voice);
      setValue('location', formData.basicInfo.location);
      setValue('countryFlag', formData.basicInfo.countryFlag);
      setValue('category', formData.basicInfo.category);
      setValue('gender', formData.basicInfo.gender);
    }
  }, [formData.basicInfo, setValue]);

  useEffect(() => {
    if (watchProfilePhoto) {
      if (typeof watchProfilePhoto !== 'string') {
        cropImageRef.current.show();
      }
    }
  }, [watchProfilePhoto]);

  const onSubmit = data => {
    onAfterSubmit(data, step);
  };

  return (
    <>
      <CreateInfluencerForm>
        <Container>
          <ContainerLeftPart>
            <FormInput label="Name" placeholder="Enter name" {...register('name')} error={errors.name?.message} />
            <FormInput
              label="Nickname"
              placeholder="Enter nickname"
              {...register('nickname')}
              error={errors.nickname?.message}
            />
            <Controller
              name="username"
              control={control}
              render={({ field }) => (
                <FormInput
                  label="Username"
                  placeholder="Enter username"
                  onChange={e => field.onChange(e.target.value.toLowerCase())}
                  value={field.value}
                  error={errors.username?.message}
                />
              )}
            />
            <FormInput label="Email" placeholder="Enter email" {...register('email')} error={errors.email?.message} />
            <FormInput
              label="Password"
              placeholder="Enter password"
              {...register('password')}
              error={errors.password?.message}
              type="password"
            />
            <FormInput
              label="Password confirmation"
              placeholder="Enter password confirmation"
              {...register('passwordConfirmation')}
              error={errors.passwordConfirmation?.message}
              type="password"
            />
            <Controller
              name="dateOfBirth"
              control={control}
              render={({ field }) => {
                return (
                  <FormDatePicker
                    label="Date of birth"
                    placeholder="Enter date of birth"
                    value={field.value}
                    onChange={field.onChange}
                    error={errors.dateOfBirth?.message}
                  />
                );
              }}
            />
          </ContainerLeftPart>
          <ContainerRightPart>
            <UploadRow>
              <Controller
                name="profilePhoto"
                control={control}
                render={({ field }) => {
                  return (
                    <FormFileInput
                      label="Profile photo"
                      value={field.value}
                      onChange={field.onChange}
                      type={UPLOAD_FILE_CONSTRAINTS.TYPE.IMAGE}
                      error={errors.profilePhoto?.message}
                    />
                  );
                }}
              />
              <Controller
                name="voice"
                control={control}
                render={({ field }) => {
                  return (
                    <VoiceInput
                      label="Voice"
                      value={field.value}
                      onChange={field.onChange}
                      type={UPLOAD_FILE_CONSTRAINTS.TYPE.VOICE}
                      error={errors.voice?.message}
                      increasedFileWidth
                    />
                  );
                }}
              />
            </UploadRow>
            <Controller
              name="category"
              control={control}
              render={({ field }) => (
                <CategorySelect
                  placeholder="Select category"
                  label="Category"
                  options={INFLUENCER_CATEGORIES.map(value => ({ value, label: capitalizeFirstLetter(value) }))}
                  selectedOption={field.value}
                  handleChange={value => field.onChange(value)}
                  error={errors.category?.message}
                />
              )}
            />
            <Controller
              name="gender"
              control={control}
              render={({ field }) => (
                <FormSelect
                  placeholder="Select gender"
                  label="Gender"
                  options={GENDERS.map(value => ({ value, label: capitalizeFirstLetter(value) }))}
                  selectedOption={field.value}
                  handleChange={value => field.onChange(value)}
                  error={errors.gender?.message}
                />
              )}
            />
            <FormInput
              label="Location"
              placeholder="Enter location"
              {...register('location')}
              error={errors.location?.message}
            />
            <FormInput
              label="Country flag"
              placeholder="Enter country flag (optional)"
              {...register('countryFlag')}
              error={errors.countryFlag?.message}
            />
          </ContainerRightPart>
        </Container>
      </CreateInfluencerForm>
      <Footer>
        <div />
        <FooterRightButton
          title="Next Step"
          icon={<ForwardIcon fill="#fff" />}
          onClick={() => handleSubmit(onSubmit)()}
        />
      </Footer>
      <CropImageModal
        ref={cropImageRef}
        onClose={() => {
          setValue('profilePhoto', null);
          cropImageRef.current.hide();
        }}
        onComplete={croppedImage => {
          setValue('profilePhoto', croppedImage);
          cropImageRef.current.hide();
        }}
        originalImage={getValues('profilePhoto')}
      />
    </>
  );
}

export default CreateInfluencerPageStepBasicInfo;
