import styled from 'styled-components';
import { Text12Regular, Text16Bold } from '../utils/texts/text.styles';
import { FORM_STEP_STATUS } from './FormStep';

export const Container = styled.div`
  width: 100%;
  height: 6.4rem;
  background-color: ${props => (props.$selected ? '#843efe60' : 'none')};
  padding: 1.2rem;
  border-radius: 1.2rem;
  display: flex;
  align-items: center;
  gap: 1.2rem;
  /* cursor: ${props => (props.$status === FORM_STEP_STATUS.UNCOMPLETED ? 'not-allowed' : 'pointer')}; */
  opacity: ${props => (props.$status === FORM_STEP_STATUS.UNCOMPLETED ? 0.6 : 1)};
  border: ${props => (props.$selected ? '1px solid #843efe80 ' : 'none')};
`;

export const StepNumberContainer = styled.div`
  width: 4rem;
  height: 4rem;
  border-radius: 50%;
  background-color: ${props => (props.$status !== FORM_STEP_STATUS.UNCOMPLETED ? '#843efe' : 'none')};
  display: flex;
  align-items: center;
  justify-content: center;
  border: ${props => (props.$status !== FORM_STEP_STATUS.UNCOMPLETED ? 'none' : '1px solid #ffffff80')};
`;

export const StepNumberText = styled(Text16Bold)`
  color: #fff;
`;

export const StepInfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: space-around;
`;

export const Title = styled(Text16Bold)`
  color: #fff;
`;

export const Subtitle = styled(Text12Regular)`
  color: #fff;
  opacity: 0.6;
`;
