import styled from 'styled-components';
import Input from '../components/utils/inputs/Input/Input';
import NumericInput from '../components/utils/inputs/NumericInput/NumericInput';
import Checkbox from '../components/utils/inputs/CheckBox/CheckBox';
import FileInput from '../components/utils/inputs/FileInput/FileInput';
import Select from '../components/utils/selects/Select/Select';
import DatePicker from '../components/utils/inputs/DatePicker/DatePicker';

export const FormInput = styled(Input)`
  margin-top: 0.6rem;
`;

export const FormNumericInput = styled(NumericInput)`
  margin-top: 0.6rem;
`;

export const FormCheckbox = styled(Checkbox)`
  margin-top: 0.6rem;
  margin-bottom: 2.1rem;
`;

export const FormFileInput = styled(FileInput)`
  margin-top: 0.6rem;
`;

export const FormSelect = styled(Select)`
  margin-top: 0.6rem;
`;

export const FormDatePicker = styled(DatePicker)`
  margin-top: 0.6rem;
`;
