import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Nav, NavItem, NavLink, NavMenu } from './PublicHeader.styles';

const NAVBAR_DATA = [{ to: '/', text: 'OnlyChats' }];

function PublicHeader() {
  const navigate = useNavigate();

  return (
    <Nav>
      <NavMenu>
        <div>
          {NAVBAR_DATA.map((el, index) => (
            <NavItem key={index}>
              <NavLink onClick={() => navigate(el.to)}>{el.text}</NavLink>
            </NavItem>
          ))}
        </div>
        {/* <SignInButton title="Login" onClick={() => navigate('/login')} /> */}
      </NavMenu>
    </Nav>
  );
}

export default PublicHeader;
