import React from 'react';
import ReactDOM from 'react-dom/client';
import { App } from './components';
import { BrowserRouter } from 'react-router-dom';
import { ThemeProvider } from 'styled-components';
import { theme } from './styles/constants';
import { Provider } from 'react-redux';
import { store } from './store';
import { injectStore } from './api/index';
import { ConfirmationModalProvider } from './components/utils/modals/ConfirmationModal/ConfirmationModal';

injectStore(store);

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  <React.StrictMode>
    <Provider store={store}>
      <BrowserRouter>
        <ThemeProvider theme={theme}>
          <ConfirmationModalProvider>
            <App />
          </ConfirmationModalProvider>
        </ThemeProvider>
      </BrowserRouter>
    </Provider>
  </React.StrictMode>,
);
