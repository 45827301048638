import { axiosApiFreeClient } from '..';
import { handleError, throwErrorIfWrongStatus } from './utils';

class AuthService {
  login = async body => {
    try {
      const response = await axiosApiFreeClient.post('/login', body, { withCredentials: true });
      throwErrorIfWrongStatus(response, 200);
      const { accessToken, user } = response.data.data;
      return { accessToken, user };
    } catch (err) {
      throw handleError(err, 'Error occurred while logging in');
    }
  };

  logout = async () => {
    try {
      const response = await axiosApiFreeClient.post('/logout', {}, { withCredentials: true });
      throwErrorIfWrongStatus(response, 200);
    } catch (err) {
      throw handleError(err, 'Error occurred while logging out');
    }
  };
}

const authService = new AuthService();
export default authService;
