import styled from 'styled-components';
import { InfoLabelText, InfoValueText, Text14Regular } from '../utils/texts/text.styles';
import { ReactComponent as DeleteIconSvg } from '../../assets/icons/delete.svg';

export const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 2rem;
  border: 1px solid #90909020;
  border-radius: 1.4rem;
  cursor: pointer;
  position: relative;
`;

export const Header = styled.div`
  display: flex;
  align-items: center;
`;

export const NameLabel = styled(Text14Regular)`
  margin-left: 1rem;
`;

export const PriceLabel = styled(Text14Regular)`
  margin-left: auto;
`;

export const InfoRowContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 1.2rem;
`;

export const InfoRowLabel = styled(InfoLabelText)`
  margin-left: ${props => (props.$inner ? '2.4rem' : '0')};
`;

export const InfoRowValue = styled(InfoValueText)``;

export const DeleteContainer = styled.div`
  width: 3.2rem;
  height: 3.2rem;
  background-color: #fff;
  opacity: 0.8;
  border-radius: 1.2rem;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  cursor: pointer;
  margin-left: 1.2rem;
`;

export const DeleteIcon = styled(DeleteIconSvg)`
  fill: '#9BACD8';
`;
