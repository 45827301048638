import styled from 'styled-components';

const StyledBasicText = styled.p`
  color: ${({ theme }) => theme.colors.mainText};
  line-height: normal;
`;

export const Text12Regular = styled(StyledBasicText)`
  font-size: 1.2rem;
  font-weight: 400;
`;

export const Text14Light = styled(StyledBasicText)`
  font-size: 1.4rem;
  font-weight: 300;
`;

export const Text14Regular = styled(StyledBasicText)`
  font-size: 1.4rem;
  font-weight: 400;
`;

export const Text14Bold = styled(StyledBasicText)`
  font-size: 1.4rem;
  font-weight: 500;
`;

export const Text16Light = styled(StyledBasicText)`
  font-size: 1.6rem;
  font-weight: 300;
`;

export const Text16Regular = styled(StyledBasicText)`
  font-size: 1.6rem;
  font-weight: 400;
`;

export const Text16Bold = styled(StyledBasicText)`
  font-size: 1.6rem;
  font-weight: 500;
`;

export const Text20Regular = styled(StyledBasicText)`
  font-size: 2rem;
  font-weight: 400;
`;

export const Text20Bold = styled(StyledBasicText)`
  font-size: 2rem;
  font-weight: 500;
`;

export const Text24Regular = styled(StyledBasicText)`
  font-size: 2.4rem;
  font-weight: 400;
`;

export const Text24Bold = styled(StyledBasicText)`
  font-size: 2.4rem;
  font-weight: 500;
`;

export const Text24ExtraBold = styled(StyledBasicText)`
  font-size: 2.4rem;
  font-weight: 600;
`;

export const Text26ExtraBold = styled(StyledBasicText)`
  font-size: 2.6rem;
  font-weight: 600;
`;

export const Text38ExtraBold = styled(StyledBasicText)`
  font-size: 3.8rem;
  font-weight: 600;
  letter-spacing: -0.38px;
`;

// ------------ Utils ------------

export const InputLabel = styled(Text16Regular)`
  margin-bottom: 0.6rem;
  margin-left: 0.2rem;
`;

export const Title = styled(Text20Bold)``;
export const TitleExtra = styled(Text24Bold)``;
export const Subtitle = styled(Text16Bold)``;
export const InfoLabelText = styled(Text14Regular)`
  color: #909090;
`;
export const InfoValueText = styled(Text14Regular)``;
