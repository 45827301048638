import React from 'react';
import {
  Container,
  DeleteContainer,
  DeleteIcon,
  Header,
  InfoRowContainer,
  InfoRowLabel,
  InfoRowValue,
  NameLabel,
  PriceLabel,
} from './SecretModeItem.styles';
import { Text14Regular } from '../utils/texts/text.styles';
import { roundNumber } from '../../utils/util';
import { Divider } from '../utils/utils.styles';

function SecretModeItem({ secretMode, onClick, onDelete }) {
  const InfoRow = ({ label, value, inner = false }) => (
    <InfoRowContainer>
      <InfoRowLabel $inner={inner}>
        {inner && '·'} {label}:
      </InfoRowLabel>
      <InfoRowValue>{value}</InfoRowValue>
    </InfoRowContainer>
  );

  return (
    <Container onClick={() => onClick()}>
      <Header>
        <Text14Regular>{secretMode.symbol}</Text14Regular>
        <NameLabel>{secretMode.name}</NameLabel>
        <PriceLabel>
          {secretMode.pricing.callPerMinute
            ? `${secretMode.pricing.callPerMinute} 💎 / Min`
            : secretMode.pricing.callPerSecond
            ? `${roundNumber(secretMode.pricing.callPerSecond * 60, 2)} 💎 / Min`
            : ''}
        </PriceLabel>
        {!!onDelete && (
          <DeleteContainer onClick={event => onDelete(event)}>
            <DeleteIcon />
          </DeleteContainer>
        )}
      </Header>
      <Divider />
      <InfoRow label="Is Default" value={secretMode.isDefault ? 'Yes' : 'No'} />
      <InfoRow label="Text message price" value={`${secretMode.pricing.textMessage} 💎`} />
      <InfoRow label="Voice message price" value={`${secretMode.pricing.voiceMessage} 💎`} />
      {secretMode.voiceId && <InfoRow label="Voice ID" value={secretMode.voiceId} />}
      <InfoRow label="Voice config" value={''} />
      <InfoRow label="Stability" value={secretMode.voiceConfig.stability} inner />
      <InfoRow label="Similarity Boost" value={secretMode.voiceConfig.similarityBoost} inner />
      <InfoRow label="Style" value={secretMode.voiceConfig.style} inner />
      <Divider />
      <InfoRow
        label="Welcome messages"
        value={secretMode.welcomeMessage.others.length + (secretMode.welcomeMessage.initial ? 1 : 0)}
      />
      <InfoRow label="Insufficient funds messages" value={secretMode.insufficientFundsMessages.length} />
      <InfoRow label="Inactive messages" value={secretMode.inactiveMessages.length} />
    </Container>
  );
}

export default SecretModeItem;
