import axios from 'axios';
import { BASE_URL, LOGOUT } from '../utils/constants.js';
import { setAccessToken } from '../store/slices/auth/slice.js';

let store;

export const injectStore = _store => {
  store = _store;
};

const axiosApiFreeClient = axios.create({
  baseURL: `${BASE_URL}/auth`,
});

const axiosApiClient = axios.create({
  baseURL: `${BASE_URL}/api`,
});

axiosApiFreeClient.interceptors.request.use(
  async config => {
    config.headers['X-MY-CSRF'] = 'fjdafhsiheiqrjowerj';
    return config;
  },
  error => {
    Promise.reject(error);
  },
);

axiosApiClient.interceptors.request.use(
  async config => {
    config.headers['X-MY-CSRF'] = 'jdafhsiheiqrjowerj';
    const token = store.getState().auth.token;
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  error => {
    Promise.reject(error);
  },
);

axiosApiClient.interceptors.response.use(
  response => {
    return response;
  },
  async error => {
    try {
      const originalRequest = error.config;
      if (error.response.status === 401 && !originalRequest._retry) {
        originalRequest._retry = true;
        const refreshResponse = await axiosApiFreeClient.post('/refresh-token', {}, { withCredentials: true });
        if (refreshResponse.status !== 200) {
          return Promise.reject(error);
        }
        const { accessToken } = refreshResponse.data.data;
        store.dispatch(setAccessToken(accessToken));
        originalRequest.headers['Authorization'] = `Bearer ${accessToken}`;
        return axios(originalRequest);
      }
      return Promise.reject(error);
    } catch (err) {
      store.dispatch({ type: LOGOUT });
      return Promise.reject(err);
    }
  },
);

export default axiosApiClient;
export { axiosApiFreeClient };
