import { createAsyncThunk } from '@reduxjs/toolkit';
import photoActivityService from '../../../api/services/photoActivity';

export const fetchPhotoActivities = createAsyncThunk(
  'photo-activity/fetchPhotoActivities',
  async ({ page, search }) => {
    return await photoActivityService.fetchPhotoActivities(page, search);
  },
);

export const createPhotoActivity = createAsyncThunk('photo-activity/createPhotoActivity', async requestData => {
  return await photoActivityService.createPhotoActivity(requestData);
});

export const editPhotoActivity = createAsyncThunk('photo-activity/editPhotoActivity', async requestData => {
  const { id, ...body } = requestData;
  return await photoActivityService.editPhotoActivity(id, body);
});

export const deletePhotoActivity = createAsyncThunk('photo-activity/deletePhotoActivity', async id => {
  return await photoActivityService.deletePhotoActivity(id);
});
