import styled from 'styled-components';
import { Text14Bold, Text24Bold } from '../../../components/utils/texts/text.styles';
import Input from '../../../components/utils/inputs/Input/Input';
import { MainButton } from '../../../components/utils/buttons/Button/Button';

export const FormHeader = styled(Text24Bold)`
  margin-top: 1.6rem;
  margin-bottom: 0.8rem;
  text-align: center;
`;

export const WelcomeText = styled(Text14Bold)`
  opacity: 0.45;
  margin-bottom: 0.6rem;
  text-align: center;
`;

export const IconWrapper = styled.div`
  margin-inline: auto;
`;

export const EmailInput = styled(Input)`
  margin-top: 2.4rem;
`;

export const PasswordInput = styled(Input)`
  margin-top: 1rem;
`;

export const LoginButton = styled(MainButton)`
  margin-top: 4rem;
`;
