import React, { useMemo } from 'react';
import { ReactComponent as AddPhotoIcon } from '../../../../assets/icons/add_photo.svg';
import { ReactComponent as VoiceIconSvg } from '../../../../assets/icons/voice.svg';
import { notifyError } from '../../../../utils/notify';
import {
  AllowedFileExtensionsText,
  UplaodFileText,
  UploadFileInputContainer,
  UploadFileInput,
  Image,
  Container,
  UploadFileHelperContainer,
} from './FileInput.styles';
import ErrorContainer from '../../ErrorContainer/ErrorContainer';
import { InputLabel } from '../../texts/text.styles';
import { UPLOAD_FILE_CONSTRAINTS } from '../../../../utils/constants';

const FileInput = ({
  label,
  value,
  onChange,
  className,
  error,
  hideErrorMessage,
  increasedFileHeight,
  increasedFileWidth,
  type = UPLOAD_FILE_CONSTRAINTS.TYPE.IMAGE,
}) => {
  const validateFile = file => {
    const allowedExtensions = UPLOAD_FILE_CONSTRAINTS[type].ALLOWED_EXTENSIONS;
    const maxFileSizeInBytes = UPLOAD_FILE_CONSTRAINTS[type].MAX_SIZE_IN_BYTES;
    if (file.size < maxFileSizeInBytes) {
      const fileType = file.type.split('/')[1];
      if (allowedExtensions.includes(fileType)) {
        return file;
      } else {
        notifyError('Unsupported type');
      }
    } else {
      notifyError('File size error');
    }
  };

  const acceptedExtensionsText = useMemo(() => UPLOAD_FILE_CONSTRAINTS[type].ALLOWED_EXTENSIONS_TEXT, [type]);

  return (
    <Container className={className}>
      {label && <InputLabel>{label}</InputLabel>}
      <UploadFileInputContainer $increasedFileHeight={increasedFileHeight} $increasedFileWidth={increasedFileWidth}>
        <UploadFileInput
          type="file"
          title=""
          value=""
          onChange={e => {
            const { files: newFiles } = e.target;
            if (newFiles.length) {
              const file = validateFile(newFiles[0]);
              if (file) {
                const reader = new FileReader();
                reader.onload = function (event) {
                  onChange({ file, content: event.target.result });
                };
                reader.readAsDataURL(file);
              }
            }
          }}
          accept={acceptedExtensionsText}
        />
        {value ? (
          type === UPLOAD_FILE_CONSTRAINTS.TYPE.IMAGE ? (
            <Image src={typeof value === 'string' ? value : value.content} alt="influencer-image" />
          ) : (
            <UploadFileHelperContainer>
              <VoiceIconSvg />
              <UplaodFileText>{value.file ? value.file.name : 'Voice Call - init message'}</UplaodFileText>
            </UploadFileHelperContainer>
          )
        ) : type === UPLOAD_FILE_CONSTRAINTS.TYPE.IMAGE ? (
          <UploadFileHelperContainer>
            <AddPhotoIcon />
            <UplaodFileText>Drag & Drop</UplaodFileText>
            <AllowedFileExtensionsText>Allowed file extensions .jpg, .png, .jpeg</AllowedFileExtensionsText>
          </UploadFileHelperContainer>
        ) : (
          <UploadFileHelperContainer>
            <VoiceIconSvg />
            <UplaodFileText>Drag & Drop</UplaodFileText>
            <AllowedFileExtensionsText>Allowed file extensions .mp3, .wav, .m4a</AllowedFileExtensionsText>
          </UploadFileHelperContainer>
        )}
      </UploadFileInputContainer>
      {!hideErrorMessage && <ErrorContainer errorText={error} />}
    </Container>
  );
};

export default FileInput;
