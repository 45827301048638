import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

function LandingPage() {
  const navigate = useNavigate();

  useEffect(() => {
    navigate('/login');
  });

  return (
    <>
      <p>Welcome</p>
    </>
  );
}

export default LandingPage;
