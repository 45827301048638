import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Table, UserByInfluencerTableItem } from '../../../components';
import { useDispatch, useSelector } from 'react-redux';
import { selectFetchUsersByInfluencerInfo, selectUser } from '../../../store/slices/user/slice';
import { fetchUsersByInfluencer } from '../../../store/slices/user/asyncThunks';
import { Header, SearchInput, Title } from '../UsersPage.styles';
import { useSearchParams } from 'react-router-dom';
import debounce from 'lodash.debounce';

function InfluencerUsersPage() {
  const [searchParams, setSearchParams] = useSearchParams();
  const page = +searchParams.get('page') || 1;
  const search = searchParams.get('search');
  const { data, total, pending } = useSelector(selectFetchUsersByInfluencerInfo);
  const user = useSelector(selectUser);
  const [searchValue, setSearchValue] = useState(() => search ?? '');
  const dispatch = useDispatch();

  const tableHeader = useMemo(
    () => [
      { label: 'First Name' },
      { label: 'Last Name' },
      { label: `Username` },
      { label: 'Total messages' },
      { label: 'Num of secret modes' },
      { label: `Actions`, type: 'action' },
    ],
    [],
  );

  const changeHandler = useCallback(
    text => {
      searchParams.set('search', text);
      searchParams.set('page', 1);
      setSearchParams(searchParams);
    },
    [setSearchParams, searchParams],
  );

  const debouncedChangeHandler = useMemo(() => debounce(changeHandler, 300), [changeHandler]);

  useEffect(() => {
    if (page > 0) {
      dispatch(fetchUsersByInfluencer({ influencerId: user.id, page, search: search ?? '' }));
    }
  }, [dispatch, page, search, user.id]);

  const setPage = page => {
    searchParams.set('page', page);
    setSearchParams(searchParams);
  };

  return (
    <>
      <Header>
        <Title>Users</Title>
        <SearchInput
          value={searchValue}
          onChange={event => {
            setSearchValue(event.target.value);
            debouncedChangeHandler(event.target.value);
          }}
          placeholder="Search"
          hideErrorMessage
        />
      </Header>
      <Table
        headerInformation={tableHeader}
        dataLoading={pending}
        totalCount={total}
        currentPage={page}
        perPage={10}
        setPage={setPage}
        emptyDataText={'No data'}
        fullHeight>
        {data.map(user => (
          <UserByInfluencerTableItem
            key={user.id}
            firstName={user.firstName}
            lastName={user.lastName}
            username={user.username}
            totalMessages={user.influencerData?.totalMessages}
            numOfSecretModes={user.influencerData?.numOfSecretModes}
            onDetailsClicked={() => {}}
          />
        ))}
      </Table>
    </>
  );
}

export default InfluencerUsersPage;
