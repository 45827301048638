import React, { Fragment } from 'react';
import { ActionTableBodyItem, TableBodyItem } from '../../Table.styles';
import { DeleteIcon, PreviewIcon } from './PhotoActivityItem.styles';

function PhotoActivityItem({ name, cost, descriptionPrompt, llmPrompt, onDetailsClicked, onDelete }) {
  return (
    <Fragment>
      <TableBodyItem>
        <p>{name || '-'}</p>
      </TableBodyItem>
      <TableBodyItem>
        <p>{cost || '-'}</p>
      </TableBodyItem>
      <TableBodyItem>
        <p>{descriptionPrompt ? `${descriptionPrompt.slice(0, 19)}...` : '-'}</p>
      </TableBodyItem>
      <TableBodyItem>
        <p>{llmPrompt ? `${llmPrompt.slice(0, 19)}...` : '-'}</p>
      </TableBodyItem>
      <ActionTableBodyItem>
        <PreviewIcon onClick={onDetailsClicked} />
        <DeleteIcon onClick={onDelete} />
      </ActionTableBodyItem>
    </Fragment>
  );
}

export default PhotoActivityItem;
