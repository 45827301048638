import styled from 'styled-components';
import { TransparentButton } from '../../../components/utils/buttons/Button/Button';
import { Text12Regular, Text16Light, Text20Bold } from '../../../components/utils/texts/text.styles';

export const Header = styled.div`
  height: 7.2rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0.6rem 0.6rem 1.4rem 0.6rem;
`;

export const DashboardContentContainer = styled.div`
  display: grid;
  height: calc(100% - 7.2rem - 2.6rem);
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: repeat(3, minmax(16rem, 1fr));
  column-gap: 2.4rem;
  row-gap: 2.4rem;
  margin: 0 0.6rem 0.6rem 0.6rem;
`;

export const HeaderLeftContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const HeaderRightContainer = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
`;

export const HeaderItem = styled.div`
  height: 80%;
  display: flex;
  align-items: center;
  padding-inline: 1rem;
  cursor: pointer;
`;

export const ProfilePhoto = styled.img`
  width: 4.8rem;
  height: 4.8rem;
  border-radius: 50%;
`;

export const ProfileInfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-left: 1.2rem;
`;

export const ProfileRoleText = styled(Text12Regular)`
  color: gray;
  letter-spacing: 0.02rem;
  text-transform: capitalize;
`;

const DashboardItemContainer = styled.div`
  background-color: #fff;
  border: 1px solid #f2f0f7;
  border-radius: 1.4rem;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  padding: 1.4rem;
  overflow: hidden;
`;

export const PromotionContainer = styled(DashboardItemContainer)`
  grid-row: 1 / 2;
  grid-column: 1 / -2;
  /* background-color: #AE87F6; */
  background: linear-gradient(135deg, #1b112d, #AE87F6);
  /* background: linear-gradient(351deg, rgba(0, 0, 0, 0.2) 7.02%, rgba(0, 0, 0, 0.2) 92.98%), #bb95fe; */
  color: #fff;
  display: flex;
  justify-content: space-between;
`;

export const PromotionLeftContainer = styled.div`
  height: 100%;
  width: 70%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
`;

export const PromotionTitle = styled(Text20Bold)`
  color: #fff;
`;

export const PromotionSubtitle = styled(Text16Light)`
  color: #fff;
  font-weight: 200;
`;

export const PromotionButton = styled(TransparentButton)`
  width: 20rem;
  height: 4.8rem;
  background-color: #fff;
  opacity: 0.94;
  font-weight: 500;
`;

export const PromotionRightContainer = styled.div`
  height: 100%;
  width: 30%;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

export const Logo = styled.img`
  width: 100%;
  aspect-ratio: 1 / 1;
`;

export const UsersInfoContainers = styled(DashboardItemContainer)`
  grid-row: 1 / 3;
  grid-column: 3 / -1;
`;

export const EarningsInfoContainer = styled(DashboardItemContainer)`
  grid-row: 2 / 3;
  grid-column: 1 / 2;
`;

export const MessagesInfoContainer = styled(DashboardItemContainer)`
  grid-row: 2 / 3;
  grid-column: 2 / 3;
`;

export const EarningsChartContainer = styled(DashboardItemContainer)`
  grid-row: 3 / -1;
  grid-column: 1 / 3;
`;

export const PhotosInfoContainer = styled(DashboardItemContainer)`
  grid-row: 3 / -1;
  grid-column: 3 / -1;
`;

export const DashboardItemHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const Row = styled.div`
  display: flex;
  align-items: center;
`;

export const DashboardItemHeaderSquare = styled.div`
  background-color: #9677cb;
  width: 2.4rem;
  height: 2.4rem;
  border-radius: 0.6rem;
`;

export const DashboardItemTitle = styled.p`
  font-size: 1.6rem;
  font-weight: 500;
  margin-left: 1.2rem;
`;
