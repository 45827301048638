import React, { useEffect } from 'react';
import { NavLink, Outlet, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { fetchInfluencerById, fetchInfluencerVariables } from '../../../store/slices/influencer/asyncThunks';
import {
  selectFetchInfluencerVariablesInfoData,
  selectSelectedInfluencerInfo,
  setSelectedInfluencer,
} from '../../../store/slices/influencer/slice';
import {
  BasicDetailsContainer,
  BasicStatsRow,
  BasicStatsRowSeparator,
  Container,
  GalleryIcon,
  InfluencerEmail,
  InfoLabel,
  InfoValue,
  NavLinkText,
  NavigatorContent,
  NavigatorItem,
  ProfilePhoto,
  TitleRow,
  SecretModeIcon,
  TabsContainer,
  TabsContentContainer,
  TabsHeaderContainer,
  Verified,
} from './InfluencerDetailsPage.styles';
import { Subtitle, Text16Regular, Title } from '../../../components/utils/texts/text.styles';
import MainLoader from '../../../components/utils/loaders/MainLoader/MainLoader';
import { Divider } from '../../../components/utils/utils.styles';
import { capitalizeFirstLetter } from '../../../utils/util';

function InfluencerDetailsPage() {
  const { id: influencerId } = useParams();
  const { influencer: selectedInfluencer, pending } = useSelector(selectSelectedInfluencerInfo);
  const influencerVariables = useSelector(selectFetchInfluencerVariablesInfoData);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchInfluencerById(influencerId));
    return () => {
      dispatch(setSelectedInfluencer(null));
    };
  }, [dispatch, influencerId]);

  useEffect(() => {
    if (!influencerVariables) {
      dispatch(fetchInfluencerVariables());
    }
  }, [dispatch, influencerVariables]);

  if (pending || !selectedInfluencer) {
    return (
      <Container>
        <MainLoader />
      </Container>
    );
  }

  return (
    <Container>
      <BasicDetailsContainer>
        <ProfilePhoto src={selectedInfluencer.profilePhoto} />
        <TitleRow>
          <Title>{selectedInfluencer.name}</Title>
          {selectedInfluencer.isVerified && (
            <Verified src={require('../../../assets/images/verified.png')} alt="verified" />
          )}
        </TitleRow>
        <InfluencerEmail>{selectedInfluencer.email}</InfluencerEmail>
        <BasicStatsRow>
          <GalleryIcon />
          <Text16Regular>
            {selectedInfluencer.photosInfo.freePhotosSize +
              selectedInfluencer.photosInfo.premiumPhotosSize +
              selectedInfluencer.photosInfo.inactivePhotosSize +
              selectedInfluencer.photosInfo.coverPhotosSize}
          </Text16Regular>
          <BasicStatsRowSeparator />
          <SecretModeIcon />
          <Text16Regular>{selectedInfluencer.secretModes.length}</Text16Regular>
        </BasicStatsRow>
        <Divider />
        <Subtitle>General Information</Subtitle>
        <InfoLabel>Username</InfoLabel>
        <InfoValue>{selectedInfluencer.username}</InfoValue>
        <InfoLabel>Location</InfoLabel>
        <InfoValue>{selectedInfluencer.location}</InfoValue>
        <InfoLabel>Status</InfoLabel>
        <InfoValue>{capitalizeFirstLetter(selectedInfluencer.status)}</InfoValue>
        <InfoLabel>About Me</InfoLabel>
        <InfoValue>{selectedInfluencer.aboutMe}</InfoValue>
      </BasicDetailsContainer>
      <TabsContainer>
        <TabsHeaderContainer>
          <NavigatorContent>
            <NavigatorItem>
              <NavLink to="secret-modes">
                <NavLinkText>{'Secret Modes'}</NavLinkText>
              </NavLink>
            </NavigatorItem>
            <NavigatorItem>
              <NavLink to="gallery">
                <NavLinkText>{'Gallery'}</NavLinkText>
              </NavLink>
            </NavigatorItem>
            <NavigatorItem>
              <NavLink to="edit">
                <NavLinkText>{'Edit'}</NavLinkText>
              </NavLink>
            </NavigatorItem>
          </NavigatorContent>
        </TabsHeaderContainer>
        <TabsContentContainer>
          <Outlet />
        </TabsContentContainer>
      </TabsContainer>
    </Container>
  );
}

export default InfluencerDetailsPage;
