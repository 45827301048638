import styled from 'styled-components';
import { ReactComponent as PreviewIconSvg } from '../../../../assets/icons/preview.svg';
import { ReactComponent as EditIconSvg } from '../../../../assets/icons/edit.svg';
import { ReactComponent as CopyIconSvg } from '../../../../assets/icons/copy.svg';
import { ReactComponent as ActiveIconSvg } from '../../../../assets/icons/active.svg';
import { ReactComponent as InactiveIconSvg } from '../../../../assets/icons/inactive.svg';

export const PreviewIcon = styled(PreviewIconSvg)`
  margin-right: 1rem;
  cursor: pointer;
  fill: '#9BACD8';
`;

export const CopyIcon = styled(CopyIconSvg)`
  margin-right: 1rem;
  cursor: pointer;
  fill: '#9BACD8';
`;

export const EditIcon = styled(EditIconSvg)`
  cursor: not-allowed;
  fill: '#9BACD8';
`;

export const ActiveIcon = styled(ActiveIconSvg)`
  margin-left: 1.1rem;
`;

export const InactiveIcon = styled(InactiveIconSvg)`
  margin-left: 1.1rem;
`;
