import { createGlobalStyle } from 'styled-components';
import { theme } from '../styles/constants';

const styled = { createGlobalStyle };

const GlobalStyles = styled.createGlobalStyle`
  *,
  *::before,
  *::after {
    margin: 0;
    padding: 0;
    box-sizing: inherit;
  }

  html {
    height: 100%;
    font-size: 62.5%; // 1rem = 10px, 10/16 = 60.25%
    box-sizing: border-box;

    @media screen and (max-width: 960px) {
      font-size: 56.25%; //1rem = 9px, 9/16 = 56.25%
    }

    @media screen and (max-width: 400px) {
      font-size: 50%; //1rem = 8px, 8/16 = 50%
    }
  }

  body {
    height: 100%;
    font-weight: 400;
    font-size: 1.7rem;
    font-family: Lexend, sans-serif;
    line-height: 1.6;
    margin: 0;
    background-color: #fafbfc;
    color: ${theme.colors.mainText};
  }

  input,
  select,
  textarea,
  button {
    font-family: inherit;
  }

  #root {
    height: 100%;
  }

  .ReactModal__Overlay {
    opacity: 0;
    transform: translateX(-0.1px);
    transition: all 300ms ease-in-out;
    // transform: translateX(400px);
    // transition: all 500ms ease-in-out;
  }

  .ReactModal__Overlay--after-open {
    opacity: 1;
    transform: translateX(0px);
  }

  .ReactModal__Overlay--before-close {
    opacity: 0;
    transform: translateX(-0.1px);
    // transform: translateX(400px);
  }

  .success-toast-message {
    background-color: #1b112d;
    color: #fff;
    border-radius: 1.2rem;
  }

  .success-toast-message-progress {
    background-color: #843efe;
  }

  .error-toast-message {
    background-color: #ef766b;
    color: #fff;
    border-radius: 1.2rem;
  }

  .error-toast-message-progress {
    background-color: #e74b3b;
  }

  .datePicker {
    font-size: 1.6rem;
    line-height: 2.4rem;
    font-weight: 300;
    color: #49435d;
    background: #fff;
    height: 4.8rem;
    width: 100%;
    border: 1px solid rgba(73, 67, 93, 0.2);
    border-radius: 12px;
    padding: 0.8rem 1.2rem;
    box-shadow: none;
    outline: none;
    cursor: ${props => (props.disabled ? 'not-allowed' : 'auto')};
  }

  .react-datepicker__input-container {
    height: 100%;
    background-color: orange;

    input {
      height: 100%;
      width: 100%;
      font-size: 1.6rem;
      line-height: 2.4rem;
      font-weight: 300;
      color: #49435d;
      border: none;
      outline: none;
    }
  }
`;

export default GlobalStyles;
