import React from 'react';
import { useSelector } from 'react-redux';
import { selectSelectedInfluencer } from '../../../../../store/slices/influencer/slice';
import { SecretModeList } from './SecretModesListTab.styles';
import { useLocation, useNavigate } from 'react-router-dom';
import { FlexMain } from '../../../../../components/utils/utils.styles';
import SecretModeItem from '../../../../../components/SecretModeItem/SecretModeItem';

function SecretModesListTab() {
  const selectedInfluencer = useSelector(selectSelectedInfluencer);
  const { pathname } = useLocation();
  const navigate = useNavigate();

  return (
    <FlexMain>
      <SecretModeList>
        {selectedInfluencer.secretModes.map(secretMode => (
          <SecretModeItem
            key={secretMode.id}
            secretMode={secretMode}
            onClick={() => {
              navigate(`${pathname.split('/list')[0]}/edit/${secretMode.id}`);
            }}
          />
        ))}
      </SecretModeList>
    </FlexMain>
  );
}

export default SecretModesListTab;
