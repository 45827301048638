import { createAsyncThunk } from '@reduxjs/toolkit';
import influencerService from '../../../api/services/influencer';
import { ALBUM_MEDIA_TYPE } from '../../../utils/constants';
import { base64ToFile } from '../../../utils/util';

export const fetchInfluencers = createAsyncThunk('influencer/fetchInfluencers', async ({ page, search }) => {
  return await influencerService.fetchInfluencers(page, search);
});

export const fetchInfluencerById = createAsyncThunk('influencer/fetchInfluencerById', async influencerId => {
  return await influencerService.fetchInfluencerById(influencerId);
});

export const createInfluencer = createAsyncThunk('influencer/createInfluencer', async requestData => {
  const {
    name,
    nickname,
    username,
    email,
    password,
    dateOfBirth,
    profilePhoto,
    voice,
    category,
    gender,
    location,
    countryFlag,
  } = requestData.basicInfo;
  const {
    businessConnection,
    highlightTags,
    personalityTags,
    appearanceTags,
    paymentRatio,
    score,
    aboutMe,
    isVerified,
    active,
    callOptions: { isVoiceCallEnabled, isVideoCallEnabled },
  } = requestData.additionalInfo;
  const { freeAlbumMedia, inactiveAlbumMedia, premiumAlbumMedia, coverAlbumMedia } = requestData.albumMedia;
  const { secretModes } = requestData.secretModes;

  // TODO: From this point album media is changed to photos

  const formData = new FormData();

  // Basic influencer info
  const profilePhotoFile = base64ToFile(profilePhoto, 'profile-photo');
  formData.append('profilePhoto', profilePhotoFile, profilePhotoFile.name);
  formData.append('voice', voice.file, voice.file.name);
  formData.append('name', name);
  formData.append('nickname', nickname);
  formData.append('username', username);
  formData.append('email', email);
  formData.append('password', password);
  formData.append('dateOfBirth', dateOfBirth);
  formData.append('category', category.value);
  formData.append('gender', gender.value);
  formData.append('location', location);
  if (countryFlag) {
    formData.append('countryFlag', countryFlag);
  }

  // Additional influencer info
  formData.append('businessConnectionId', businessConnection.value.id);
  personalityTags.forEach(personalityTag => {
    formData.append(`personalityTags[]`, personalityTag.value);
  });
  appearanceTags.forEach(appearanceTag => {
    formData.append(`appearanceTags[]`, appearanceTag.value);
  });
  highlightTags.forEach(highlightTag => {
    formData.append(`highlightTags[]`, highlightTag.value);
  });
  formData.append('paymentRatio[directReferral]', paymentRatio.directReferral);
  formData.append('paymentRatio[directForeigner]', paymentRatio.directForeigner);
  formData.append('paymentRatio[directOthersReferral]', paymentRatio.directOthersReferral);
  formData.append('paymentRatio[indirectReferral]', paymentRatio.indirectReferral);
  formData.append('score', score);
  formData.append('aboutMe', aboutMe);
  formData.append('isVerified', isVerified);
  formData.append('active', active);
  formData.append('callOptions[isVoiceCallEnabled]', isVoiceCallEnabled);
  formData.append('callOptions[isVideoCallEnabled]', isVideoCallEnabled);

  // Photos
  freeAlbumMedia.forEach(photo => {
    formData.append(`freePhotoFiles[]`, photo.albumMedia.file, photo.albumMedia.file.name);
  });
  inactiveAlbumMedia.forEach((photo, index) => {
    formData.append(`inactivePhotoFiles[]`, photo.albumMedia.file, photo.albumMedia.file.name);
    formData.append(`inactivePhotoData[${index}][description]`, photo.description);
  });
  premiumAlbumMedia.forEach((photo, index) => {
    formData.append(`premiumPhotoFiles[]`, photo.albumMedia.file, photo.albumMedia.file.name);
    formData.append(`premiumPhotoData[${index}][cost]`, photo.cost);
    formData.append(`premiumPhotoData[${index}][isForAISale]`, photo.isForAISale);
    formData.append(`premiumPhotoData[${index}][aiDescription]`, photo.aiDescription);
    formData.append(`premiumPhotoData[${index}][buttonDescription]`, photo.buttonDescription);
  });
  coverAlbumMedia.forEach((photo, ind) => {
    const coverFile = base64ToFile(photo.albumMedia, `cover-${ind}`);
    formData.append(`coverPhotoFiles[]`, coverFile, coverFile.name);
  });

  // Secret modes
  secretModes.forEach((secretMode, index) => {
    formData.append(`secretModeFiles[]`, secretMode.welcomePhoto.file, secretMode.welcomePhoto.file.name);
    formData.append(`secretModeData[${index}][name]`, secretMode.name);
    formData.append(`secretModeData[${index}][symbol]`, secretMode.symbol);
    formData.append(`secretModeData[${index}][suggestedReply]`, secretMode.suggestedReply);
    formData.append(`secretModeData[${index}][pricing][textMessage]`, secretMode.pricing.textMessage);
    formData.append(`secretModeData[${index}][pricing][voiceMessage]`, secretMode.pricing.voiceMessage);
    if (isVoiceCallEnabled) {
      formData.append(`secretModeVoiceFiles[]`, secretMode.voiceCall.voice.file, secretMode.voiceCall.voice.file.name);
      formData.append(`secretModeData[${index}][pricing][voiceCallPerMinute]`, secretMode.pricing.voiceCallPerMinute);
      formData.append(`secretModeData[${index}][voiceCall][vapi][assistantId]`, secretMode.voiceCall.vapi.assistantId);
      formData.append(
        `secretModeData[${index}][prompt][male][voiceCall][template]`,
        secretMode.prompt.male.voiceCall.template,
      );
      if (secretMode.prompt.male.voiceCall.dynamicSection) {
        formData.append(
          `secretModeData[${index}][prompt][male][voiceCall][dynamicSection]`,
          secretMode.prompt.male.voiceCall.dynamicSection,
        );
      }
      formData.append(
        `secretModeData[${index}][prompt][female][voiceCall][template]`,
        secretMode.prompt.male.voiceCall.template,
      );
      if (secretMode.prompt.male.voiceCall.dynamicSection) {
        formData.append(
          `secretModeData[${index}][prompt][female][voiceCall][dynamicSection]`,
          secretMode.prompt.male.voiceCall.dynamicSection,
        );
      }
      formData.append(
        `secretModeData[${index}][prompt][other][voiceCall][template]`,
        secretMode.prompt.male.voiceCall.template,
      );
      if (secretMode.prompt.male.voiceCall.dynamicSection) {
        formData.append(
          `secretModeData[${index}][prompt][other][voiceCall][dynamicSection]`,
          secretMode.prompt.male.voiceCall.dynamicSection,
        );
      }
    }
    if (isVideoCallEnabled) {
      formData.append(`secretModeData[${index}][pricing][videoCallPerMinute]`, secretMode.pricing.videoCallPerMinute);
      formData.append(`secretModeData[${index}][videoCall][replicaId]`, secretMode.videoCall.replicaId);
      formData.append(`secretModeData[${index}][videoCall][personaId]`, secretMode.videoCall.personaId);
      formData.append(
        `secretModeData[${index}][prompt][male][videoCall][template]`,
        secretMode.prompt.male.videoCall.template,
      );
      if (secretMode.prompt.male.videoCall.dynamicSection) {
        formData.append(
          `secretModeData[${index}][prompt][male][videoCall][dynamicSection]`,
          secretMode.prompt.male.videoCall.dynamicSection,
        );
      }
      formData.append(
        `secretModeData[${index}][prompt][female][videoCall][template]`,
        secretMode.prompt.male.videoCall.template,
      );
      if (secretMode.prompt.male.videoCall.dynamicSection) {
        formData.append(
          `secretModeData[${index}][prompt][female][videoCall][dynamicSection]`,
          secretMode.prompt.male.videoCall.dynamicSection,
        );
      }
      formData.append(
        `secretModeData[${index}][prompt][other][videoCall][template]`,
        secretMode.prompt.male.videoCall.template,
      );
      if (secretMode.prompt.male.videoCall.dynamicSection) {
        formData.append(
          `secretModeData[${index}][prompt][other][videoCall][dynamicSection]`,
          secretMode.prompt.male.videoCall.dynamicSection,
        );
      }
    }
    formData.append(`secretModeData[${index}][isDefault]`, secretMode.isDefault);

    // Main - male
    formData.append(
      `secretModeData[${index}][prompt][male][mainMessage][template]`,
      secretMode.prompt.male.mainMessage.template,
    );
    if (secretMode.prompt.male.mainMessage.dynamicSection) {
      formData.append(
        `secretModeData[${index}][prompt][male][mainMessage][dynamicSection]`,
        secretMode.prompt.male.mainMessage.dynamicSection,
      );
    }
    formData.append(
      `secretModeData[${index}][prompt][male][mainMessage][initialMessage]`,
      secretMode.prompt.male.mainMessage.initialMessage,
    );
    formData.append(
      `secretModeData[${index}][prompt][male][inactiveMessage][template]`,
      secretMode.prompt.male.inactiveMessage.template,
    );

    // Main - female
    formData.append(
      `secretModeData[${index}][prompt][female][mainMessage][template]`,
      secretMode.prompt.female.mainMessage.template,
    );
    if (secretMode.prompt.female.mainMessage.dynamicSection) {
      formData.append(
        `secretModeData[${index}][prompt][female][mainMessage][dynamicSection]`,
        secretMode.prompt.female.mainMessage.dynamicSection,
      );
    }
    formData.append(
      `secretModeData[${index}][prompt][female][mainMessage][initialMessage]`,
      secretMode.prompt.female.mainMessage.initialMessage,
    );
    formData.append(
      `secretModeData[${index}][prompt][female][inactiveMessage][template]`,
      secretMode.prompt.female.inactiveMessage.template,
    );

    // Main - other
    formData.append(
      `secretModeData[${index}][prompt][other][mainMessage][template]`,
      secretMode.prompt.other.mainMessage.template,
    );
    if (secretMode.prompt.other.mainMessage.dynamicSection) {
      formData.append(
        `secretModeData[${index}][prompt][other][mainMessage][dynamicSection]`,
        secretMode.prompt.other.mainMessage.dynamicSection,
      );
    }
    formData.append(
      `secretModeData[${index}][prompt][other][mainMessage][initialMessage]`,
      secretMode.prompt.other.mainMessage.initialMessage,
    );
    formData.append(
      `secretModeData[${index}][prompt][other][inactiveMessage][template]`,
      secretMode.prompt.other.inactiveMessage.template,
    );

    formData.append(`secretModeData[${index}][welcomeMessage][initial]`, secretMode.welcomeMessage.initial);
    secretMode.welcomeMessage.others.forEach((otherWelcomeMessage, otherWelcomeMessageIndex) => {
      formData.append(
        `secretModeData[${index}][welcomeMessage][others][${otherWelcomeMessageIndex}]`,
        otherWelcomeMessage,
      );
    });
    secretMode.insufficientFundsMessages.forEach((insufficientFundsMessage, insufficientFundsMessageIndex) => {
      formData.append(
        `secretModeData[${index}][insufficientFundsMessages][${insufficientFundsMessageIndex}]`,
        insufficientFundsMessage,
      );
    });
    secretMode.inactiveMessages.forEach((inactiveMessage, inactiveMessageIndex) => {
      formData.append(`secretModeData[${index}][inactiveMessages][${inactiveMessageIndex}]`, inactiveMessage);
    });
    formData.append(`secretModeData[${index}][voiceConfig][stability]`, secretMode.voiceConfig.stability);
    formData.append(`secretModeData[${index}][voiceConfig][similarityBoost]`, secretMode.voiceConfig.similarityBoost);
    formData.append(`secretModeData[${index}][voiceConfig][style]`, secretMode.voiceConfig.style);
    formData.append(`secretModeData[${index}][voiceConfig][useSpeakerBoost]`, secretMode.voiceConfig.useSpeakerBoost);
  });

  return await influencerService.createInfluencer(formData);
});

export const editInfluencer = createAsyncThunk('influencer/editInfluencer', async (requestData, { getState }) => {
  const selectedInfluencerId = getState().influencerInfo.selectedInfluencerInfo.influencer.id;
  const {
    profilePhoto,
    name,
    nickname,
    email,
    dateOfBirth,
    location,
    category,
    gender,
    countryFlag,
    personalityTags,
    appearanceTags,
    highlightTags,
    paymentRatio,
    score,
    aboutMe,
    isVerified,
    active,
  } = requestData;

  const formData = new FormData();
  if (profilePhoto && profilePhoto.startsWith('data:')) {
    const profilePhotoFile = base64ToFile(profilePhoto, 'profile-photo');
    formData.append('file', profilePhotoFile, profilePhotoFile.name);
  }

  formData.append('name', name);
  formData.append('nickname', nickname);
  formData.append('email', email);
  formData.append('dateOfBirth', dateOfBirth);
  formData.append('location', location);
  if (countryFlag) {
    formData.append('countryFlag', countryFlag);
  }
  formData.append('category', category.value);
  formData.append('gender', gender.value);
  personalityTags.forEach(personalityTag => {
    formData.append(`personalityTags[]`, personalityTag.value);
  });
  appearanceTags.forEach(appearanceTag => {
    formData.append(`appearanceTags[]`, appearanceTag.value);
  });
  highlightTags.forEach(highlightTag => {
    formData.append(`highlightTags[]`, highlightTag.value);
  });
  formData.append('paymentRatio[directReferral]', paymentRatio.directReferral);
  formData.append('paymentRatio[directForeigner]', paymentRatio.directForeigner);
  formData.append('paymentRatio[directOthersReferral]', paymentRatio.directOthersReferral);
  formData.append('paymentRatio[indirectReferral]', paymentRatio.indirectReferral);
  formData.append('score', score);
  formData.append('aboutMe', aboutMe);
  formData.append('isVerified', isVerified);
  formData.append('active', active);

  return await influencerService.editInfluencer(selectedInfluencerId, formData);
});

export const editSecretMode = createAsyncThunk('influencer/editSecretMode', async (requestData, { getState }) => {
  const selectedInfluencerId = getState().influencerInfo.selectedInfluencerInfo.influencer.id;
  const {
    secretModeId,
    name,
    symbol,
    welcomePhoto,
    suggestedReply,
    voiceCall,
    videoCall,
    pricing,
    isDefault,
    prompt,
    voiceId,
    voiceConfig,
    welcomeMessage,
    insufficientFundsMessages,
    inactiveMessages,
    callOptions: { isVoiceCallEnabled, isVideoCallEnabled },
  } = requestData;

  const formData = new FormData();
  if (welcomePhoto && welcomePhoto.file) {
    formData.append('welcomePhoto', welcomePhoto.file, welcomePhoto.file.name);
  }

  if (isVoiceCallEnabled) {
    if (voiceCall.initMessage && voiceCall.initMessage.file) {
      formData.append('voiceCallInitMessage', voiceCall.initMessage.file, voiceCall.initMessage.file.name);
    }
    formData.append('voiceCall[vapi][assistantId]', voiceCall.vapi.assistantId);
  }

  formData.append('callOptions[isVoiceCallEnabled]', isVoiceCallEnabled);
  formData.append('callOptions[isVideoCallEnabled]', isVideoCallEnabled);

  formData.append('name', name);
  formData.append('symbol', symbol);
  formData.append('suggestedReply', suggestedReply);
  formData.append('pricing[textMessage]', pricing.textMessage);
  formData.append('pricing[voiceMessage]', pricing.voiceMessage);
  formData.append('isDefault', isDefault);
  formData.append('voiceId', voiceId);
  formData.append('voiceConfig[stability]', voiceConfig.stability);
  formData.append('voiceConfig[similarityBoost]', voiceConfig.similarityBoost);
  formData.append('voiceConfig[style]', voiceConfig.style);
  formData.append('voiceConfig[useSpeakerBoost]', voiceConfig.useSpeakerBoost);
  formData.append('welcomeMessage[initial]', welcomeMessage.initial);
  for (const otherWelcomeMessage of welcomeMessage.others) {
    formData.append('welcomeMessage[others][]', otherWelcomeMessage);
  }
  for (const insufficientFundsMessage of insufficientFundsMessages) {
    formData.append('insufficientFundsMessages[]', insufficientFundsMessage);
  }
  for (const inactiveMessage of inactiveMessages) {
    formData.append('inactiveMessages[]', inactiveMessage);
  }

  if (isVoiceCallEnabled) {
    formData.append(`pricing[voiceCallPerMinute]`, pricing.voiceCallPerMinute);
    formData.append(`prompt[male][voiceCall][template]`, prompt.male.voiceCall.template);
    if (prompt.male.voiceCall.dynamicSection) {
      formData.append('prompt[male][voiceCall][dynamicSection]', prompt.male.voiceCall.dynamicSection);
    }
    formData.append(`prompt[female][voiceCall][template]`, prompt.female.voiceCall.template);
    if (prompt.female.voiceCall.dynamicSection) {
      formData.append('prompt[female][voiceCall][dynamicSection]', prompt.female.voiceCall.dynamicSection);
    }
    formData.append(`prompt[other][voiceCall][template]`, prompt.other.voiceCall.template);
    if (prompt.other.voiceCall.dynamicSection) {
      formData.append('prompt[other][voiceCall][dynamicSection]', prompt.other.voiceCall.dynamicSection);
    }
  }

  if (isVideoCallEnabled) {
    formData.append(`pricing[videoCallPerMinute]`, pricing.videoCallPerMinute);
    formData.append(`videoCall[replicaId]`, videoCall.replicaId);
    formData.append(`videoCall[personaId]`, videoCall.personaId);
    formData.append(`prompt[male][videoCall][template]`, prompt.male.videoCall.template);
    if (prompt.male.videoCall.dynamicSection) {
      formData.append('prompt[male][videoCall][dynamicSection]', prompt.male.videoCall.dynamicSection);
    }
    formData.append(`prompt[female][videoCall][template]`, prompt.female.videoCall.template);
    if (prompt.female.videoCall.dynamicSection) {
      formData.append('prompt[female][videoCall][dynamicSection]', prompt.female.videoCall.dynamicSection);
    }
    formData.append(`prompt[other][videoCall][template]`, prompt.other.videoCall.template);
    if (prompt.other.videoCall.dynamicSection) {
      formData.append('prompt[other][videoCall][dynamicSection]', prompt.other.videoCall.dynamicSection);
    }
  }

  // Main & Inactive Prompt - male
  formData.append('prompt[male][mainMessage][template]', prompt.male.mainMessage.template);
  if (prompt.male.mainMessage.dynamicSection) {
    formData.append('prompt[male][mainMessage][dynamicSection]', prompt.male.mainMessage.dynamicSection);
  }
  formData.append('prompt[male][mainMessage][initialMessage]', prompt.male.mainMessage.initialMessage);
  formData.append('prompt[male][inactiveMessage][template]', prompt.male.inactiveMessage.template);

  // Main & Inactive Prompt - female
  formData.append('prompt[female][mainMessage][template]', prompt.female.mainMessage.template);
  if (prompt.female.mainMessage.dynamicSection) {
    formData.append('prompt[female][mainMessage][dynamicSection]', prompt.female.mainMessage.dynamicSection);
  }
  formData.append('prompt[female][mainMessage][initialMessage]', prompt.female.mainMessage.initialMessage);
  formData.append('prompt[female][inactiveMessage][template]', prompt.female.inactiveMessage.template);

  // Main & Inactive Prompt - other
  formData.append('prompt[other][mainMessage][template]', prompt.other.mainMessage.template);
  if (prompt.other.mainMessage.dynamicSection) {
    formData.append('prompt[other][mainMessage][dynamicSection]', prompt.other.mainMessage.dynamicSection);
  }
  formData.append('prompt[other][mainMessage][initialMessage]', prompt.other.mainMessage.initialMessage);
  formData.append('prompt[other][inactiveMessage][template]', prompt.other.inactiveMessage.template);

  return await influencerService.editSecretMode(selectedInfluencerId, secretModeId, formData);
});

export const fetchInfluencerAlbumMedia = createAsyncThunk(
  'influencer/fetchInfluencerAlbumMedia',
  async (requestData, { getState }) => {
    const { influencerId, page, type } = requestData;
    const maxReached = getState().influencerInfo.fetchInfluencerAlbumMediaInfo.maxReached;
    if (maxReached && page !== 1) {
      throw new Error('There is no more items');
    }
    return await influencerService.fetchInfluencerAlbumMedia(influencerId, page, type);
  },
);

export const uploadFreeInfluencerAlbumMedia = createAsyncThunk(
  'influencer/uploadFreeInfluencerAlbumMedia',
  async (requestData, { getState }) => {
    const selectedInfluencerId = getState().influencerInfo.selectedInfluencerInfo.influencer.id;
    const { albumMedia } = requestData;

    const formData = new FormData();
    formData.append('file', albumMedia.file);

    return await influencerService.uploadInfluencerAlbumMedia(selectedInfluencerId, ALBUM_MEDIA_TYPE.FREE, formData);
  },
);

export const editFreeInfluencerAlbumMedia = createAsyncThunk(
  'influencer/editFreeInfluencerAlbumMedia',
  async (requestData, { getState }) => {
    const selectedInfluencerId = getState().influencerInfo.selectedInfluencerInfo.influencer.id;
    const { albumMediaId, albumMedia } = requestData;

    const formData = new FormData();
    if (albumMedia.file) {
      formData.append('file', albumMedia.file);
    }

    return await influencerService.editInfluencerAlbumMedia(
      selectedInfluencerId,
      albumMediaId,
      ALBUM_MEDIA_TYPE.FREE,
      formData,
    );
  },
);

export const uploadInactiveInfluencerAlbumMedia = createAsyncThunk(
  'influencer/uploadInactiveInfluencerAlbumMedia',
  async (requestData, { getState }) => {
    const selectedInfluencerId = getState().influencerInfo.selectedInfluencerInfo.influencer.id;
    const { albumMedia, description } = requestData;

    const formData = new FormData();
    formData.append('file', albumMedia.file);
    formData.append('description', description);

    return await influencerService.uploadInfluencerAlbumMedia(
      selectedInfluencerId,
      ALBUM_MEDIA_TYPE.INACTIVE,
      formData,
    );
  },
);

export const editInactiveInfluencerAlbumMedia = createAsyncThunk(
  'influencer/editInactiveInfluencerAlbumMedia',
  async (requestData, { getState }) => {
    const selectedInfluencerId = getState().influencerInfo.selectedInfluencerInfo.influencer.id;
    const { albumMediaId, albumMedia, description } = requestData;

    const formData = new FormData();
    if (albumMedia.file) {
      formData.append('file', albumMedia.file);
    }
    formData.append('description', description);

    return await influencerService.editInfluencerAlbumMedia(
      selectedInfluencerId,
      albumMediaId,
      ALBUM_MEDIA_TYPE.INACTIVE,
      formData,
    );
  },
);

export const uploadPremiumInfluencerAlbumMedia = createAsyncThunk(
  'influencer/uploadPremiumInfluencerAlbumMedia',
  async (requestData, { getState }) => {
    const selectedInfluencerId = getState().influencerInfo.selectedInfluencerInfo.influencer.id;
    const { albumMedia, cost, isForAISale, aiDescription, buttonDescription } = requestData;

    const formData = new FormData();
    formData.append('file', albumMedia.file);
    formData.append('cost', cost);
    formData.append('aiDescription', aiDescription);
    formData.append('isForAISale', isForAISale);
    formData.append('buttonDescription', buttonDescription);

    return await influencerService.uploadInfluencerAlbumMedia(selectedInfluencerId, ALBUM_MEDIA_TYPE.PREMIUM, formData);
  },
);

export const editPremiumInfluencerAlbumMedia = createAsyncThunk(
  'influencer/editPremiumInfluencerAlbumMedia',
  async (requestData, { getState }) => {
    const selectedInfluencerId = getState().influencerInfo.selectedInfluencerInfo.influencer.id;
    const { albumMediaId, albumMedia, cost, isForAISale, aiDescription, buttonDescription } = requestData;

    const formData = new FormData();
    if (albumMedia.file) {
      formData.append('file', albumMedia.file);
    }
    formData.append('cost', cost);
    formData.append('aiDescription', aiDescription);
    formData.append('isForAISale', isForAISale);
    formData.append('buttonDescription', buttonDescription);

    return await influencerService.editInfluencerAlbumMedia(
      selectedInfluencerId,
      albumMediaId,
      ALBUM_MEDIA_TYPE.PREMIUM,
      formData,
    );
  },
);

export const uploadCoverInfluencerAlbumMedia = createAsyncThunk(
  'influencer/uploadCoverInfluencerAlbumMedia',
  async (requestData, { getState }) => {
    const selectedInfluencerId = getState().influencerInfo.selectedInfluencerInfo.influencer.id;
    const { albumMedia } = requestData;

    const formData = new FormData();
    const albumMediaFile = base64ToFile(albumMedia, 'cover');
    formData.append('file', albumMediaFile);

    return await influencerService.uploadInfluencerCoverAlbumMedia(selectedInfluencerId, formData);
  },
);

export const editCoverInfluencerAlbumMedia = createAsyncThunk(
  'influencer/editCoverInfluencerAlbumMedia',
  async (requestData, { getState }) => {
    const selectedInfluencerId = getState().influencerInfo.selectedInfluencerInfo.influencer.id;
    const { albumMedia, url } = requestData;

    const formData = new FormData();
    if (albumMedia && albumMedia.startsWith('data:')) {
      const albumMediaFile = base64ToFile(albumMedia, 'cover');
      formData.append('file', albumMediaFile);
    }
    formData.append('url', url);

    const newUrl = await influencerService.editInfluencerCoverAlbumMedia(selectedInfluencerId, formData);
    return {
      oldUrl: url,
      newUrl,
    };
  },
);

export const deleteCoverInfluencerAlbumMedia = createAsyncThunk(
  'influencer/deleteCoverInfluencerAlbumMedia',
  async (requestData, { getState }) => {
    const selectedInfluencerId = getState().influencerInfo.selectedInfluencerInfo.influencer.id;
    return await influencerService.deleteInfluencerCoverAlbumMedia(selectedInfluencerId, requestData);
  },
);

export const promoteCoverInfluencerAlbumMedia = createAsyncThunk(
  'influencer/promoteCoverInfluencerAlbumMedia',
  async (requestData, { getState }) => {
    const selectedInfluencerId = getState().influencerInfo.selectedInfluencerInfo.influencer.id;
    return await influencerService.promoteInfluencerCoverAlbumMedia(selectedInfluencerId, requestData);
  },
);

export const fetchInfluencerVariables = createAsyncThunk('influencer/fetchInfluencerVariables', async () => {
  return await influencerService.fetchInfluencerVariables();
});
