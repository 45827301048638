import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import {
  selectDeletePhotoActivityPending,
  selectFetchPhotoActivitiesInfo,
} from '../../store/slices/photoActivity/slice';
import { deletePhotoActivity, fetchPhotoActivities } from '../../store/slices/photoActivity/asyncThunks';
import debounce from 'lodash.debounce';
import { AddPhotoActivityButton, Header, Row, SearchInput, Title } from './PhotoActivitiesPage.styles';
import { Table } from '../../components';
import PhotoActivityItem from '../../components/Table/items/PhotoActivityItem/PhotoActivityItem';
import CreatePhotoActivityFormModal from '../../components/modals/CreatePhotoActivityFormModal/CreatePhotoActivityFormModal';
import { useConfirmationModal } from '../../components/utils/modals/ConfirmationModal/ConfirmationModal';
import { notifyError, notifyInfo } from '../../utils/notify';

function PhotoActivitiesPage() {
  const [searchParams, setSearchParams] = useSearchParams();
  const page = +searchParams.get('page') || 1;
  const search = searchParams.get('search');
  const { data, total, pending } = useSelector(selectFetchPhotoActivitiesInfo);
  const deletePhotoActivityPending = useSelector(selectDeletePhotoActivityPending);
  const [searchValue, setSearchValue] = useState(() => search ?? '');
  const [selectedPhotoActivity, setSelectedPhotoActivity] = useState(null);
  const [openModal, closeModal, setConfirmationModalLoader] = useConfirmationModal();
  const createPhotoActivityRef = useRef();
  const dispatch = useDispatch();

  const tableHeader = useMemo(
    () => [
      { label: 'Name' },
      { label: 'Cost' },
      { label: 'Description Prompt' },
      { label: 'LLM Prompt' },
      { label: 'Actions', type: 'action' },
    ],
    [],
  );

  const changeHandler = useCallback(
    text => {
      searchParams.set('search', text);
      searchParams.set('page', 1);
      setSearchParams(searchParams);
    },
    [setSearchParams, searchParams],
  );

  const debouncedChangeHandler = useMemo(() => debounce(changeHandler, 300), [changeHandler]);

  useEffect(() => {
    if (page > 0) {
      dispatch(fetchPhotoActivities({ page, search: search ?? '' }));
    }
  }, [dispatch, page, search]);

  const setPage = page => {
    searchParams.set('page', page);
    setSearchParams(searchParams);
  };

  useEffect(() => {
    setConfirmationModalLoader(deletePhotoActivityPending);
  }, [deletePhotoActivityPending, setConfirmationModalLoader]);

  const onCreatePhotoActivity = () => {
    createPhotoActivityRef.current.show();
  };

  const onEditPhotoActivity = photoActivity => {
    setSelectedPhotoActivity(photoActivity);
    createPhotoActivityRef.current.show();
  };

  return (
    <>
      <Header>
        <Title>Photo Activities</Title>
        <Row>
          <SearchInput
            value={searchValue}
            onChange={event => {
              setSearchValue(event.target.value);
              debouncedChangeHandler(event.target.value);
            }}
            placeholder="Search"
            hideErrorMessage
          />
          <AddPhotoActivityButton title="Add Photo Activity" onClick={() => onCreatePhotoActivity()} />
        </Row>
      </Header>
      <Table
        headerInformation={tableHeader}
        dataLoading={pending}
        totalCount={total}
        currentPage={page}
        perPage={10}
        setPage={setPage}
        emptyDataText={'No data'}
        fullHeight>
        {data.map(photoActivity => (
          <PhotoActivityItem
            key={photoActivity.id}
            name={photoActivity.name}
            cost={photoActivity.cost}
            descriptionPrompt={photoActivity.descriptionPrompt}
            llmPrompt={photoActivity.llmPrompt}
            onDetailsClicked={() => onEditPhotoActivity(photoActivity)}
            onDelete={event => {
              openModal(
                'Delete photo activity',
                `Are you sure you want to delete "${photoActivity.name}" photo activity?`,
                'Delete',
                () => {
                  dispatch(deletePhotoActivity(photoActivity.id))
                    .unwrap()
                    .then(_ => {
                      notifyInfo('Photo activity deleted successfully!');
                      closeModal();
                      if (page > 0) {
                        dispatch(fetchPhotoActivities({ page, search: search ?? '' }));
                      }
                    })
                    .catch(err => {
                      notifyError(err.message);
                    });
                },
                'Cancel',
                () => {
                  closeModal();
                },
              );
              event.stopPropagation();
            }}
          />
        ))}
      </Table>
      <CreatePhotoActivityFormModal
        modalRef={createPhotoActivityRef}
        onSuccess={() => {
          if (page > 0) {
            dispatch(fetchPhotoActivities({ page, search: search ?? '' }));
          }
        }}
        selectedPhotoActivity={selectedPhotoActivity}
        onAfterClose={() => setSelectedPhotoActivity(null)}
      />
    </>
  );
}

export default PhotoActivitiesPage;
