import React from 'react';
import LogoIcon from '../../../assets/images/logo.png';
import { Container, Logo, Title } from './ForbiddenPage.styles';

const ForbiddenPage = () => {
  return (
    <Container>
      <Logo src={LogoIcon} alt="loading-spinner" />
      <Title>Forbidden page</Title>
    </Container>
  );
};

export default ForbiddenPage;
