import styled from 'styled-components';

export const StyledBasicButton = styled.button`
  position: relative;
  width: 100%;
  height: 5.4rem;
  padding: ${props => (props.$isLoading ? '1.4rem 3.4rem' : '1.6rem 3.4rem')};
  border-radius: 1.2rem;
  color: #161320;
  font-size: 1.6rem;
  font-weight: 400;
  line-height: 2rem;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  :disabled {
    cursor: not-allowed;
    opacity: 0.7;
  }
`;

export const StyledMainButton = styled(StyledBasicButton)`
  color: #fff;
  /* background: linear-gradient(
    92deg,
    ${({ theme }) => theme.colors.linearLeft} 4.62%,
    ${({ theme }) => theme.colors.linearRight} 94.98%
  ); */
  border-radius: 1.2rem;
  border: 1px solid #843efe;
  background: linear-gradient(
      269deg,
      color(display-p3 0.1373 0.0745 0.2745) -32.78%,
      color(display-p3 0 0 0 / 0) 82.28%
    ),
    color(display-p3 0.4824 0.2588 0.9608);
  opacity: ${props => (props.disabled ? 0.5 : 1)};
  cursor: ${props => (props.disabled ? 'not-allowed' : 'cursor')};
`;

export const StyledTransparentButton = styled(StyledBasicButton)`
  background-color: transparent;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
`;

export const ButtonIconWrapper = styled.div`
  position: absolute;
  top: 50%;
  right: 10%;
  transform: translate(0%, -44%);
`;

export const ButtonLoader = styled.img`
  height: 100%;
  aspect-ratio: 1 / 1;
`;
