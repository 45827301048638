import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { selectSelectedInfluencer } from '../../../../../store/slices/influencer/slice';
import {
  GalleryIcon,
  GalleryItemContainer,
  GalleryItemInfoContainer,
  GalleryItemsList,
} from './InfluencerGalleriesListTab.styles';
import { Title, TitleExtra } from '../../../../../components/utils/texts/text.styles';
import { useLocation, useNavigate } from 'react-router-dom';
import { FlexMain } from '../../../../../components/utils/utils.styles';

function InfluencerGalleriesListTab() {
  const selectedInfluencer = useSelector(selectSelectedInfluencer);
  const { pathname } = useLocation();
  const navigate = useNavigate();

  const data = useMemo(
    () => [
      {
        name: 'Free Gallery',
        pathSufix: 'free',
        albumMediaProp: 'freePhotosSize',
      },
      {
        name: 'Premium Gallery',
        pathSufix: 'premium',
        albumMediaProp: 'premiumPhotosSize',
      },
      {
        name: 'Inactive Gallery',
        pathSufix: 'inactive',
        albumMediaProp: 'inactivePhotosSize',
      },
      {
        name: 'Cover Gallery',
        pathSufix: 'cover',
        albumMediaProp: 'coverPhotosSize',
      },
    ],
    [],
  );

  return (
    <FlexMain>
      <GalleryItemsList>
        {data.map((galleryItem, index) => (
          <GalleryItemContainer
            key={index}
            onClick={() => {
              navigate(`${pathname.split('/list')[0]}/${galleryItem.pathSufix}`);
            }}>
            <TitleExtra>{galleryItem.name}</TitleExtra>
            <GalleryItemInfoContainer>
              <GalleryIcon />
              <Title>{selectedInfluencer.photosInfo[galleryItem.albumMediaProp]}</Title>
            </GalleryItemInfoContainer>
          </GalleryItemContainer>
        ))}
      </GalleryItemsList>
    </FlexMain>
  );
}

export default InfluencerGalleriesListTab;
