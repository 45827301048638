import styled from 'styled-components';
import { InfoLabelText, InfoValueText } from '../../../components/utils/texts/text.styles';
import { ReactComponent as GallerySvg } from '../../../assets/icons/gallery.svg';
import { ReactComponent as SecretModeSvg } from '../../../assets/icons/secret-mode.svg';

export const Container = styled.div`
  position: relative;
  display: flex;
  height: 100%;
`;

export const BasicDetailsContainer = styled.div`
  width: 30%;
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  background-color: #fff;
  border: 1px solid #f2f0f7;
  border-radius: 1.4rem;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  padding: 4rem;
  min-width: 32rem;
`;

export const TabsContainer = styled.div`
  position: relative;
  flex: 1;
  height: 100%;
  display: flex;
  flex-direction: column;
  background-color: #fff;
  border: 1px solid #f2f0f7;
  border-radius: 1.4rem;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  margin-left: 2rem;
`;

export const TabsHeaderContainer = styled.nav`
  height: 6rem;
  background-color: #fbfafc;
  border-bottom: 1px solid #90909020;
  border-radius: 1.4rem 1.4rem 0 0;
`;

export const TabsContentContainer = styled.div`
  position: relative;
  height: calc(100% - 6rem);
  display: flex;
  flex-direction: column;
`;

export const ProfilePhoto = styled.img`
  width: 14rem;
  height: 14rem;
  border-radius: 50%;
  align-self: center;
`;

export const TitleRow = styled.div`
  margin-top: 1.2rem;
  align-self: center;
  display: flex;
  align-items: center;
`;

export const Verified = styled.img`
  width: 1.8rem;
  height: 1.8rem;
  margin-left: 0.8rem;
`;

export const InfluencerEmail = styled(InfoLabelText)`
  margin-top: 0.4rem;
  align-self: center;
`;

export const BasicStatsRow = styled.div`
  margin-top: 2rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`;

export const BasicStatsRowSeparator = styled.div`
  width: 4rem;
  margin: 2rem 0;
  height: 1px;
  background: linear-gradient(270deg, #fff 0%, rgba(254, 145, 50, 0) 0.01%, #a0a0a0 50.52%, rgba(210, 52, 2, 0) 100%);
  opacity: 0.5;
  transform: rotate(90deg);
`;

export const GalleryIcon = styled(GallerySvg)`
  margin-right: 1rem;
`;

export const SecretModeIcon = styled(SecretModeSvg)`
  margin-right: 1rem;
`;

export const InfoLabel = styled(InfoLabelText)`
  margin-top: 2rem;
`;

export const InfoValue = styled(InfoValueText)`
  margin-top: 0.2rem;
`;

export const NavigatorContent = styled.ul`
  display: flex;
  flex: 1;
  list-style-type: none;
  gap: 3.2rem;
  height: 100%;
  padding: 0px 2rem;
`;

export const NavigatorItem = styled.li`
  height: 100%;
  font-size: 1.5rem;
  line-height: 1.6rem;
  font-weight: 400;

  a {
    display: flex;
    flex-direction: column;
    height: 100%;
    color: #909090;
    text-decoration: none;
  }

  a.active {
    color: #161320;
  }

  a:after {
    content: '';
    height: 3px;
    width: 100%;
    margin-top: auto;
  }

  a.active:after {
    background-color: #161320;
  }
`;

export const NavLinkText = styled.span`
  padding-top: 2.2rem;
`;
