import React, { useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import FormModal from '../../utils/modals/FormModal/FormModal';
import { useDispatch, useSelector } from 'react-redux';
import { selectUploadAlbumMediaPending } from '../../../store/slices/influencer/slice';
import { notifyError, notifyInfo } from '../../../utils/notify';
import FileInput from '../../utils/inputs/FileInput/FileInput';
import { UPLOAD_FILE_CONSTRAINTS } from '../../../utils/constants';
import {
  editPremiumInfluencerAlbumMedia,
  uploadPremiumInfluencerAlbumMedia,
} from '../../../store/slices/influencer/asyncThunks';
import { Text16Regular } from '../../utils/texts/text.styles';
import { uploadPremiumAlbumMediaFormSchema } from '../../../forms/schemas/albumMedia.schema';
import { FormCheckbox, FormInput, FormNumericInput } from '../../../forms/form.styles';

const UploadPremiumAlbumMediaFormModal = ({ modalRef, selectedAlbumMedia, onAfterClose, customOnSubmit }) => {
  const uploadAlbumMediaPending = useSelector(selectUploadAlbumMediaPending);
  const dispatch = useDispatch();

  const {
    register,
    handleSubmit,
    setValue,
    control,
    reset,
    formState: { errors },
  } = useForm({
    defaultValues: {
      albumMedia: null,
      cost: '',
      isForAISale: false,
      aiDescription: '',
      buttonDescription: '',
    },
    delayError: 300,
    resolver: yupResolver(uploadPremiumAlbumMediaFormSchema),
  });

  useEffect(() => {
    if (selectedAlbumMedia) {
      const { aiDescription, buttonDescription, cost, isForAISale, url, albumMedia } = selectedAlbumMedia;
      setValue('albumMedia', url ? url : albumMedia);
      setValue('cost', cost);
      setValue('isForAISale', isForAISale);
      setValue('aiDescription', aiDescription);
      setValue('buttonDescription', buttonDescription);
    } else {
      reset();
    }
  }, [selectedAlbumMedia, setValue, reset]);

  const onSubmit = data => {
    if (!!customOnSubmit) {
      customOnSubmit(data);
      modalRef.current.hide();
      return;
    }

    if (selectedAlbumMedia) {
      dispatch(editPremiumInfluencerAlbumMedia({ ...data, albumMediaId: selectedAlbumMedia.id }))
        .unwrap()
        .then(_ => {
          modalRef.current.hide();
          notifyInfo('Album media edited successfully!');
        })
        .catch(err => {
          notifyError(err.message);
        });
    } else {
      dispatch(uploadPremiumInfluencerAlbumMedia(data))
        .unwrap()
        .then(_ => {
          modalRef.current.hide();
          notifyInfo('Album media uploaded successfully!');
        })
        .catch(err => {
          notifyError(err.message);
        });
    }
  };

  return (
    <FormModal
      ref={modalRef}
      title={selectedAlbumMedia ? 'Edit Album Media (Premium)' : 'Upload Album Media (Premium)'}
      onClose={() => {
        modalRef.current.hide();
      }}
      onAfterClose={() => {
        reset();
        onAfterClose();
      }}
      leftButtonText="Cancel"
      onLeftButtonClick={() => {
        modalRef.current.hide();
      }}
      rightButtonText={selectedAlbumMedia ? 'Edit' : 'Submit'}
      onRightButtonClick={() => handleSubmit(onSubmit)()}
      rightButtonLoader={uploadAlbumMediaPending}
      shouldCloseOnOverlayClick={false}>
      <form>
        <Controller
          name="albumMedia"
          control={control}
          render={({ field }) => {
            return (
              <FileInput
                label="Album media"
                value={field.value}
                onChange={field.onChange}
                type={UPLOAD_FILE_CONSTRAINTS.TYPE.IMAGE}
                error={errors.albumMedia?.message}
                increasedFileHeight
              />
            );
          }}
        />
        <FormNumericInput
          label="Cost (credits)"
          {...register('cost', {
            valueAsNumber: true,
          })}
          error={errors.cost?.message}
        />
        <Controller
          name="isForAISale"
          control={control}
          render={({ field }) => {
            return (
              <FormCheckbox
                checked={field.value}
                onChange={e => field.onChange(e.target.checked)}
                error={errors.isForAISale?.message}
                hideErrorMessage>
                <Text16Regular>Is for AI Sale</Text16Regular>
              </FormCheckbox>
            );
          }}
        />
        <FormInput
          label="AI description"
          placeholder="Enter AI description"
          {...register('aiDescription')}
          error={errors.aiDescription?.message}
        />
        <FormInput
          label="Button description"
          placeholder="Enter button description"
          {...register('buttonDescription')}
          error={errors.buttonDescription?.message}
        />
      </form>
    </FormModal>
  );
};

export default UploadPremiumAlbumMediaFormModal;
