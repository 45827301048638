import styled from 'styled-components';

export const Container = styled.div`
  position: relative;
  width: 100%;
  max-width: 360rem;
  min-height: 100%;
  height: 1px;
  margin-left: auto;
  margin-right: auto;
`;

export const ContentContainer = styled.div`
  height: 100%;
  overflow-y: auto;
  margin-left: 28rem;
  padding: 2rem;
`;
