import styled from 'styled-components';

export const StyledLabel = styled.label`
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  font-weight: 300;
  font-size: 1.8rem;
  opacity: ${props => (props.disabled ? 0.8 : 1)};
`;

export const StyledLabelRow = styled.div`
  display: flex;
  align-items: center;
  margin-inline: 0.2rem;
  margin-bottom: 0.6rem;
`;
