import styled from 'styled-components';
import Input from '../../components/utils/inputs/Input/Input';
import { Text16Bold } from '../../components/utils/texts/text.styles';

export const Header = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 2rem;
`;

export const Title = styled(Text16Bold)`
  font-size: 2.8rem;
  margin-left: 1rem;
`;

export const SearchInput = styled(Input)`
  /* width: 42rem; */
  width: 100%;
  max-width: 36rem;
`;
