import { fetchUserInfo } from '../user/asyncThunks';
import { login } from './asyncThunks';

export const loginBuilder = builder => {
  builder.addCase(login.pending, state => {
    state.loginPending = true;
  });
  builder.addCase(login.fulfilled, (state, action) => {
    const { accessToken } = action.payload;
    state.loginPending = false;
    state.loggedIn = true;
    state.token = accessToken;
  });
  builder.addCase(login.rejected, state => {
    state.loginPending = false;
  });
};

export const fetchUserInfoBuilder = builder => {
  builder.addCase(fetchUserInfo.fulfilled, (state, { payload }) => {
    const { accessToken } = payload;
    state.loggedIn = true;
    state.token = accessToken;
  });
  builder.addCase(fetchUserInfo.rejected, state => {
    state.loggedIn = false;
  });
};
