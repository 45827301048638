import axiosApiClient from '..';
import { handleError, throwErrorIfWrongStatus } from './utils';

const BASE_URL = '/influencers';

class InfluencerService {
  fetchInfluencers = async (page, search) => {
    try {
      const response = await axiosApiClient.get(`${BASE_URL}?page=${page}&search=${search}`);
      throwErrorIfWrongStatus(response, 200);
      const { data, total } = response.data.data;
      return { data, total };
    } catch (err) {
      throw handleError(err, 'Error occured while fetching influencers.');
    }
  };

  fetchInfluencerById = async id => {
    try {
      const response = await axiosApiClient.get(`${BASE_URL}/${id}`);
      throwErrorIfWrongStatus(response, 200);
      const { influencer } = response.data.data;
      return influencer;
    } catch (err) {
      throw handleError(err, 'Error occured while fetching influencers.');
    }
  };

  createInfluencer = async formData => {
    try {
      const headers = { 'Content-Type': 'multipart/form-data' };
      const response = await axiosApiClient.post(`${BASE_URL}`, formData, { headers });
      throwErrorIfWrongStatus(response, 201);
      const { businessConnectionId } = response.data.data;
      return businessConnectionId;
    } catch (err) {
      throw handleError(err, 'Error occured while creating influencer.');
    }
  };

  editInfluencer = async (influencerId, formData) => {
    try {
      const headers = { 'Content-Type': 'multipart/form-data' };
      const response = await axiosApiClient.put(`${BASE_URL}/${influencerId}`, formData, { headers });
      throwErrorIfWrongStatus(response, 200);
      const { influencer } = response.data.data;
      return influencer;
    } catch (err) {
      throw handleError(err, 'Error occured while editing influencer.');
    }
  };

  editSecretMode = async (influencerId, secretModeId, formData) => {
    try {
      const headers = { 'Content-Type': 'multipart/form-data' };
      const response = await axiosApiClient.put(`${BASE_URL}/${influencerId}/secret-mode/${secretModeId}`, formData, {
        headers,
      });
      throwErrorIfWrongStatus(response, 200);
      const { influencer } = response.data.data;
      return influencer;
    } catch (err) {
      throw handleError(err, 'Error occured while editing influencer secret mode.');
    }
  };

  fetchInfluencerAlbumMedia = async (influencerId, page, type) => {
    try {
      const response = await axiosApiClient.get(
        `${BASE_URL}/album-media/${influencerId}?page=${page}&type=${type.toLowerCase()}`,
      );
      throwErrorIfWrongStatus(response, 200);
      const { data, maxReached } = response.data.data;
      return { data, maxReached, page };
    } catch (err) {
      throw handleError(err, 'Error occured while fetching influencers.');
    }
  };

  uploadInfluencerAlbumMedia = async (influencerId, albumMediaType, formData) => {
    try {
      const headers = { 'Content-Type': 'multipart/form-data' };
      const response = await axiosApiClient.post(
        `${BASE_URL}/${influencerId}/photo/${albumMediaType.toLowerCase()}`,
        formData,
        {
          headers,
        },
      );
      throwErrorIfWrongStatus(response, 200);
      const { photo } = response.data.data;
      return photo;
    } catch (err) {
      throw handleError(err, 'Error occured while uploading album media');
    }
  };

  editInfluencerAlbumMedia = async (influencerId, albumMediaId, albumMediaType, formData) => {
    try {
      const headers = { 'Content-Type': 'multipart/form-data' };
      const response = await axiosApiClient.put(
        `${BASE_URL}/${influencerId}/photo/${albumMediaType.toLowerCase()}/${albumMediaId}`,
        formData,
        {
          headers,
        },
      );
      throwErrorIfWrongStatus(response, 200);
      const { photo } = response.data.data;
      return photo;
    } catch (err) {
      throw handleError(err, 'Error occured while uploading album media');
    }
  };

  uploadInfluencerCoverAlbumMedia = async (influencerId, formData) => {
    try {
      const headers = { 'Content-Type': 'multipart/form-data' };
      const response = await axiosApiClient.post(`${BASE_URL}/${influencerId}/photo/cover`, formData, {
        headers,
      });
      throwErrorIfWrongStatus(response, 200);
      const { url } = response.data.data;
      return url;
    } catch (err) {
      throw handleError(err, 'Error occured while uploading cover album media');
    }
  };

  editInfluencerCoverAlbumMedia = async (influencerId, formData) => {
    try {
      const headers = { 'Content-Type': 'multipart/form-data' };
      const response = await axiosApiClient.put(`${BASE_URL}/${influencerId}/photo/cover`, formData, {
        headers,
      });
      throwErrorIfWrongStatus(response, 200);
      const { url } = response.data.data;
      return url;
    } catch (err) {
      throw handleError(err, 'Error occured while editing cover album media');
    }
  };

  deleteInfluencerCoverAlbumMedia = async (influencerId, photoId) => {
    try {
      const response = await axiosApiClient.post(`${BASE_URL}/${influencerId}/photo/cover/delete`, { photoId });
      throwErrorIfWrongStatus(response, 200);
      return photoId;
    } catch (err) {
      throw handleError(err, 'Error occured while deleting cover album media');
    }
  };

  promoteInfluencerCoverAlbumMedia = async (influencerId, photoId) => {
    try {
      const response = await axiosApiClient.post(`${BASE_URL}/${influencerId}/photo/cover/promote`, { photoId });
      throwErrorIfWrongStatus(response, 200);
    } catch (err) {
      throw handleError(err, 'Error occured while promoting cover album media');
    }
  };

  fetchInfluencerVariables = async () => {
    try {
      const response = await axiosApiClient.get(`${BASE_URL}/variables`);
      throwErrorIfWrongStatus(response, 200);
      const { appearanceTags, personalityTags, highlightTags, businessConnections } = response.data.data;
      return { appearanceTags, personalityTags, highlightTags, businessConnections };
    } catch (err) {
      throw handleError(err, 'Error occured while fetching influencer variables');
    }
  };
}

const influencerService = new InfluencerService();
export default influencerService;
