import React, { useState } from 'react';
import { ImageComponent, ImageContainer, SkeletonContainer } from './Image.styles';
import 'react-loading-skeleton/dist/skeleton.css';

function Image({ src, alt, className, onClick }) {
  const [loaded, setLoaded] = useState(false);

  const handleImageLoaded = () => {
    setLoaded(true);
  };

  return (
    <ImageContainer className={className} onClick={onClick} $cursorPointer={!!onClick}>
      {!loaded && <SkeletonContainer />}
      <ImageComponent src={src} alt={alt} onLoad={handleImageLoaded} style={{ display: loaded ? 'block' : 'none' }} />
    </ImageContainer>
  );
}

export default Image;
