import React from 'react';
import {
  DashboardContentContainer,
  DashboardItemHeader,
  DashboardItemHeaderSquare,
  DashboardItemTitle,
  EarningsChartContainer,
  EarningsInfoContainer,
  Header,
  HeaderItem,
  HeaderLeftContainer,
  HeaderRightContainer,
  Logo,
  MessagesInfoContainer,
  PhotosInfoContainer,
  ProfileInfoContainer,
  ProfilePhoto,
  ProfileRoleText,
  PromotionButton,
  PromotionContainer,
  PromotionLeftContainer,
  PromotionRightContainer,
  PromotionSubtitle,
  PromotionTitle,
  Row,
  UsersInfoContainers,
} from './InfluencerDashboardPage.styles';
import { useSelector } from 'react-redux';
import { selectUser } from '../../../store/slices/user/slice';
import { Text16Bold, Text16Light, Text26ExtraBold } from '../../../components/utils/texts/text.styles';
import LogoIcon from '../../../assets/images/logo.png';

function InfluencerDashboardPage() {
  const user = useSelector(selectUser);

  return (
    <>
      <Header>
        <HeaderLeftContainer>
          <Text16Light>Welcome back,</Text16Light>
          <Text26ExtraBold>{user.fullName}</Text26ExtraBold>
        </HeaderLeftContainer>
        <HeaderRightContainer>
          <HeaderItem>
            <ProfilePhoto src={user.profilePhoto} alt="profile-photo" />
            <ProfileInfoContainer>
              <Text16Bold>{user.fullName}</Text16Bold>
              <ProfileRoleText>{user.role?.toLowerCase()}</ProfileRoleText>
            </ProfileInfoContainer>
          </HeaderItem>
        </HeaderRightContainer>
      </Header>
      <DashboardContentContainer>
        <PromotionContainer>
          <PromotionLeftContainer>
            <PromotionTitle>Bring Your Selfies to Life!</PromotionTitle>
            <PromotionSubtitle>
              We're thrilled to announce the latest addition to our app - the groundbreaking Lip Sync feature!
            </PromotionSubtitle>
            <PromotionButton title="Request Access" />
          </PromotionLeftContainer>
          <PromotionRightContainer>
            <Logo src={LogoIcon} />
          </PromotionRightContainer>
        </PromotionContainer>
        <UsersInfoContainers>
          <DashboardItemHeader>
            <Row>
              <DashboardItemHeaderSquare />
              <DashboardItemTitle>Top Users</DashboardItemTitle>
            </Row>
          </DashboardItemHeader>
        </UsersInfoContainers>
        <EarningsInfoContainer>
          <DashboardItemHeader>
            <Row>
              <DashboardItemHeaderSquare />
              <DashboardItemTitle>Total Earning</DashboardItemTitle>
            </Row>
          </DashboardItemHeader>
        </EarningsInfoContainer>
        <MessagesInfoContainer>
          <DashboardItemHeader>
            <Row>
              <DashboardItemHeaderSquare />
              <DashboardItemTitle>Messages Stats</DashboardItemTitle>
            </Row>
          </DashboardItemHeader>
        </MessagesInfoContainer>
        <EarningsChartContainer>
          <DashboardItemHeader>
            <Row>
              <DashboardItemHeaderSquare />
              <DashboardItemTitle>Earnings Activities</DashboardItemTitle>
            </Row>
          </DashboardItemHeader>
        </EarningsChartContainer>
        <PhotosInfoContainer>
          <DashboardItemHeader>
            <Row>
              <DashboardItemHeaderSquare />
              <DashboardItemTitle>Photos Stats</DashboardItemTitle>
            </Row>
          </DashboardItemHeader>
        </PhotosInfoContainer>
      </DashboardContentContainer>
    </>
  );
}

export default InfluencerDashboardPage;
