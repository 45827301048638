import styled from 'styled-components';
import { ReactComponent as ExpandMore } from '../../../assets/icons/expand-more.svg';
import { ReactComponent as ExpandLess } from '../../../assets/icons/expand-less.svg';
import { Text16Bold } from '../texts/text.styles';

export const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 5.6rem;
  padding: 0 2rem;
  background-color: #fafbfc;
  cursor: pointer;
  margin-left: -2rem;
  margin-right: -2rem;
  border-bottom: 1.4px solid #eef0f7;
`;

export const Row = styled.div`
  display: flex;
  align-items: center;
`;

export const Title = styled(Text16Bold)`
  margin-left: 1rem;
`;

export const ExpandMoreIcon = styled(ExpandMore)``;
export const ExpandLessIcon = styled(ExpandLess)``;
