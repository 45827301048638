import { capitalizeFirstLetter } from './util';

export const BASE_URL = 'https://staging-api.onlychats.xyz';
// export const BASE_URL = 'http://localhost:5001';

// Upload
export const UPLOAD_FILE_CONSTRAINTS = {
  TYPE: {
    IMAGE: 'IMAGE',
    VOICE: 'VOICE',
  },
  IMAGE: {
    MAX_SIZE_IN_BYTES: 15 * 1024 * 1024, // 15 MB
    ALLOWED_EXTENSIONS: ['png', 'jpeg', 'jpg'],
    ALLOWED_EXTENSIONS_TEXT: '.png, .jpeg, .jpg',
  },
  VOICE: {
    MAX_SIZE_IN_BYTES: 200 * 1024 * 1024, // 200 MB
    ALLOWED_EXTENSIONS: ['mpeg3', 'x-mpeg-3', 'mpeg', 'm4a', 'x-m4a', 'wav', 'x-wav'],
    ALLOWED_EXTENSIONS_TEXT: '.mp3, .wav, .m4a',
  },
};

export const ENVIRONMENT = {
  PRODUCTION: 'production',
  DEVELOPMENT: 'development',
};

// Store
export const LOGOUT = 'LOGOUT';

// Roles
export const INFLUENCER_ROLE = {
  INFLUENCER: 'INFLUENCER',
};
export const INFLUENCER_ROLES = [INFLUENCER_ROLE.INFLUENCER];

export const ADMIN_ROLE = {
  SUPER_ADMIN: 'SUPER_ADMIN',
  ADMIN: 'ADMIN',
};
export const ADMIN_ROLES = [ADMIN_ROLE.SUPER_ADMIN, ADMIN_ROLE.ADMIN];

export const MODEL = {
  BASIC: 'BASIC',
  ULTRANSFW: 'ULTRANSFW',
};
export const MODELS = [MODEL.BASIC, MODEL.ULTRANSFW];
export const MODELS_FOR_FORM = MODELS.map(model => ({
  value: model,
  label: capitalizeFirstLetter(model),
}));

export const ALBUM_MEDIA_TYPE = {
  FREE: 'FREE',
  PREMIUM: 'PREMIUM',
  INACTIVE: 'INACTIVE',
  COVER: 'COVER',
};

export const INFLUENCER_STATUS = {
  ACTIVE: 'ACTIVE',
  INACTIVE: 'INACTIVE',
};

export const INFLUENCER_CATEGORY = {
  PERSON: 'PERSON',
  ANIME: 'ANIME',
};
export const INFLUENCER_CATEGORIES = Object.values(INFLUENCER_CATEGORY);

export const GENDER = {
  MALE: 'MALE',
  FEMALE: 'FEMALE',
};
export const GENDERS = Object.values(GENDER);
