export const Transition = {
  hover: {
    on: 'all 0.2s ease-in',
    off: 'all 0.2s ease-out',
  },
  reaction: {
    on: 'all 0.15s ease-in',
    off: 'all 0.1s ease-out',
  },
  dropdown: {
    off: 'all 0.35s ease-out',
  },
};

export const theme = {
  colors: {
    mainText: '#161320',
    // linearLeft: '#C9A5FF',
    // linearLeft: '#8642F7',
    // linearRight: '#4E2A8A',
    // #8B52FF -> #6637CA
    // linearLeft: '#8B52FF',
    // linearRight: '#6637CA',
    linearLeft: '#4e65ca',
    linearRight: '#5970d0',
    error: '#FF2226',
  },
};
