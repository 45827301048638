import React from 'react';
import { AuthContainer } from '../../../components';
import { EmailInput, FormHeader, IconWrapper, LoginButton, PasswordInput, WelcomeText } from './LoginPage.styles';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useDispatch, useSelector } from 'react-redux';
import { selectLoginPending } from '../../../store/slices/auth/slice';
import { login } from '../../../store/slices/auth/asyncThunks';
import { notifyError } from '../../../utils/notify';
import { loginFormSchema } from '../../../forms/schemas/auth.schema';
import { ReactComponent as LogoIcon } from '../../../assets/icons/logo-light.svg';
const LoginPage = () => {
  const loginPending = useSelector(selectLoginPending);
  const dispatch = useDispatch();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: {
      email: '',
      password: '',
    },
    delayError: 300,
    resolver: yupResolver(loginFormSchema),
  });

  const onSubmit = async data => {
    const { email, password } = data;
    dispatch(
      login({
        email,
        password,
      }),
    )
      .unwrap()
      .then(_ => {})
      .catch(err => {
        notifyError(err.message);
      });
  };

  return (
    <AuthContainer>
      <WelcomeText>Welcome to</WelcomeText>
      <IconWrapper>
        <LogoIcon width={180} height={60} />
      </IconWrapper>
      <FormHeader>Login</FormHeader>
      <form onSubmit={handleSubmit(onSubmit)}>
        <EmailInput label="Email" placeholder="Enter email" {...register('email')} error={errors.email?.message} />
        <PasswordInput
          label="Password"
          placeholder="Enter password"
          {...register('password')}
          error={errors.password?.message}
          type="password"
        />
        <LoginButton title="Login" type="submit" isLoading={loginPending} />
      </form>
    </AuthContainer>
  );
};

export default LoginPage;
