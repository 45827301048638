import React, { useEffect, useRef } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import FormModal from '../../utils/modals/FormModal/FormModal';
import { useDispatch, useSelector } from 'react-redux';
import { selectUploadAlbumMediaPending } from '../../../store/slices/influencer/slice';
import { notifyError, notifyInfo } from '../../../utils/notify';
import FileInput from '../../utils/inputs/FileInput/FileInput';
import { UPLOAD_FILE_CONSTRAINTS } from '../../../utils/constants';
import {
  editCoverInfluencerAlbumMedia,
  uploadCoverInfluencerAlbumMedia,
} from '../../../store/slices/influencer/asyncThunks';
import { uploadCoverAlbumMediumFormSchema } from '../../../forms/schemas/albumMedia.schema';
import CropImageModal from '../../utils/modals/CropImageModal/CropImageModal';

const UploadCoverAlbumMediaFormModal = ({ modalRef, selectedAlbumMedia, onAfterClose, customOnSubmit }) => {
  const uploadAlbumMediaPending = useSelector(selectUploadAlbumMediaPending);
  const cropImageRef = useRef();
  const dispatch = useDispatch();

  const {
    handleSubmit,
    setValue,
    control,
    reset,
    formState: { errors },
    watch,
    getValues,
  } = useForm({
    defaultValues: {
      albumMedia: null,
    },
    delayError: 300,
    resolver: yupResolver(uploadCoverAlbumMediumFormSchema),
  });
  const watchAlbumMedia = watch('albumMedia');

  useEffect(() => {
    if (selectedAlbumMedia) {
      const { url, albumMedia } = selectedAlbumMedia;
      setValue('albumMedia', url ? url : albumMedia);
    } else {
      reset();
    }
  }, [selectedAlbumMedia, setValue, reset]);

  useEffect(() => {
    if (watchAlbumMedia) {
      if (typeof watchAlbumMedia !== 'string') {
        cropImageRef.current?.show();
      }
    }
  }, [watchAlbumMedia]);

  const onSubmit = data => {
    if (!!customOnSubmit) {
      customOnSubmit(data);
      modalRef.current.hide();
      return;
    }

    if (selectedAlbumMedia) {
      dispatch(editCoverInfluencerAlbumMedia({ ...data, url: selectedAlbumMedia.url }))
        .unwrap()
        .then(_ => {
          modalRef.current.hide();
          notifyInfo('Album media edited successfully!');
        })
        .catch(err => {
          notifyError(err.message);
        });
    } else {
      dispatch(uploadCoverInfluencerAlbumMedia(data))
        .unwrap()
        .then(_ => {
          modalRef.current.hide();
          notifyInfo('Album media uploaded successfully!');
        })
        .catch(err => {
          notifyError(err.message);
        });
    }
  };

  return (
    <FormModal
      ref={modalRef}
      title={selectedAlbumMedia ? 'Edit Album Media (Cover)' : 'Upload Album Media (Cover)'}
      onClose={() => {
        modalRef.current.hide();
      }}
      onAfterClose={() => {
        reset();
        onAfterClose();
      }}
      leftButtonText="Cancel"
      onLeftButtonClick={() => {
        modalRef.current.hide();
      }}
      rightButtonText={selectedAlbumMedia ? 'Edit' : 'Submit'}
      onRightButtonClick={() => handleSubmit(onSubmit)()}
      rightButtonLoader={uploadAlbumMediaPending}
      shouldCloseOnOverlayClick={false}>
      <form>
        <Controller
          name="albumMedia"
          control={control}
          render={({ field }) => {
            return (
              <FileInput
                label="Album media"
                value={field.value}
                onChange={field.onChange}
                type={UPLOAD_FILE_CONSTRAINTS.TYPE.IMAGE}
                error={errors.albumMedia?.message}
                increasedFileHeight
              />
            );
          }}
        />
      </form>
      <CropImageModal
        ref={cropImageRef}
        aspectRatio={3 / 4}
        onClose={() => {
          setValue('albumMedia', null);
          cropImageRef.current.hide();
        }}
        onComplete={croppedImage => {
          setValue('albumMedia', croppedImage);
          cropImageRef.current.hide();
        }}
        originalImage={getValues('albumMedia')}
      />
    </FormModal>
  );
};

export default UploadCoverAlbumMediaFormModal;
