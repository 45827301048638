import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { selectUser } from '../../store/slices/user/slice';
import { INFLUENCER_ROLE } from '../../utils/constants';
import InfluencerDashboardPage from './influencer/InfluencerDashboardPage';

function DashboardPage() {
  const user = useSelector(selectUser);

  const DashboardPageContent = useMemo(() => {
    switch (user.role) {
      case INFLUENCER_ROLE.INFLUENCER:
        return <InfluencerDashboardPage />;
      default:
        return null;
    }
  }, [user.role]);

  return DashboardPageContent;
}

export default DashboardPage;
