import styled from 'styled-components';
import { FormCheckbox } from '../../../forms/form.styles';

export const SectionHeader = styled.div`
  display: flex;
  align-items: center;
  margin-top: ${props => (props.$first ? '2rem' : '4.4rem')};
  margin-bottom: 4.4rem;
`;

export const LeftDivider = styled.div`
  flex: 1;
  height: 1.2px;
  background: linear-gradient(90deg, #fff 0%, rgba(254, 145, 50, 0) 0.01%, rgba(73, 67, 93, 0.4) 100%);
  opacity: 0.8;
  margin-right: 2rem;
`;

export const RightDivider = styled.div`
  flex: 1;
  height: 1.2px;
  background: linear-gradient(270deg, #fff 0%, rgba(254, 145, 50, 0) 0.01%, rgba(73, 67, 93, 0.4) 100%);
  opacity: 0.8;
  margin-left: 2rem;
`;

export const TwoColumnsContainer = styled.div`
  display: grid;
  width: 100%;
  grid-template-columns: repeat(2, minmax(24rem, 1fr));
  column-gap: 3.6rem;
  row-gap: 2rem;
  margin-bottom: ${props => (props.$last ? '4rem' : 'none')};
`;

export const CustomCheckbox = styled(FormCheckbox)`
  margin-top: 3.2rem;
`;

export const TwoColumnsItem = styled.div`
  grid-column: 1 / -1;
`;
