import React from 'react';
import { useSelector } from 'react-redux';
import { Navigate, Outlet, useLocation } from 'react-router-dom';
import { selectLoggedIn } from '../../store/slices/auth/slice';
import { PrivateNavigation } from '../../components';
import { ContentContainer, Container } from './PrivateLayout.styles';

const PrivateLayout = () => {
  const isAuthenticated = useSelector(selectLoggedIn);

  const location = useLocation();

  if (!isAuthenticated) {
    return <Navigate to="/login" state={{ from: location }} replace />;
  }

  return (
    <Container>
      <PrivateNavigation />
      <ContentContainer>
        <Outlet />
      </ContentContainer>
    </Container>
  );
};

export default PrivateLayout;
