import { createSlice } from '@reduxjs/toolkit';
import {
  createPhotoActivityBuilder,
  deletePhotoActivityBuilder,
  fetchPhotoActivitiesBuilder,
  editPhotoActivityBuilder,
} from './builder';
import { LOGOUT } from '../../../utils/constants';

const INIT_STATE = {
  fetchPhotoActivitiesInfo: {
    pending: false,
    data: [],
    total: 0,
  },
  pending: {
    createPhotoActivity: false,
    editPhotoActivity: false,
    deletePhotoActivity: false,
  },
};

export const photoActivitySlice = createSlice({
  name: 'photoActivity',
  initialState: INIT_STATE,
  reducers: {},
  extraReducers: builder => {
    fetchPhotoActivitiesBuilder(builder);
    createPhotoActivityBuilder(builder);
    editPhotoActivityBuilder(builder);
    deletePhotoActivityBuilder(builder);
    builder.addCase(LOGOUT, () => ({ ...INIT_STATE }));
  },
});

export const selectFetchPhotoActivitiesInfo = state => state.photoActivityInfo.fetchPhotoActivitiesInfo;
export const selectCreatePhotoActivityPending = state => state.photoActivityInfo.pending.createPhotoActivity;
export const selectEditPhotoActivityPending = state => state.photoActivityInfo.pending.editPhotoActivity;
export const selectDeletePhotoActivityPending = state => state.photoActivityInfo.pending.deletePhotoActivity;

// export const {} = photoActivitySlice.actions;

export default photoActivitySlice.reducer;
