import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { selectUser } from '../../store/slices/user/slice';
import { ADMIN_ROLE, INFLUENCER_ROLE } from '../../utils/constants';
import AdminUsersPage from './admin/AdminUsersPage';
import InfluencerUsersPage from './influencer/InfluencerUsersPage';

function UsersPage() {
  const user = useSelector(selectUser);

  const UsersPageContent = useMemo(() => {
    switch (user.role) {
      case ADMIN_ROLE.SUPER_ADMIN:
      case ADMIN_ROLE.ADMIN:
        return <AdminUsersPage />;
      case INFLUENCER_ROLE.INFLUENCER:
        return <InfluencerUsersPage />;
      default:
        return null;
    }
  }, [user.role]);

  return UsersPageContent;
}

export default UsersPage;
