import axiosApiClient from '..';
import { handleError, throwErrorIfWrongStatus } from './utils';

const BASE_URL = '/photo-activities';

class PhotoActivityService {
  fetchPhotoActivities = async (page, search) => {
    try {
      const response = await axiosApiClient.get(`${BASE_URL}?page=${page}&search=${search}`);
      throwErrorIfWrongStatus(response, 200);
      const { data, total } = response.data.data;
      return { data, total };
    } catch (err) {
      throw handleError(err, 'Error occured while fetching photo activities.');
    }
  };

  createPhotoActivity = async body => {
    try {
      const response = await axiosApiClient.post(BASE_URL, body);
      throwErrorIfWrongStatus(response, 201);
      const { photoActivity } = response.data.data;
      return photoActivity;
    } catch (err) {
      throw handleError(err, 'Error occured while creating photo activity.');
    }
  };

  editPhotoActivity = async (id, body) => {
    try {
      const response = await axiosApiClient.put(`${BASE_URL}/${id}`, body);
      throwErrorIfWrongStatus(response, 200);
      const { photoActivity } = response.data.data;
      return photoActivity;
    } catch (err) {
      throw handleError(err, 'Error occured while creating photo activity.');
    }
  };

  deletePhotoActivity = async id => {
    try {
      const response = await axiosApiClient.delete(`${BASE_URL}/${id}`);
      throwErrorIfWrongStatus(response, 200);
      const { photoActivityId } = response.data.data;
      return photoActivityId;
    } catch (err) {
      throw handleError(err, 'Error occured while deleting photo activity.');
    }
  };
}

const photoActivityService = new PhotoActivityService();
export default photoActivityService;
