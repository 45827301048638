import styled from 'styled-components';
import { MainButton, TransparentButton } from '../../../components/utils/buttons/Button/Button';

export const CreateInfluencerForm = styled.form`
  margin-bottom: 10rem;
`;

export const Footer = styled.footer`
  width: calc(100% - 28rem);
  height: 8.6rem;
  position: absolute;
  bottom: 0;
  left: 28rem;
  right: 0;
  background-color: #fff;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  z-index: 3;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1.2rem 2rem;
`;

export const FooterLeftButton = styled(TransparentButton)`
  width: 20rem;
  font-weight: 500;
`;

export const FooterRightButton = styled(MainButton)`
  width: 28rem;
  font-weight: 500;
`;
