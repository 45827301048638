import styled from 'styled-components';
import { ReactComponent as CloseIconSvg } from '../../../../assets/icons/close.svg';
import { MainButton, TransparentButton } from '../../../utils/buttons/Button/Button';

export const ModalStyle = styled.div`
  position: relative;
  width: 100%;
  max-width: ${props => (props.$increasedWidth ? '104rem' : '64rem')};
  height: fit-content;
  min-height: 28rem;
  /* max-height: 84vh; */
  max-height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: #fff;
  border-radius: 1.6rem;
  outline: none;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
`;

export const OverlayStyle = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  padding: 3.2rem;
  z-index: 3500;
  background: rgba(22, 19, 32, 0.5);
`;

export const Header = styled.div`
  height: 3rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 3.2rem 3.2rem 0 3.2rem;
`;

export const HeaderIconContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const CloseIcon = styled(CloseIconSvg)`
  cursor: pointer;
`;

export const Content = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  margin-top: 2.4rem;
  margin-bottom: 2rem;
  padding: 0 3.2rem;
`;

export const Footer = styled.div`
  height: 5.4rem;
  margin: 0rem 3.2rem 3.2rem 3.2rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const LeftButton = styled(TransparentButton)`
  width: 30%;
  font-weight: 500;
`;

export const RightButton = styled(MainButton)`
  width: 60%;
  font-weight: 500;
`;
