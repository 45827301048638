import React, { useEffect } from 'react';
import { Controller, useFieldArray, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import ErrorContainer from '../../../components/utils/ErrorContainer/ErrorContainer';
import FormModal from '../../utils/modals/FormModal/FormModal';
import { AddMoreItemsButton } from '../../../pages/influencers/tabs/InfluencerSecretModesTab/EditSecretModeTab/EditSecretModeTab.styles';
import { InputLabel, Text16Regular, Text20Bold } from '../../utils/texts/text.styles';
import { createSecretModeAsAdminFormSchema } from '../../../forms/schemas/secretMode.schema';
import { FormFileInput, FormInput, FormNumericInput } from '../../../forms/form.styles';
import {
  CustomCheckbox,
  LeftDivider,
  RightDivider,
  SectionHeader,
  TwoColumnsContainer,
  TwoColumnsItem,
} from './CreateSecretModeFormModal.styles';
import { UPLOAD_FILE_CONSTRAINTS } from '../../../utils/constants';
import { VoiceInput } from '../../../pages/influencers/CreateInfluencerPage/steps/CreateInfluencerPageStepBasicInfo/CreateInfluencerPageStepBasicInfo.styles';

function CreateSecretModeFormModal({
  modalRef,
  selectedSecretMode,
  onAfterClose,
  customOnSubmit,
  forceDefaultTrue = false,
  isVoiceCallEnabled,
  isVideoCallEnabled,
}) {
  const {
    register,
    handleSubmit,
    setValue,
    reset,
    control,
    formState: { errors },
  } = useForm({
    defaultValues: {
      name: '',
      symbol: '',
      welcomePhoto: null,
      suggestedReply: '',
      pricing: {
        textMessage: '',
        voiceMessage: '',
        voiceCallPerMinute: '',
        videoCallPerMinute: '',
      },
      voiceCall: {
        voice: null,
        vapi: {
          assistantId: '',
        },
      },
      videoCall: {
        personaId: '',
        replicaId: '',
      },
      isDefault: forceDefaultTrue ? true : false,
      prompt: {
        male: {
          mainMessage: {
            template: '',
            dynamicSection: '',
            initialMessage: '',
          },
          voiceCall: {
            template: '',
            dynamicSection: '',
          },
          videoCall: {
            template: '',
            dynamicSection: '',
          },
          inactiveMessage: {
            template: '',
          },
        },
        female: {
          mainMessage: {
            template: '',
            dynamicSection: '',
            initialMessage: '',
          },
          voiceCall: {
            template: '',
            dynamicSection: '',
          },
          videoCall: {
            template: '',
            dynamicSection: '',
          },
          inactiveMessage: {
            template: '',
          },
        },
        other: {
          mainMessage: {
            template: '',
            dynamicSection: '',
            initialMessage: '',
          },
          voiceCall: {
            template: '',
            dynamicSection: '',
          },
          videoCall: {
            template: '',
            dynamicSection: '',
          },
          inactiveMessage: {
            template: '',
          },
        },
      },
      welcomeMessage: {
        initial: '',
        others: [],
      },
      insufficientFundsMessages: [],
      inactiveMessages: [],
      voiceConfig: {
        stability: 0.35,
        similarityBoost: 0.75,
        style: 0.49,
        useSpeakerBoost: true,
      },
    },
    delayError: 300,
    resolver: yupResolver(createSecretModeAsAdminFormSchema),
  });

  useEffect(() => {
    setValue('isDefault', forceDefaultTrue);
  }, [setValue, forceDefaultTrue]);

  useEffect(() => {
    if (selectedSecretMode) {
      const secretMode = selectedSecretMode;
      setValue('name', secretMode.name);
      setValue('symbol', secretMode.symbol);
      setValue('welcomePhoto', secretMode.welcomePhoto);
      setValue('suggestedReply', secretMode.suggestedReply);
      setValue('voiceCall', secretMode.voiceCall);
      // setValue('voiceCall.voice', secretMode.voiceCall.voice);
      // setValue('voiceCall.vapi.assistantId', secretMode.voiceCall.vapi.assistantId);
      setValue('videoCall', secretMode.videoCall);
      // setValue('videoCall.replicaId', secretMode.videoCall.replicaId);
      // setValue('videoCall.personaId', secretMode.videoCall.personaId);
      setValue('pricing', secretMode.pricing);
      // setValue('pricing.textMessage', secretMode.pricing.textMessage);
      // setValue('pricing.voiceMessage', secretMode.pricing.voiceMessage);
      // setValue('pricing.voiceCallPerMinute', secretMode.pricing.voiceCallPerMinute);
      // setValue('pricing.videoCallPerMinute', secretMode.pricing.videoCallPerMinute);
      setValue('isDefault', secretMode.isDefault);

      setValue('prompt', secretMode.prompt);
      // setValue('prompt.male.mainMessage.template', secretMode.prompt.male.mainMessage.template);
      // setValue('prompt.male.mainMessage.dynamicSection', secretMode.prompt.male.mainMessage.dynamicSection);
      // setValue('prompt.male.mainMessage.initialMessage', secretMode.prompt.male.mainMessage.initialMessage);
      // setValue('prompt.male.voiceCall.template', secretMode.prompt.male.voiceCall.template);
      // setValue('prompt.male.voiceCall.dynamicSection', secretMode.prompt.male.voiceCall.dynamicSection);
      // setValue('prompt.male.videoCall.template', secretMode.prompt.male.videoCall.template);
      // setValue('prompt.male.videoCall.dynamicSection', secretMode.prompt.male.videoCall.dynamicSection);
      // setValue('prompt.male.inactiveMessage.template', secretMode.prompt.male.inactiveMessage.template);

      // setValue('prompt.female.mainMessage.template', secretMode.prompt.female.mainMessage.template);
      // setValue('prompt.female.mainMessage.dynamicSection', secretMode.prompt.female.mainMessage.dynamicSection);
      // setValue('prompt.female.mainMessage.initialMessage', secretMode.prompt.female.mainMessage.initialMessage);
      // setValue('prompt.female.voiceCall.template', secretMode.prompt.female.voiceCall.template);
      // setValue('prompt.female.voiceCall.dynamicSection', secretMode.prompt.female.voiceCall.dynamicSection);
      // setValue('prompt.female.videoCall.template', secretMode.prompt.female.videoCall.template);
      // setValue('prompt.female.videoCall.dynamicSection', secretMode.prompt.female.videoCall.dynamicSection);
      // setValue('prompt.female.inactiveMessage.template', secretMode.prompt.female.inactiveMessage.template);

      // setValue('prompt.other.mainMessage.template', secretMode.prompt.other.mainMessage.template);
      // setValue('prompt.other.mainMessage.dynamicSection', secretMode.prompt.other.mainMessage.dynamicSection);
      // setValue('prompt.other.mainMessage.initialMessage', secretMode.prompt.other.mainMessage.initialMessage);
      // setValue('prompt.other.voiceCall.template', secretMode.prompt.other.voiceCall.template);
      // setValue('prompt.other.voiceCall.dynamicSection', secretMode.prompt.other.voiceCall.dynamicSection);
      // setValue('prompt.other.videoCall.template', secretMode.prompt.other.videoCall.template);
      // setValue('prompt.other.videoCall.dynamicSection', secretMode.prompt.other.videoCall.dynamicSection);
      // setValue('prompt.other.inactiveMessage.template', secretMode.prompt.other.inactiveMessage.template);
      setValue('welcomeMessage', secretMode.welcomeMessage);
      // setValue('welcomeMessage.initial', secretMode.welcomeMessage.initial);
      // setValue('welcomeMessage.others', secretMode.welcomeMessage.others);
      setValue('insufficientFundsMessages', secretMode.insufficientFundsMessages);
      setValue('inactiveMessages', secretMode.inactiveMessages);
      setValue('voiceConfig', secretMode.voiceConfig);
      // setValue('voiceConfig.stability', secretMode.voiceConfig.stability);
      // setValue('voiceConfig.similarityBoost', secretMode.voiceConfig.similarityBoost);
      // setValue('voiceConfig.style', secretMode.voiceConfig.style);
      // setValue('voiceConfig.useSpeakerBoost', secretMode.voiceConfig.useSpeakerBoost);
    }
  }, [selectedSecretMode, setValue]);

  const {
    fields: otherWelcomeMessagesFields,
    append: appendOtherWelcomeMessage,
    remove: removeOtherWelcomeMessage,
  } = useFieldArray({
    control,
    name: 'welcomeMessage.others',
  });

  const {
    fields: insufficientFundsMessagesFields,
    append: appendInsufficientFundsMessage,
    remove: removeInsufficientFundsMessage,
  } = useFieldArray({
    control,
    name: 'insufficientFundsMessages',
  });

  const {
    fields: inactiveMessagesFields,
    append: appendInactiveMessage,
    remove: removeInactiveMessage,
  } = useFieldArray({
    control,
    name: 'inactiveMessages',
  });

  const onSubmit = data => {
    if (!!customOnSubmit) {
      customOnSubmit(data);
      modalRef.current.hide();
      return;
    }

    // TODO: Create secret mode for existing influencer
    // dispatch(uploadFreeInfluencerAlbumMedia(data))
    //     .unwrap()
    //     .then(_ => {
    //       modalRef.current.hide();
    //       notifyInfo('Album media uploaded successfully!');
    //     })
    //     .catch(err => {
    //       notifyError(err.message);
    //     });
  };

  const Section = ({ title, first }) => (
    <SectionHeader $first={first}>
      <LeftDivider />
      <Text20Bold>{title}</Text20Bold>
      <RightDivider />
    </SectionHeader>
  );

  return (
    <FormModal
      ref={modalRef}
      title={selectedSecretMode ? 'Edit Secret Mode' : 'Create Secret Mode'}
      onClose={() => {
        modalRef.current.hide();
      }}
      onAfterClose={() => {
        reset();
        onAfterClose();
      }}
      leftButtonText="Cancel"
      onLeftButtonClick={() => {
        modalRef.current.hide();
      }}
      rightButtonText={selectedSecretMode ? 'Edit' : 'Submit'}
      onRightButtonClick={() => handleSubmit(onSubmit)()}
      rightButtonLoader={false} // TODO: Add pending when creating secret mode for existing influencer
      shouldCloseOnOverlayClick={false}
      increasedWidth>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Section title="Basic information" first />
        <TwoColumnsContainer>
          <FormInput label="Name" placeholder="Enter name" {...register('name')} error={errors.name?.message} />
          <FormNumericInput
            label="Text message price (credits)"
            {...register('pricing.textMessage', {
              valueAsNumber: true,
            })}
            error={errors.pricing?.textMessage?.message}
          />
          <FormInput label="Symbol" placeholder="Enter symbol" {...register('symbol')} error={errors.symbol?.message} />
          <FormNumericInput
            label="Voice message price (credits)"
            {...register('pricing.voiceMessage', {
              valueAsNumber: true,
            })}
            error={errors.pricing?.voiceMessage?.message}
          />
          <Controller
            name="welcomePhoto"
            control={control}
            render={({ field }) => {
              return (
                <FormFileInput
                  label="Welcome photo"
                  value={field.value}
                  onChange={field.onChange}
                  type={UPLOAD_FILE_CONSTRAINTS.TYPE.IMAGE}
                  error={errors.welcomePhoto?.message}
                />
              );
            }}
          />
          <div>
            <FormInput
              label="Suggested reply"
              placeholder="Enter suggested reply"
              {...register('suggestedReply')}
              error={errors.suggestedReply?.message}
            />
            <Controller
              name="isDefault"
              control={control}
              render={({ field }) => {
                return (
                  <CustomCheckbox
                    checked={field.value}
                    onChange={e => field.onChange(e.target.checked)}
                    error={errors.isDefault?.message}
                    disabled={forceDefaultTrue || (selectedSecretMode && selectedSecretMode.isDefault)}
                    hideErrorMessage>
                    <Text16Regular>Is Default</Text16Regular>
                  </CustomCheckbox>
                );
              }}
            />
          </div>
        </TwoColumnsContainer>
        {isVoiceCallEnabled && (
          <>
            <Section title="Voice call settings" />
            <FormNumericInput
              label="Voice call per minute price (credits)"
              {...register('pricing.voiceCallPerMinute', {
                valueAsNumber: true,
              })}
              error={errors.pricing?.voiceCallPerMinute?.message}
            />
            <FormInput
              label="Vapi assistant id"
              placeholder="Enter vapi assistant id"
              {...register('voiceCall.vapi.assistantId')}
              error={errors.voiceCall?.vapi?.assistantId?.message}
            />
            <Controller
              name="voiceCall.voice"
              control={control}
              render={({ field }) => {
                return (
                  <VoiceInput
                    label="Voice"
                    value={field.value}
                    onChange={field.onChange}
                    type={UPLOAD_FILE_CONSTRAINTS.TYPE.VOICE}
                    error={errors.voiceCall?.voice?.message}
                    increasedFileWidth
                  />
                );
              }}
            />
          </>
        )}
        {isVideoCallEnabled && (
          <>
            <Section title="Video call settings" />
            <FormNumericInput
              label="Video call per minute price (credits)"
              {...register('pricing.videoCallPerMinute', {
                valueAsNumber: true,
              })}
              error={errors.pricing?.videoCallPerMinute?.message}
            />
            <FormInput
              label="Replica id"
              placeholder="Enter replica id"
              {...register('videoCall.replicaId')}
              error={errors.videoCall?.replicaId?.message}
            />
            <FormInput
              label="Persona id"
              placeholder="Enter persona id"
              {...register('videoCall.personaId')}
              error={errors.videoCall?.personaId?.message}
            />
          </>
        )}
        <Section title="Prompts (Male)" />
        <FormInput
          label="Main message template (Male)"
          placeholder="Enter main template"
          {...register('prompt.male.mainMessage.template')}
          error={errors.prompt?.male?.mainMessage?.template?.message}
          type="textarea"
          specificTextAreaHeight={26}
        />
        <FormInput
          label="Main message dynamic section (Male)"
          placeholder="Enter main dynamic section"
          {...register('prompt.male.mainMessage.dynamicSection')}
          error={errors.prompt?.male?.mainMessage?.dynamicSection?.message}
          type="textarea"
          specificTextAreaHeight={26}
        />
        <FormInput
          label="Main message initial message (Male)"
          placeholder="Enter main initial message"
          {...register('prompt.male.mainMessage.initialMessage')}
          error={errors.prompt?.male?.mainMessage?.initialMessage?.message}
          type="textarea"
          specificTextAreaHeight={26}
        />
        {isVoiceCallEnabled && (
          <>
            <FormInput
              label="Voice call template (Male)"
              placeholder="Enter voice call template"
              {...register('prompt.male.voiceCall.template')}
              error={errors.prompt?.male?.voiceCall?.template?.message}
              type="textarea"
              specificTextAreaHeight={26}
            />
            <FormInput
              label="Voice call dynamic section (Male)"
              placeholder="Enter voice call dynamic section"
              {...register('prompt.male.voiceCall.dynamicSection')}
              error={errors.prompt?.male?.voiceCall?.dynamicSection?.message}
              type="textarea"
              specificTextAreaHeight={26}
            />
          </>
        )}
        {isVideoCallEnabled && (
          <>
            <FormInput
              label="Video call template (Male)"
              placeholder="Enter video call template"
              {...register('prompt.male.videoCall.template')}
              error={errors.prompt?.male?.videoCall?.template?.message}
              type="textarea"
              specificTextAreaHeight={26}
            />
            <FormInput
              label="Video call dynamic section (Male)"
              placeholder="Enter video call dynamic section"
              {...register('prompt.male.videoCall.dynamicSection')}
              error={errors.prompt?.male?.videoCall?.dynamicSection?.message}
              type="textarea"
              specificTextAreaHeight={26}
            />
          </>
        )}
        <FormInput
          label="Inactive message template (Male)"
          placeholder="Enter inactive template"
          {...register('prompt.male.inactiveMessage.template')}
          error={errors.prompt?.male?.inactiveMessage?.template?.message}
          type="textarea"
          specificTextAreaHeight={26}
        />
        <Section title="Prompts (Female)" />
        <FormInput
          label="Main message template (Female)"
          placeholder="Enter main template"
          {...register('prompt.female.mainMessage.template')}
          error={errors.prompt?.female?.mainMessage?.template?.message}
          type="textarea"
          specificTextAreaHeight={26}
        />
        <FormInput
          label="Main message dynamic section (Female)"
          placeholder="Enter main dynamic section"
          {...register('prompt.female.mainMessage.dynamicSection')}
          error={errors.prompt?.female?.mainMessage?.dynamicSection?.message}
          type="textarea"
          specificTextAreaHeight={26}
        />
        <FormInput
          label="Main message initial message (Female)"
          placeholder="Enter main initial message"
          {...register('prompt.female.mainMessage.initialMessage')}
          error={errors.prompt?.female?.mainMessage?.initialMessage?.message}
          type="textarea"
          specificTextAreaHeight={26}
        />
        {isVoiceCallEnabled && (
          <>
            <FormInput
              label="Voice call template (Female)"
              placeholder="Enter voice call template"
              {...register('prompt.female.voiceCall.template')}
              error={errors.prompt?.female?.voiceCall?.template?.message}
              type="textarea"
              specificTextAreaHeight={26}
            />
            <FormInput
              label="Voice call dynamic section (Female)"
              placeholder="Enter voice call dynamic section"
              {...register('prompt.female.voiceCall.dynamicSection')}
              error={errors.prompt?.female?.voiceCall?.dynamicSection?.message}
              type="textarea"
              specificTextAreaHeight={26}
            />
          </>
        )}
        {isVideoCallEnabled && (
          <>
            <FormInput
              label="Video call template (Female)"
              placeholder="Enter video call template"
              {...register('prompt.female.videoCall.template')}
              error={errors.prompt?.female?.videoCall?.template?.message}
              type="textarea"
              specificTextAreaHeight={26}
            />
            <FormInput
              label="Video call dynamic section (Female)"
              placeholder="Enter video call dynamic section"
              {...register('prompt.female.videoCall.dynamicSection')}
              error={errors.prompt?.female?.videoCall?.dynamicSection?.message}
              type="textarea"
              specificTextAreaHeight={26}
            />
          </>
        )}
        <FormInput
          label="Inactive message template (Female)"
          placeholder="Enter inactive template"
          {...register('prompt.female.inactiveMessage.template')}
          error={errors.prompt?.female?.inactiveMessage?.template?.message}
          type="textarea"
          specificTextAreaHeight={26}
        />
        <Section title="Prompts (Other)" />
        <FormInput
          label="Main message template (Other)"
          placeholder="Enter main template"
          {...register('prompt.other.mainMessage.template')}
          error={errors.prompt?.other?.mainMessage?.template?.message}
          type="textarea"
          specificTextAreaHeight={26}
        />
        <FormInput
          label="Main message dynamic section (Other)"
          placeholder="Enter main dynamic section"
          {...register('prompt.other.mainMessage.dynamicSection')}
          error={errors.prompt?.other?.mainMessage?.dynamicSection?.message}
          type="textarea"
          specificTextAreaHeight={26}
        />
        <FormInput
          label="Main message initial message (Other)"
          placeholder="Enter main initial message"
          {...register('prompt.other.mainMessage.initialMessage')}
          error={errors.prompt?.other?.mainMessage?.initialMessage?.message}
          type="textarea"
          specificTextAreaHeight={26}
        />
        {isVoiceCallEnabled && (
          <>
            <FormInput
              label="Voice call template (Other)"
              placeholder="Enter voice call template"
              {...register('prompt.other.voiceCall.template')}
              error={errors.prompt?.other?.voiceCall?.template?.message}
              type="textarea"
              specificTextAreaHeight={26}
            />
            <FormInput
              label="Voice call dynamic section (Other)"
              placeholder="Enter voice call dynamic section"
              {...register('prompt.other.voiceCall.dynamicSection')}
              error={errors.prompt?.other?.voiceCall?.dynamicSection?.message}
              type="textarea"
              specificTextAreaHeight={26}
            />
          </>
        )}
        {isVideoCallEnabled && (
          <>
            <FormInput
              label="Video call template (Other)"
              placeholder="Enter video call template"
              {...register('prompt.other.videoCall.template')}
              error={errors.prompt?.other?.videoCall?.template?.message}
              type="textarea"
              specificTextAreaHeight={26}
            />
            <FormInput
              label="Video call dynamic section (Other)"
              placeholder="Enter video call dynamic section"
              {...register('prompt.other.videoCall.dynamicSection')}
              error={errors.prompt?.other?.videoCall?.dynamicSection?.message}
              type="textarea"
              specificTextAreaHeight={26}
            />
          </>
        )}
        <FormInput
          label="Inactive message template (Other)"
          placeholder="Enter inactive template"
          {...register('prompt.other.inactiveMessage.template')}
          error={errors.prompt?.other?.inactiveMessage?.template?.message}
          type="textarea"
          specificTextAreaHeight={26}
        />
        <Section title="Messages" />
        <FormInput
          label="Welcome initial message"
          placeholder="Enter initial welcome message"
          {...register('welcomeMessage.initial')}
          error={errors.welcomeMessage?.initial?.message}
          type="textarea"
          specificTextAreaHeight={12}
        />
        <TwoColumnsContainer>
          <div>
            <InputLabel>Welcome others messages</InputLabel>
            {otherWelcomeMessagesFields.map((_, index) => (
              <FormInput
                key={index}
                label={`#${index + 1}`}
                placeholder="Enter other welcome message"
                {...register(`welcomeMessage.others[${index}]`)}
                error={errors.welcomeMessage?.others && errors.welcomeMessage.others[index]?.message}
                type="textarea"
                specificTextAreaHeight={12}
                onDelete={() => removeOtherWelcomeMessage(index)}
              />
            ))}
            <AddMoreItemsButton type="button" title="Add new message" onClick={() => appendOtherWelcomeMessage()} />
            <ErrorContainer errorText={errors.welcomeMessage?.others?.message} />
          </div>
          <div>
            <InputLabel>Insufficient funds messages</InputLabel>
            {insufficientFundsMessagesFields.map((_, index) => (
              <FormInput
                key={index}
                label={`#${index + 1}`}
                placeholder="Enter insufficient funds message"
                {...register(`insufficientFundsMessages[${index}]`)}
                error={errors.insufficientFundsMessages && errors.insufficientFundsMessages[index]?.message}
                type="textarea"
                specificTextAreaHeight={12}
                onDelete={() => removeInsufficientFundsMessage(index)}
              />
            ))}
            <AddMoreItemsButton
              type="button"
              title="Add new message"
              onClick={() => appendInsufficientFundsMessage()}
            />
            <ErrorContainer errorText={errors.insufficientFundsMessages?.message} />
          </div>
          <TwoColumnsItem>
            <InputLabel>Inactive messages</InputLabel>
            {inactiveMessagesFields.map((_, index) => (
              <FormInput
                key={index}
                label={`#${index + 1}`}
                placeholder="Enter inactive message"
                {...register(`inactiveMessages[${index}]`)}
                error={errors.inactiveMessages && errors.inactiveMessages[index]?.message}
                onDelete={() => removeInactiveMessage(index)}
              />
            ))}
            <AddMoreItemsButton type="button" title="Add new message" onClick={() => appendInactiveMessage()} />
            <ErrorContainer errorText={errors.inactiveMessages?.message} />
          </TwoColumnsItem>
        </TwoColumnsContainer>
        <Section title="Voice configuration" />
        <TwoColumnsContainer $last>
          <FormNumericInput
            label="Stability"
            {...register('voiceConfig.stability', {
              valueAsNumber: true,
            })}
            error={errors.voiceConfig?.stability?.message}
          />
          <FormNumericInput
            label="Similarity boost"
            {...register('voiceConfig.similarityBoost', {
              valueAsNumber: true,
            })}
            error={errors.voiceConfig?.similarityBoost?.message}
          />
          <FormNumericInput
            label="Style"
            {...register('voiceConfig.style', {
              valueAsNumber: true,
            })}
            error={errors.voiceConfig?.style?.message}
          />
          <Controller
            name="voiceConfig.useSpeakerBoost"
            control={control}
            render={({ field }) => {
              return (
                <CustomCheckbox
                  checked={field.value}
                  onChange={e => field.onChange(e.target.checked)}
                  error={errors.voiceConfig?.useSpeakerBoost?.message}>
                  <Text16Regular>Use speaker boost</Text16Regular>
                </CustomCheckbox>
              );
            }}
          />
        </TwoColumnsContainer>
      </form>
    </FormModal>
  );
}

export default CreateSecretModeFormModal;
