import styled from 'styled-components';

export const StyledLabel = styled.label`
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  font-weight: 300;
  font-size: 1.8rem;
  opacity: ${props => (props.disabled ? 0.8 : 1)};
`;

export const StyledInput = styled.input`
  font-size: 1.6rem;
  line-height: 2.4rem;
  font-weight: 300;
  color: #49435d;
  background: #fff;
  height: 4.8rem;
  width: 100%;
  border: 1px solid rgba(73, 67, 93, 0.2);
  border-radius: 12px;
  padding: 0.8rem 1.2rem;
  padding-right: ${props => (props.type === 'password' || props.$isShown ? '5rem' : '0')};
  box-shadow: none;
  outline: none;
  cursor: ${props => (props.disabled ? 'not-allowed' : 'auto')};
`;
