import React, { createContext, useContext, useState } from 'react';
import {
  CancelButton,
  ConfirmationModalContainer,
  ConfirmButton,
  Footer,
  Subtitle,
  TitleWrapper,
} from './ConfirmationModal.styles';
import Modal from '../Modal/Modal';
import { Text24Bold } from '../../texts/text.styles';

const ConfirmationModalContext = createContext({
  openModal: (title, message, confirmButtonText, onConfirm, cancelButtonText, onCancel) => {},
  closeModal: () => {},
  setConfirmationModalLoader: isLoading => {},
});

export const ConfirmationModalProvider = ({ children }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [title, setTitle] = useState('');
  const [message, setMessage] = useState('');
  const [confirmButtonText, setConfirmButtonText] = useState('');
  const [onConfirm, setOnConfirm] = useState(null);
  const [cancelButtonText, setCancelButtonText] = useState('');
  const [onCancel, setOnCancel] = useState(null);
  const [loading, setLoading] = useState(false);

  const setConfirmationModalLoader = isLoading => setLoading(isLoading);

  const openModal = (
    title,
    message,
    confirmButtonText = 'Confirm',
    onConfirm,
    cancelButtonText = 'Cancel',
    onCancel,
  ) => {
    setIsOpen(true);
    setTitle(title);
    setMessage(message);
    setConfirmButtonText(confirmButtonText);
    setOnConfirm(() => onConfirm);
    setCancelButtonText(cancelButtonText);
    setOnCancel(() => onCancel);
  };

  const closeModal = () => {
    setIsOpen(false);
    setTitle('');
    setMessage('');
    setConfirmButtonText('');
    setOnConfirm(null);
    setCancelButtonText('');
    setOnCancel(null);
    setLoading(false);
  };

  return (
    <ConfirmationModalContext.Provider value={{ openModal, closeModal, setConfirmationModalLoader }}>
      {children}
      <ConfirmationModal
        isOpen={isOpen}
        onClose={closeModal}
        title={title}
        message={message}
        confirmButtonText={confirmButtonText}
        cancelButtonText={cancelButtonText}
        onConfirm={onConfirm}
        onCancel={onCancel}
        loading={loading}
      />
    </ConfirmationModalContext.Provider>
  );
};

const ConfirmationModal = ({
  isOpen,
  title,
  message,
  confirmButtonText,
  onConfirm,
  cancelButtonText,
  onCancel,
  onClose,
  loading,
}) => {
  return (
    <Modal isOpen={isOpen} onClose={onClose} shouldCloseOnOverlayClick={true}>
      <ConfirmationModalContainer>
        <TitleWrapper>
          <Text24Bold>{title}</Text24Bold>
        </TitleWrapper>
        {!!message && <Subtitle>{message}</Subtitle>}
        <Footer>
          {!!onCancel && <CancelButton title={cancelButtonText} onClick={onCancel} />}
          {!!onConfirm && <ConfirmButton title={confirmButtonText} onClick={onConfirm} isLoading={loading} />}
        </Footer>
      </ConfirmationModalContainer>
    </Modal>
  );
};

export const useConfirmationModal = () => {
  const { openModal, closeModal, setConfirmationModalLoader } = useContext(ConfirmationModalContext);
  return [openModal, closeModal, setConfirmationModalLoader];
};
