import { yupResolver } from '@hookform/resolvers/yup';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useFieldArray, useForm } from 'react-hook-form';
import { createInfluencerStepAlbumMediaFormSchema } from '../../../../../forms/schemas/influencer.schema';
import { CreateInfluencerForm, Footer, FooterLeftButton, FooterRightButton } from '../../CreateInfluencerPage.styles';
import { ReactComponent as ForwardIcon } from '../../../../../assets/icons/forward.svg';
import { useConfirmationModal } from '../../../../../components/utils/modals/ConfirmationModal/ConfirmationModal';
import UploadFreeAlbumMediaFormModal from '../../../../../components/modals/UploadFreeAlbumMediaFormModal/UploadFreeAlbumMediaFormModal';
import UploadPremiumAlbumMediaFormModal from '../../../../../components/modals/UploadPremiumAlbumMediaFormModal/UploadPremiumAlbumMediaFormModal';
import UploadInactiveAlbumMediaFormModal from '../../../../../components/modals/UploadInactiveAlbumMediaFormModal/UploadInactiveAlbumMediaFormModal';
import {
  AddNewAlbumMediumButton,
  Container,
  ContainerFooter,
  ContainerHeader,
  EmptyListContainer,
  Row,
} from './CreateInfluencerPageStepAlbumMedia.styles';
import ErrorContainer from '../../../../../components/utils/ErrorContainer/ErrorContainer';
import { ReactComponent as GalleryIcon } from '../../../../../assets/icons/gallery.svg';
import { Text20Bold } from '../../../../../components/utils/texts/text.styles';
import AlbumMedia from '../../../../../components/AlbumMedia/AlbumMedia';
import { ALBUM_MEDIA_TYPE } from '../../../../../utils/constants';
import UploadCoverAlbumMediaFormModal from '../../../../../components/modals/UploadCoverAlbumMediaFormModal/UploadCoverAlbumMediaFormModal';

function CreateInfluencerPageStepAlbumMedia({ step, onAfterSubmit, onBack, formData }) {
  const [selectedAlbumMedium, setSelectedAlbumMedium] = useState(null);
  const [openModal, closeModal] = useConfirmationModal();
  const freeAlbumMediumModalRef = useRef();
  const inactiveAlbumMediumModalRef = useRef();
  const premiumAlbumMediumModalRef = useRef();
  const coverAlbumMediumModalRef = useRef();

  const {
    handleSubmit,
    control,
    formState: { errors },
    setValue,
    getValues,
  } = useForm({
    defaultValues: {
      freeAlbumMedia: [],
      inactiveAlbumMedia: [],
      premiumAlbumMedia: [],
      coverAlbumMedia: [],
    },
    delayError: 300,
    resolver: yupResolver(createInfluencerStepAlbumMediaFormSchema),
  });

  const {
    fields: freeAlbumMediaFields,
    append: appendFreeAlbumMedium,
    remove: removeFreeAlbumMedium,
    update: updateFreeAlbumMedium,
  } = useFieldArray({
    control,
    name: 'freeAlbumMedia',
  });

  const {
    fields: inactiveAlbumMediaFields,
    append: appendInactiveAlbumMedium,
    remove: removeInactiveAlbumMedium,
    update: updateInactiveAlbumMedium,
  } = useFieldArray({
    control,
    name: 'inactiveAlbumMedia',
  });

  const {
    fields: premiumAlbumMediaFields,
    append: appendPremiumAlbumMedium,
    remove: removePremiumAlbumMedium,
    update: updatePremiumAlbumMedium,
  } = useFieldArray({
    control,
    name: 'premiumAlbumMedia',
  });

  const {
    fields: coverAlbumMediaFields,
    append: appendCoverAlbumMedium,
    remove: removeCoverAlbumMedium,
    update: updateCoverAlbumMedium,
  } = useFieldArray({
    control,
    name: 'coverAlbumMedia',
  });

  useEffect(() => {
    if (formData.albumMedia) {
      if (formData.albumMedia.freeAlbumMedia.length) {
        for (const freeAlbumMedia of formData.albumMedia.freeAlbumMedia) {
          appendFreeAlbumMedium(freeAlbumMedia);
        }
      }
      if (formData.albumMedia.inactiveAlbumMedia.length) {
        for (const inactiveAlbumMedia of formData.albumMedia.inactiveAlbumMedia) {
          appendInactiveAlbumMedium(inactiveAlbumMedia);
        }
      }
      if (formData.albumMedia.premiumAlbumMedia.length) {
        for (const premiumAlbumMedia of formData.albumMedia.premiumAlbumMedia) {
          appendPremiumAlbumMedium(premiumAlbumMedia);
        }
      }
      if (formData.albumMedia.coverAlbumMedia.length) {
        for (const coverAlbumMedia of formData.albumMedia.coverAlbumMedia) {
          appendCoverAlbumMedium(coverAlbumMedia);
        }
      }
    }
  }, [
    formData.albumMedia,
    setValue,
    appendFreeAlbumMedium,
    appendInactiveAlbumMedium,
    appendPremiumAlbumMedium,
    appendCoverAlbumMedium,
  ]);

  const onEditAlbumMedia = useCallback((albumMedia, ref) => {
    setSelectedAlbumMedium(albumMedia);
    ref.current.show();
  }, []);

  const onDeleteAlbumMedia = useCallback(
    (event, type, index) => {
      openModal(
        'Delete album media',
        `Are you sure you want to delete album media?`,
        'Delete',
        () => {
          switch (type) {
            case ALBUM_MEDIA_TYPE.FREE:
              removeFreeAlbumMedium(index);
              break;
            case ALBUM_MEDIA_TYPE.INACTIVE:
              removeInactiveAlbumMedium(index);
              break;
            case ALBUM_MEDIA_TYPE.PREMIUM:
              removePremiumAlbumMedium(index);
              break;
            case ALBUM_MEDIA_TYPE.COVER:
              removeCoverAlbumMedium(index);
              break;
            default:
              break;
          }
          closeModal();
        },
        'Cancel',
        () => {
          closeModal();
        },
      );
      event.stopPropagation();
    },
    [
      openModal,
      closeModal,
      removeFreeAlbumMedium,
      removeInactiveAlbumMedium,
      removePremiumAlbumMedium,
      removeCoverAlbumMedium,
    ],
  );

  const onSubmit = data => {
    onAfterSubmit(data, step);
  };

  const refs = useMemo(() => {
    return {
      [ALBUM_MEDIA_TYPE.FREE]: { ref: freeAlbumMediumModalRef },
      [ALBUM_MEDIA_TYPE.INACTIVE]: { ref: inactiveAlbumMediumModalRef },
      [ALBUM_MEDIA_TYPE.PREMIUM]: { ref: premiumAlbumMediumModalRef },
      [ALBUM_MEDIA_TYPE.COVER]: { ref: coverAlbumMediumModalRef },
    };
  }, []);

  const renderAlbumMediaContainer = (title, modalRef, fieldsList, alt, errorMessage, type, disableAddNewAlbumMedia) => {
    return (
      <Container>
        <ContainerHeader>
          <Row>
            <GalleryIcon />
            <Text20Bold>{title}</Text20Bold>
          </Row>
          <AddNewAlbumMediumButton
            title="Add media"
            type="button"
            onClick={() => modalRef.current.show()}
            disabled={disableAddNewAlbumMedia}
          />
        </ContainerHeader>
        {fieldsList.length ? (
          <>
            {fieldsList.map((item, index) => {
              return (
                <AlbumMedia
                  key={item.id}
                  id={index}
                  src={typeof item.albumMedia === 'string' ? item.albumMedia : item.albumMedia.content}
                  type={type}
                  index={index}
                  item={item}
                  onClick={onEditAlbumMedia}
                  onClickParams={refs[type]}
                  onDelete={onDeleteAlbumMedia}
                  alt={alt}
                />
              );
            })}
          </>
        ) : (
          <EmptyListContainer />
        )}
        <ContainerFooter>
          <ErrorContainer errorText={errorMessage} />
        </ContainerFooter>
      </Container>
    );
  };

  return (
    <>
      <CreateInfluencerForm>
        {renderAlbumMediaContainer(
          'Free Gallery',
          freeAlbumMediumModalRef,
          freeAlbumMediaFields,
          'free-album-media-item',
          errors.freeAlbumMedia?.message,
          ALBUM_MEDIA_TYPE.FREE,
          freeAlbumMediaFields.length > 4,
        )}
        {renderAlbumMediaContainer(
          'Premium Gallery',
          premiumAlbumMediumModalRef,
          premiumAlbumMediaFields,
          'premium-album-media-item',
          errors.premiumAlbumMedia?.message,
          ALBUM_MEDIA_TYPE.PREMIUM,
          premiumAlbumMediaFields.length > 4,
        )}
        {renderAlbumMediaContainer(
          'Inactive Gallery',
          inactiveAlbumMediumModalRef,
          inactiveAlbumMediaFields,
          'inactive-album-media-item',
          errors.inactiveAlbumMedia?.message,
          ALBUM_MEDIA_TYPE.INACTIVE,
          inactiveAlbumMediaFields.length > 4,
        )}
        {renderAlbumMediaContainer(
          'Cover Gallery',
          coverAlbumMediumModalRef,
          coverAlbumMediaFields,
          'cover-album-media-item',
          errors.coverAlbumMedia?.message,
          ALBUM_MEDIA_TYPE.COVER,
          coverAlbumMediaFields.length > 4,
        )}
      </CreateInfluencerForm>
      <Footer>
        <FooterLeftButton title="Previous Step" onClick={() => onBack(getValues(), step)} />
        <FooterRightButton
          title="Next Step"
          icon={<ForwardIcon fill="#fff" />}
          onClick={() => handleSubmit(onSubmit)()}
        />
      </Footer>
      <UploadFreeAlbumMediaFormModal
        modalRef={freeAlbumMediumModalRef}
        selectedAlbumMedia={selectedAlbumMedium}
        customOnSubmit={
          selectedAlbumMedium
            ? data => {
                const index = freeAlbumMediaFields.findIndex(albumMedia => albumMedia.id === selectedAlbumMedium.id);
                updateFreeAlbumMedium(index, { ...freeAlbumMediaFields[index], ...data });
              }
            : data => appendFreeAlbumMedium(data)
        }
        onAfterClose={() => setSelectedAlbumMedium(null)}
      />
      <UploadPremiumAlbumMediaFormModal
        modalRef={premiumAlbumMediumModalRef}
        selectedAlbumMedia={selectedAlbumMedium}
        customOnSubmit={
          selectedAlbumMedium
            ? data => {
                const index = premiumAlbumMediaFields.findIndex(albumMedia => albumMedia.id === selectedAlbumMedium.id);
                updatePremiumAlbumMedium(index, { ...premiumAlbumMediaFields[index], ...data });
              }
            : data => appendPremiumAlbumMedium(data)
        }
        onAfterClose={() => setSelectedAlbumMedium(null)}
      />
      <UploadInactiveAlbumMediaFormModal
        modalRef={inactiveAlbumMediumModalRef}
        selectedAlbumMedia={selectedAlbumMedium}
        customOnSubmit={
          selectedAlbumMedium
            ? data => {
                const index = inactiveAlbumMediaFields.findIndex(
                  albumMedia => albumMedia.id === selectedAlbumMedium.id,
                );
                updateInactiveAlbumMedium(index, { ...inactiveAlbumMediaFields[index], ...data });
              }
            : data => appendInactiveAlbumMedium(data)
        }
        onAfterClose={() => setSelectedAlbumMedium(null)}
      />
      <UploadCoverAlbumMediaFormModal
        modalRef={coverAlbumMediumModalRef}
        selectedAlbumMedia={selectedAlbumMedium}
        customOnSubmit={
          selectedAlbumMedium
            ? data => {
                const index = coverAlbumMediaFields.findIndex(albumMedia => albumMedia.id === selectedAlbumMedium.id);
                updateCoverAlbumMedium(index, { ...inactiveAlbumMediaFields[index], ...data });
              }
            : data => appendCoverAlbumMedium(data)
        }
        onAfterClose={() => setSelectedAlbumMedium(null)}
      />
    </>
  );
}

export default CreateInfluencerPageStepAlbumMedia;
