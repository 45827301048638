import React, { Fragment } from 'react';
import { TableBodyItem } from '../../Table.styles';

function UserTableItem({ firstName, lastName, username, email, credits, isPremium }) {
  return (
    <Fragment>
      <TableBodyItem>
        <p>{firstName || '-'}</p>
      </TableBodyItem>
      <TableBodyItem>
        <p>{lastName || '-'}</p>
      </TableBodyItem>
      <TableBodyItem>
        <p>{username || '-'}</p>
      </TableBodyItem>
      <TableBodyItem>
        <p>{email || '-'}</p>
      </TableBodyItem>
      <TableBodyItem>
        <p>{isNaN(credits) ? '-' : credits}</p>
      </TableBodyItem>
      <TableBodyItem>
        <p>{isPremium ? 'Yes' : 'No'}</p>
      </TableBodyItem>
    </Fragment>
  );
}

export default UserTableItem;
