import { yupResolver } from '@hookform/resolvers/yup';
import React, { useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { createInfluencerStepAdditionalInfoFormSchema } from '../../../../../forms/schemas/influencer.schema';
import { CreateInfluencerForm, Footer, FooterLeftButton, FooterRightButton } from '../../CreateInfluencerPage.styles';
import { ReactComponent as ForwardIcon } from '../../../../../assets/icons/forward.svg';
import { FormCheckbox, FormInput, FormNumericInput, FormSelect } from '../../../../../forms/form.styles';
import {
  CheckboxRow,
  Container,
  ContainerLeftPart,
  ContainerRightPart,
} from './CreateInfluencerPageStepAdditionalInfo.styles';
import { selectFetchInfluencerVariablesInfoData } from '../../../../../store/slices/influencer/slice';
import { useSelector } from 'react-redux';
import { convertTagValueToLabel } from '../../../../../utils/util';

function CreateInfluencerPageStepAdditionalInfo({ formData, onAfterSubmit, onBack, step }) {
  const influencerVariables = useSelector(selectFetchInfluencerVariablesInfoData);

  const {
    register,
    handleSubmit,
    control,
    setValue,
    getValues,
    formState: { errors },
  } = useForm({
    defaultValues: {
      businessConnection: null,
      personalityTags: [],
      appearanceTags: [],
      highlightTags: [],
      paymentRatio: {
        directReferral: 0.5,
        directForeigner: 0.2,
        directOthersReferral: 0.05,
        indirectReferral: 0.15,
      },
      callOptions: {
        isVoiceCallEnabled: false,
        isVideoCallEnabled: false,
      },
      score: '',
      isVerified: true,
      active: true,
      aboutMe: '',
    },
    delayError: 300,
    resolver: yupResolver(createInfluencerStepAdditionalInfoFormSchema),
  });

  useEffect(() => {
    if (formData.additionalInfo) {
      setValue('businessConnection', formData.additionalInfo.businessConnection);
      setValue('personalityTags', formData.additionalInfo.personalityTags);
      setValue('appearanceTags', formData.additionalInfo.appearanceTags);
      setValue('highlightTags', formData.additionalInfo.highlightTags);
      setValue('paymentRatio', formData.additionalInfo.paymentRatio);
      setValue('score', formData.additionalInfo.score);
      setValue('isVerified', formData.additionalInfo.isVerified);
      setValue('callOptions.isVoiceCallEnabled', formData.additionalInfo.callOptions.isVoiceCallEnabled);
      setValue('callOptions.isVideoCallEnabled', formData.additionalInfo.callOptions.isVideoCallEnabled);
      setValue('active', formData.additionalInfo.active);
      setValue('aboutMe', formData.additionalInfo.aboutMe);
    }
  }, [formData.additionalInfo, setValue]);

  const onSubmit = data => {
    onAfterSubmit(data, step);
  };

  return (
    <>
      <CreateInfluencerForm>
        <Container>
          <ContainerLeftPart>
            <Controller
              name="businessConnection"
              control={control}
              render={({ field }) => (
                <FormSelect
                  placeholder="Select business connection"
                  label="Business Connection"
                  options={influencerVariables.businessConnections.map(value => ({ value, label: value.name }))}
                  selectedOption={field.value}
                  handleChange={value => field.onChange(value)}
                  error={errors.businessConnection?.message}
                />
              )}
            />
            <Controller
              name="highlightTags"
              control={control}
              render={({ field }) => (
                <FormSelect
                  placeholder="Select highlight tags"
                  label="Highlight tags"
                  options={influencerVariables.highlightTags.map(value => ({
                    value,
                    label: convertTagValueToLabel(value),
                  }))}
                  multiSelection
                  selectedOption={field.value}
                  handleChange={value => field.onChange(value)}
                  error={errors.highlightTags?.message}
                />
              )}
            />
            <Controller
              name="personalityTags"
              control={control}
              render={({ field }) => (
                <FormSelect
                  placeholder="Select personality tags"
                  label="Personality tags"
                  options={influencerVariables.personalityTags.map(value => ({
                    value,
                    label: convertTagValueToLabel(value),
                  }))}
                  multiSelection
                  selectedOption={field.value}
                  handleChange={value => field.onChange(value)}
                  error={errors.personalityTags?.message}
                />
              )}
            />
            <Controller
              name="appearanceTags"
              control={control}
              render={({ field }) => (
                <FormSelect
                  placeholder="Select appearance tags"
                  label="Appearance tags"
                  options={influencerVariables.appearanceTags.map(value => ({
                    value,
                    label: convertTagValueToLabel(value),
                  }))}
                  multiSelection
                  selectedOption={field.value}
                  handleChange={value => field.onChange(value)}
                  error={errors.appearanceTags?.message}
                />
              )}
            />
            <FormNumericInput
              label="Score"
              {...register('score', {
                valueAsNumber: true,
              })}
              error={errors.score?.message}
            />
            <FormNumericInput
              label="Direct referral payment ratio"
              {...register('paymentRatio.directReferral', {
                valueAsNumber: true,
              })}
              error={errors.paymentRatio?.directReferral?.message}
            />
            <FormNumericInput
              label="Direct others referral payment ratio"
              {...register('paymentRatio.directOthersReferral', {
                valueAsNumber: true,
              })}
              error={errors.paymentRatio?.directOthersReferral?.message}
            />
          </ContainerLeftPart>
          <ContainerRightPart>
            <FormInput
              label="About Me"
              placeholder="Enter about me"
              {...register('aboutMe')}
              error={errors.aboutMe?.message}
              type="textarea"
              specificTextAreaHeight={27}
            />
            <CheckboxRow>
              <Controller
                name="isVerified"
                control={control}
                render={({ field }) => {
                  return (
                    <FormCheckbox
                      checked={field.value}
                      onChange={e => field.onChange(e.target.checked)}
                      error={errors.isVerified?.message}
                      hideErrorMessage>
                      <p>Verified</p>
                    </FormCheckbox>
                  );
                }}
              />
              <Controller
                name="active"
                control={control}
                render={({ field }) => {
                  return (
                    <FormCheckbox
                      checked={field.value}
                      onChange={e => field.onChange(e.target.checked)}
                      error={errors.active?.message}
                      hideErrorMessage>
                      <p>Active</p>
                    </FormCheckbox>
                  );
                }}
              />
            </CheckboxRow>
            <div style={{ height: '3.7rem' }} />
            <CheckboxRow>
              <Controller
                name="callOptions.isVoiceCallEnabled"
                control={control}
                render={({ field }) => {
                  return (
                    <FormCheckbox
                      checked={field.value}
                      onChange={e => field.onChange(e.target.checked)}
                      error={errors.callOptions?.isVoiceCallEnabled?.message}
                      hideErrorMessage>
                      <p>Voice call</p>
                    </FormCheckbox>
                  );
                }}
              />
              <Controller
                name="callOptions.isVideoCallEnabled"
                control={control}
                render={({ field }) => {
                  return (
                    <FormCheckbox
                      checked={field.value}
                      onChange={e => field.onChange(e.target.checked)}
                      error={errors.callOptions?.isVideoCallEnabled?.message}
                      hideErrorMessage>
                      <p>Video call</p>
                    </FormCheckbox>
                  );
                }}
              />
            </CheckboxRow>
            <div style={{ height: '1.5rem' }} />
            <FormNumericInput
              label="Direct foreigner payment ratio"
              {...register('paymentRatio.directForeigner', {
                valueAsNumber: true,
              })}
              error={errors.paymentRatio?.directForeigner?.message}
            />
            <FormNumericInput
              label="Indirect referral payment ratio"
              {...register('paymentRatio.indirectReferral', {
                valueAsNumber: true,
              })}
              error={errors.paymentRatio?.indirectReferral?.message}
            />
          </ContainerRightPart>
        </Container>
      </CreateInfluencerForm>
      <Footer>
        <FooterLeftButton title="Previous Step" onClick={() => onBack(getValues(), step)} />
        <FooterRightButton
          title="Next Step"
          icon={<ForwardIcon fill="#fff" />}
          onClick={() => handleSubmit(onSubmit)()}
        />
      </Footer>
    </>
  );
}

export default CreateInfluencerPageStepAdditionalInfo;
