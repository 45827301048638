import React from 'react';
import { Container, NoDataImage } from './NoDataContainer.styles';
import NoDataImg from '../../assets/images/no-data.png';
import { Text16Bold } from '../utils/texts/text.styles';

function NoDataContainer({ title = 'No Data Found', className }) {
  return (
    <Container className={className}>
      <NoDataImage src={NoDataImg} alt="no-data" />
      <Text16Bold>{title}</Text16Bold>
    </Container>
  );
}

export default NoDataContainer;
